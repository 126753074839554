import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";

const defaultTheme = createTheme();

export default function Contact() {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Typography component="h1" variant="h5">
              Request Call back
            </Typography>
            <Typography component="h1" variant="h5" height="30rem">
              <TextField
                margin="normal"
                required
                fullWidth
                id="fullname"
                label="Full Name"
                name="fullname"
                autoComplete="fullname"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phonenumber"
                label="Phone Number"
                name="phonenumber"
                autoComplete="phonenumber"
                autoFocus
              />
              <TextField
                margin="normal"
                multiline
                minRows={"4"}
                required
                fullWidth
                id="message"
                label="Message"
                name="message"
                autoComplete="message"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="contact_button "
              >
                Send
              </Button>
            </Typography>
          </Grid>
          <Grid item md={8}>
            {" "}
            <Typography component="h1" variant="h5">
              Our Location
            </Typography>
            <Typography component="h1" variant="h5" height="30rem">
              {" "}
              <a href="https://www.google.com/maps">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.127917794822!2d73.91232677348847!3d18.568270867718784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c147c99c328b%3A0x133d0d5f39576dab!2sBuena%20Vista%2C%20Anand%20Vidya%20Niketan%20High%20School%20Rd%2C%20Mhada%20Colony%2C%20Viman%20Nagar%2C%20Pune%2C%20Maharashtra%20411014!5e0!3m2!1sen!2sin!4v1697612933491!5m2!1sen!2sin"
                ></iframe>
{/* 
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.127917794822!2d73.91232677348847!3d18.568270867718784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c147c99c328b%3A0x133d0d5f39576dab!2sBuena%20Vista%2C%20Anand%20Vidya%20Niketan%20High%20School%20Rd%2C%20Mhada%20Colony%2C%20Viman%20Nagar%2C%20Pune%2C%20Maharashtra%20411014!5e0!3m2!1sen!2sin!4v1697612933491!5m2!1sen!2sin" width="600" height="450"   loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            */}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
