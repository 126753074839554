import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MenuItem } from '@mui/material';
import useValidation from '../../../Validation/useValidation';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

const VehicleDetails = () => {
  const getAutomobileFormStateData = useSelector((state: any) => state.VehicleReducer);
  const [formData, setFormData] = useState({ ...getAutomobileFormStateData });
  const { eventHandler } = useValidation()
  const [error, setError] = useState({
    permitValidFromDate: '',
    registrationCertificateNumber: '',
    registrationDate: '',
    registeredAtRto: '',
    rcStatus: '',
    manufacturingDate: '',
    ownerSerialNumber: '',
    fuelNorms: '',
    engineCubicCapacity: '',
    ncrbStatus: '',
    blackListStatus: '',
    nocDetails: '',
    permitNo: '',
    permitIssueDate: '',
    vehicleType: '',
    permitType: '',
    permitValidUptoDate: '',
    rcFitnessValidity: '',
    rcTaxValidity: '',
    insurancePolicyNumber: '',
    rcStatusAsOn: '',
    insuranceValidity: '',
    dateOfIntimation: '',
    date: '',
    time: '',
    nameOfHospital: '',
    firInitial: '',
    firFinal: '',
    fireDepartment: '',
    nameOfDriver: '',
    driverDisability: '',
    advocateName: '',
    city: '',
    state: '',
    referenceNumber: '',
    zipCode: '',
    salesChannel: '',
    remark: '',
    company: '',
    // vehicleNo: '',
    registrationCertificateNo: '',
    executiveAssign: '',
  });

  const [companies, setCompanies] = useState([]);
  const fetchCompanies = (selectedChannel: any) => {
    if (selectedChannel === 'Insurance') {

    } else if (selectedChannel === 'Bank') {

    } else {
      setCompanies([]);
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const dispatchVehicleDetails = useDispatch();

  useEffect(() => {
    if (formData) {
      dispatchVehicleDetails({ type: "UPDATE_VEHICLE_DATA", payload: formData });
    }
    return () => {

    };
  }, [formData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };


  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData({ ...getAutomobileFormStateData });
  }, [getAutomobileFormStateData]);

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>

          { }

          <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>

              <Typography variant="h6" gutterBottom>
                Vehicle Details
              </Typography>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={12}>
                  <TextField
                    label="Manufacturing Date"
                    size='small'
                    name="manufacturingDate"
                    value={formData.manufacturingDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Manufacturing Date"
                    size='small'
                    name="manufacturingDate"
                    value={formData.manufacturingDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Fuel Norms"
                    size='small'
                    name="fuelNorms"
                    value={formData.fuelNorms}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Registration Certificate No"
                    size='small'
                    name="registrationCertificateNo"
                    value={formData.registrationCertificateNo}
                    onChange={(e: any) => {
                      validationHandler(e, "alphanumeric");
                      handleChange(e)
                    }}
                    error={Boolean(error.registrationCertificateNo)}
                    helperText={error.registrationCertificateNo}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Registration Date"
                    size='small'
                    name="registrationDate"
                    value={formData.registrationDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Registration Date"
                    size='small'
                    name="registrationDate"
                    value={formData.registrationDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Registered At RTO"
                    size='small'
                    name="registeredAtRto"
                    value={formData.registeredAtRto}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="RC Status"
                    size='small'
                    name="rcStatus"
                    value={formData.rcStatus}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="RC Fitness Validity"
                    size='small'
                    name="rcFitnessValidity"
                    value={formData.rcFitnessValidity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="RC Tax Validity"
                    size='small'
                    name="rcTaxValidity"
                    value={formData.rcTaxValidity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Reference Number"
                    size='small'
                    name="referenceNumber"
                    value={formData.referenceNumber}
                    onChange={handleChange}
                    fullWidth

                    margin="normal"
                  />
                </Grid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>

              <Typography variant="h6" gutterBottom>
                Vehicle Insurance Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Insurance Policy Number"
                    size='small'
                    name="insurancePolicyNumber"
                    value={formData.insurancePolicyNumber}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Insurance Validity"
                    size='small'
                    name="insuranceValidity"
                    value={formData.insuranceValidity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}

                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit No"
                    size='small'
                    name="permitNo"
                    value={formData.permitNo}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit Issue Date"
                    size='small'
                    name="permitIssueDate"
                    value={formData.permitIssueDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}

                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit Issue Date"
                    size='small'
                    name="permitIssueDate"
                    value={formData.permitIssueDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit Valid From Date"
                    size='small'
                    name="permitValidFromDate"
                    value={formData.permitValidFromDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit ValidFrom Date"
                    size='small'
                    name="permitValidFromDate"
                    value={formData.permitValidFromDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit Valid Upto Date"
                    size='small'
                    name="permitValidUptoDate"
                    value={formData.permitValidUptoDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Permit Valid Upto Date"
                    size='small'
                    name="permitValidUptoDate"
                    value={formData.permitValidUptoDate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Date Of Intimation"
                    size='small'
                    name="dateOfIntimation"
                    value={formData.dateOfIntimation}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}

                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Date Of Intimation"
                    size='small'
                    name="dateOfIntimation"
                    value={formData.dateOfIntimation}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Date Of Inspection"
                    size='small'
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Date of inspection"
                    size='small'
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Time of Inspection"
                    size='small'
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="time"
                    InputLabelProps={{

                      shrink: true,

                    }}
                  />
                </Grid>



                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Recommandation For Insurance"
                    size='small'
                    name="recommandationForInsurance"
                    select // Make it a select input
                    value={formData.recommandationForInsurance}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin="normal"
                  >
                    {/* Add your custom options here */}
                    <MenuItem value="accepted">Accepted</MenuItem>
                    <MenuItem value="decilined">Declined</MenuItem>

                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>

              <Typography variant="h6" gutterBottom>
                FIR Details
              </Typography>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="FIR Initial"
                    size='small'
                    name="firInitial"
                    select // Make it a select input
                    value={formData.firInitial}
                    onChange={handleChange}
                    fullWidth

                    margin="normal"
                  >

                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>

                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="FIR Final"
                    size='small'
                    name="firFinal"
                    select // Make it a select input
                    value={formData.firFinal}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>

                  </TextField>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="FIR Initial"
                    size='small'
                    name="firInitial"
                    select // Make it a select input
                    value={formData.firInitial}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    {/* Add your custom options here */}
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>

                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="FIR Final"
                    size='small'
                    name="firFinal"
                    select // Make it a select input
                    value={formData.firFinal}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    {/* Add your custom options here */}
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>

                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Fire Department Report"
                    size='small'
                    name="fireDepartment"
                    select // Make it a select input
                    value={formData.fireDepartment}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    {/* Add your custom options here */}
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>

                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Name Of Driver"
                    size='small'
                    name="nameOfDriver"
                    value={formData.nameOfDriver}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Driver Disability"
                    size='small'
                    name="driverDisability"
                    select // Make it a select input
                    value={formData.driverDisability}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    {/* Add your custom options here */}
                    <MenuItem value="Partial">Partial</MenuItem>
                    <MenuItem value="Permanent">Permanent</MenuItem>


                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>

              <Typography variant="h6" gutterBottom>
                Advocate Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Advocate Name"
                    size='small'
                    name="advocateName"
                    value={formData.advocateName}
                    onChange={handleChange}
                    fullWidth

                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    size='small'
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="State"
                    size='small'
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Zip Code"
                    size='small'
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Remark"
                    size='small'
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

      </form >
    </Container >
  );
};

export default VehicleDetails;








