import React, { useState, useEffect } from "react";
import Layout from "../Website/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "../Webapp/MainLayout ";
import Service_Content from "../Website/ServiceContent";
import About_Content from "../Website/AboutContent";
import BasicTabs from "../Webapp/Automobiles/Automobile";
import Cattle from "../Webapp/Cattle/Cattle";
import ServiceCategory from "../Webapp/ServiceCategory";
import Report1 from "../Webapp/Report/CattleFitness";
import Report2 from "../Webapp/Report/FarmerPackage";
import Report3 from "../Webapp/Report/Agency";
// import Qrcodeview from "../Website/Qrcodeview";
import List from "../Webapp/List";
import Login from "../Website/Login";
import AddExecutive from "../Webapp/Cattle/AddExecutive";

import PageNotFound from "../Website/PageNotFound";
import AutoMobileList from "../Webapp/AutoMobileList";
import Automobile from "../Webapp/Automobiles/Automobile";
import { useSelector } from "react-redux";
import DrRegistrationForm from "../Webapp/Cattle/DrRegistrationForm";
import AddDrRegistrationList from "../Webapp/Cattle/AddDrRegistrationList";
import AddExecutiveList from "../Webapp/Cattle/AddExecutiveList";

const Routing = () => {
  // state to maintain login details
  const [appswitch, setAppswitch] = useState("automobiles");
  // const authorization=sessionStorage.getItem("token");
  const userType = sessionStorage.getItem("usertype");
  const getStoreDetails = useSelector((state: any) => state)
  const authorization = useSelector((state: any) => state.TokenReducer);
  // Callback function to update the parent state
  const updateAppswitch = (newCount: any) => {
    setAppswitch(newCount);
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          {/* <Route path="/Qrcode" element={<Qrcodeview/>} /> */}
          {authorization && userType !== "Executive" && userType !== null ? <>
            <Route
              path="/dashboard"
              element={<MainLayout appswitch={appswitch} />}
            >

              {/*ServiceCategory */}

              <Route path="/dashboard" element={<ServiceCategory />} />
              {/* <Route path="/dashboard/Automobiles" element={<BasicTabs />} /> */}
              
              <Route path="/dashboard/Cattle" element={<List />} />
              <Route path="/dashboard/Cattle/Executive" element={<AddExecutive />} />
              <Route path="/dashboard/Cattle/Edit" element={<AddExecutive />} />
              <Route path="/dashboard/Cattle/Create" element={<Cattle />} />
              <Route path="/dashboard/Executive/Create" element={<AddExecutiveList />} />
              <Route path="/dashboard/Automobiles" element={<AutoMobileList />} />
              <Route path="/dashboard/Automobiles/Create" element={<Automobile />} />


              {/*Veterinary*/}        
              <Route path="/dashboard/Veterinary/AddDrRegistrationList" element={<AddDrRegistrationList />} />
              <Route path="/dashboard/Veterinary/AddDrRegistrationList/Create" element={<DrRegistrationForm />} />
              <Route path="/dashboard/Veterinary/AddDrRegistrationList/Edit" element={<DrRegistrationForm />} />
              {/*Report*/}
              <Route path="/dashboard" element={<ServiceCategory />} />
              <Route path="/dashboard/Automobiles" element={<BasicTabs />} />

              <Route path="/dashboard/Cattle" element={<List />} />
              <Route path="/dashboard/Cattle/Create" element={<Cattle />} />

              {/*Report*/}

              <Route path="/dashboard/CattleFitness" element={<Report1 />} />
              <Route path="/dashboard/FarmerPackage" element={<Report2 />} />
              <Route path="/dashboard/Agency" element={<Report3 />} />


            </Route>
          </> : <><Route path="/dashboard" element={<Login />} />
            <Route path="*" element={<Layout />} /></>}
          {/*Service*/}
          <Route path="/ServiceContent" element={<Service_Content />} />
          <Route path="/About_Content" element={<About_Content />} />
        </Routes>
        <Routes></Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
