import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';
import useValidation from '../../../Validation/useValidation';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

const CommercialForm = () => {
  const getAutomobileFormStateData = useSelector((state: any) => state.CommercialReducer);

  const [formData, setFormData] = useState({ ...getAutomobileFormStateData });
  const { eventHandler } = useValidation()
  const [error, setError] = useState({
    cmfrontBumper: '',
    cmjobType: '',
    cmrhHeadLight: '',
    cmlhHeadLight: '',
    cmfrontShow: '',
    cmcabin: '',
    cmloadBody: '',
    cmrhTailLight: '',
    cmlhTailLight: '',
    cmchassis: '',
    cmunderCarriage: '',
    cmrearmudguard: '',
    cmdashboard: '',
    cmengine: '',
    cmtyres: '',
    cmremarks: '',
  });

  const [companies, setCompanies] = useState([]);

  const fetchCompanies = (selectedChannel: any) => {

    if (selectedChannel === 'Insurance') {

    } else if (selectedChannel === 'Bank') {

    } else {
      setCompanies([]);
    }
  };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const dispatchCommercial = useDispatch();

  useEffect(() => {
    if (formData) {
      dispatchCommercial({ type: "UPDATE_COMMERCIAL_DATA", payload: formData });
    }
  }, [formData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>

          { }

          <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>

              <Typography variant="h6" gutterBottom>
                Commercial
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Front Bumper"
                    size='small'
                    name="cmfrontBumper"
                    value={formData.cmfrontBumper}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="RH Head Light"
                    size='small'
                    name="cmrhHeadLight"
                    value={formData.cmrhHeadLight}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="LH Head Light"
                    size='small'
                    name="cmlhHeadLight"
                    value={formData.cmlhHeadLight}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Front Show"
                    size='small'
                    name="cmfrontShow"
                    value={formData.cmfrontShow}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="cabin"
                    size='small'
                    name="cmcabin"
                    value={formData.cmcabin}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Load Body"
                    size='small'
                    name="cmloadBody"
                    value={formData.cmloadBody}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="RH Tail Light"
                    size='small'
                    name="cmrhTailLight"
                    value={formData.cmrhTailLight}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="LH Tail Light"
                    size='small'
                    name="cmlhTailLight"
                    value={formData.cmlhTailLight}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="chassis"
                    size='small'
                    name="cmchassis"
                    value={formData.cmchassis}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Under Carriage"
                    size='small'
                    name="cmunderCarriage"
                    value={formData.cmunderCarriage}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="engine"
                    size='small'
                    name="cmengine"
                    value={formData.cmengine}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="dashboard"
                    size='small'
                    name="cmdashboard"
                    value={formData.cmdashboard}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="tyres"
                    size='small'
                    name="cmtyres"
                    value={formData.cmtyres}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="remarks"
                    size='small'
                    name="cmremarks"
                    value={formData.cmremarks}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          { }
          { }
          { }
          { }
        </Grid>
      </form>
    </Container>
  );
};

export default CommercialForm;