import { updateOwnerData } from "../Actions/CommonAction";

export const CommercialInitialState = {
  cmfrontBumper: '',
  cmsalesChannel: '',
  cmrhHeadLight: '',
  cmlhHeadLight: '',
  cmfrontShow: '',
  cmcabin: '',
  cmloadBody: '',
  cmrhTailLight: '',
  cmlhTailLight: '',
  cmchassis: '',
  cmunderCarriage: '',
  cmengine: '',
  cmdashboard: '',
  cmtyres: '',
  cmremarks: '',
};

const CommercialReducer = (state = CommercialInitialState, action: any) => {
  switch (action.type) {
    case "UPDATE_COMMERCIAL_DATA":
      return {
        ...state,
        cmfrontBumper: action.payload.cmfrontBumper,
        cmsalesChannel: action.payload.cmsalesChannel,
        cmrhHeadLight: action.payload.cmrhHeadLight,
        cmlhHeadLight: action.payload.cmlhHeadLight,
        cmfrontShow: action.payload.cmfrontShow,
        cmcabin: action.payload.cmcabin,
        cmloadBody: action.payload.cmloadBody,
        cmrhTailLight: action.payload.cmrhTailLight,
        cmlhTailLight: action.payload.cmlhTailLight,
        cmchassis: action.payload.cmchassis,
        cmunderCarriage: action.payload.cmunderCarriage,
        cmengine: action.payload.cmengine,
        cmdashboard: action.payload.cmdashboard,
        cmtyres: action.payload.cmtyres,
        cmremarks: action.payload.cmremarks,

      };
    case "CLEAR_COMMERCIAL_DATA": return { ...CommercialInitialState };
    default:
      return state;
  }
};

export default CommercialReducer;
