import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import useValidation from '../../Validation/useValidation'
import { format } from 'path'
import { useDispatch, useSelector } from 'react-redux'
import { getRequest, getRequestByParams } from '../../../Api/ApiEndpointconfiguration'
import { GET_IMAGE_BY_VDID, GET_VETERNARY_LIST } from '../../../Api/ServerConfiguration'
import { useLocation } from 'react-router-dom'
import CommonSnackBar from "../../CommonComponents/CommonSnackbar";
const CattalDetails = () => {
  const { eventHandler } = useValidation()
  const getFormStateData = useSelector((state: any) => state.CattleReducer)
  const authoriseReducer = useSelector((state: any) => state.AuthorisedReducer)
  const dispatchCattleDetails = useDispatch()
  const dispatchAuthorizeDeatils = useDispatch()
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    animalCategory: '',
    typeOfBreed: '',
    gender: '',
    age: '',
    AnimalDescription: '',
    purpose: '',
    marketValue: '',
    earTagNo: '',
    stabledAnimalAddress: '',
    veterinaryCertificate: 'Yes',
    VeterinaryDoctorServices: '',
    Qualification: '',
    mobileNumber: '',
    doctorAddress: '',
    vetCheckupDetails: '',
    diseaseType: '',
    dateOfIntimation: '',
    previousHistory: '',
    previousClaimDate: '',
    dateOfInceptionofDisease: '',
    treatmentDetails: '',
    DateofDeath: '',
    timeOfDeath: '',
    dateOfPostmortem: '',
    timeOfPostmortem: '',
    causeOfDeath: '',
    VDID: ''
  })
  const [show, setShow] = useState(false)
  const [veternList, setveternList] = useState<any>([])
  const [cattleDetailsArray, setCattleDetailsArray] = useState<any>([...getFormStateData]);
  const [cattleList, setCattleList] = useState<any[]>([]);
  const [editIndex, setEditIndex] = useState(null);
  const location = useLocation();
  const [addClick, setAddClick] = useState(false);
  const [editClick, setEditClick] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false)
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  // Add a state variable to track whether the form has been initialized
  // const [selectedVeterinaryDoctor, setSelectedVeterinaryDoctor] = useState<any>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const vetenaryHide = () => {
    setShow(true)
  }
  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };
  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value

    const id = alterName

    if (id) {
      let prom = new Promise(resolve => {
        if (true) {
          resolve(eventHandler(id, val))
        }
      })

      prom.then(res => setError({ ...error, [e.target.name]: res }))
    }

    // setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const veternaryDetails = async () => {
    try {
      const response: any = await getRequest(GET_VETERNARY_LIST, '')
      if (response.data.length > 0) {
        setveternList(response.data)
      }
    } catch (error: any) {
      console.log('Veternary error', error)
    }
  }
  useEffect(() => {
    veternaryDetails()
  }, [])

  const handleAddCattleDetails = () => {
    // Check if the veterinary doctor details are already selected
    const isVeterinaryDoctorSelected = cattleDetailsArray.some((cattleDetails: any) => cattleDetails.VeterinaryDoctorServices !== "");

    // If veterinary doctor details are not selected, show an alert
    if (!isVeterinaryDoctorSelected) {
      handleOpenSnackBar(
        "Please fill veterinary doctor details first."
      );
      return;
    }
    // Get the first veterinary doctor from the list
    let selectedVeterinaryDoctor: any = null;
    if (cattleDetailsArray[0].VeterinaryDoctorServices !== '') {
      selectedVeterinaryDoctor = cattleDetailsArray[0].VeterinaryDoctorServices;
    } else {
      selectedVeterinaryDoctor = veternList[0].VeterinaryDoctorServices;
    }
    // Set the veterinary doctor details for all cattle details
    const updatedCattleDetailsArray = cattleDetailsArray.map((cattleDetails: any) => ({
      ...cattleDetails,
      VeterinaryDoctorServices: selectedVeterinaryDoctor,
      Qualification: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.Qualification || '',
      mobileNumber: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.mobileNumber || '',
      doctorAddress: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.doctorAddress || '',
      registrationNumber: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.registrationNumber || '',
      VDID: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.VDID || ''
    }));

    setCattleList([...cattleList, ...updatedCattleDetailsArray]);
    const newCattleDetails = {
      animalCategory: '',
      typeOfBreed: '',
      gender: '',
      age: '',
      AnimalDescription: '',
      purpose: '',
      marketValue: '',
      earTagNo: '',
      stabledAnimalAddress: '',
      veterinaryCertificate: 'Yes',
      // VeterinaryDoctorServices: '',
      // Qualification: '',
      // mobileNumber: '',
      // doctorAddress: '',
      VeterinaryDoctorServices: selectedVeterinaryDoctor,
      Qualification: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.Qualification || '',
      mobileNumber: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.mobileNumber || '',
      doctorAddress: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.doctorAddress || '',
      registrationNumber: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.registrationNumber || '',
      VDID: veternList.find((doctor: any) => doctor.VeterinaryDoctorServices === selectedVeterinaryDoctor)?.VDID || '',
      vetCheckupDetails: '',
      diseaseType: '',
      dateOfIntimation: '',
      previousHistory: '',
      previousClaimDate: '',
      dateOfInceptionofDisease: '',
      treatmentDetails: '',
      DateofDeath: '',
      timeOfDeath: '',
      dateOfPostmortem: '',
      timeOfPostmortem: '',
      causeOfDeath: '',
      // VDID: ''
    };
    setCattleDetailsArray([newCattleDetails]);
    if (location.state === null && editClick === true) {
      // Editing mode: Update the data at the specified index (editIndex)
      if (editIndex !== null && editIndex !== undefined) {
        const updatedCattleList = [...cattleList];
        updatedCattleList[editIndex] = { ...cattleDetailsArray[0] };
        setCattleList(updatedCattleList);

        // Optionally, you can dispatch the edited data to your Redux store
        dispatchCattleDetails({
          type: 'UPDATE_CATTLE_DATA',
          payload: cattleDetailsArray[0],
          index: editIndex,
        });
      }

    }

    if (location.state !== null && editClick === true) {
      if (editIndex !== null && editIndex !== undefined) {
        const updatedCattleList = [...cattleDetailsArray];
        updatedCattleList[editIndex] = { ...cattleDetailsArray[editIndex] };
        setCattleList(updatedCattleList);

        // Optionally, you can dispatch the edited data to your Redux store
        dispatchCattleDetails({
          type: 'UPDATE_CATTLE_DATA',
          payload: cattleDetailsArray[editIndex],
          index: editIndex,
        });
      }
    }
    setEditIndex(null);
    setAddClick(true);
    setEditClick(false);
    setButtonEnable(false);
  };
  const handleFieldChange = async (index: any, fieldName: any, value: any) => {
    setButtonEnable(true);
    let updatedArray = [...cattleDetailsArray];

    if (fieldName === "VeterinaryDoctorServices") {
      const filterVetrn = veternList.filter((vetern: any) => vetern.VeterinaryDoctorServices === value);
      if (filterVetrn.length > 0) {
        updatedArray[index] = {
          ...updatedArray[index],
          VeterinaryDoctorServices: filterVetrn[0].VeterinaryDoctorServices,
          Qualification: filterVetrn[0].Qualification,
          mobileNumber: filterVetrn[0].mobileNumber,
          doctorAddress: filterVetrn[0].doctorAddress,
          registrationNumber: filterVetrn[0].registrationNumber,
          VDID: filterVetrn[0].VDID,
        };

        dispatchCattleDetails({
          type: 'UPDATE_CATTLE_DATA',
          payload: updatedArray[index],
          index: index,
        });

        try {
          const VDID = filterVetrn[0].VDID;
          const response: any = await getRequestByParams(GET_IMAGE_BY_VDID, VDID, "", "");
          if (response.status === 404) {
            console.log(`No image found for VDID ${VDID}`);
          }

          dispatchAuthorizeDeatils({
            type: "Authorised_Update",
            payload: {
              ...updatedArray[index],
              nameOfThePerson: filterVetrn[0].VeterinaryDoctorServices,
              designation: filterVetrn[0].Qualification,
              status: authoriseReducer.status,
              signature: response.data,
            },
          });
        } catch (error) {
          console.log(error);
        }

        setShow(true);
      }
    } else {
      setShow(true);
      updatedArray[index] = {
        ...updatedArray[index],
        [fieldName]: value,
      };
      // dispatchCattleDetails({
      //   type: 'UPDATE_CATTLE_DATA',
      //   payload: updatedArray[index],
      //   index: index,
      // });
    }
    setCattleDetailsArray(updatedArray);
  };

  const handleEditClick = (index: any) => {
    if (location.state === null) {
      setEditIndex(index);
      setEditClick(true);
      setAddClick(false);
      setCattleDetailsArray([getFormStateData[index]]);
    } else {
      setButtonEnable(true)
      setEditIndex(index);
      setEditClick(true);
      setCattleDetailsArray([...getFormStateData])
      setAddClick(false)

    }

  };

  useEffect(() => {
    cattleList.forEach((cattleRecord, index) => {
      dispatchCattleDetails({
        type: 'UPDATE_CATTLE_DATA',
        payload: cattleRecord,
        index: index,
      });
    })
    console.log("cattlelist", cattleList)
  }, [cattleList])


  useEffect(() => {
    if (location.state === null && !editClick && !addClick) {
      const newCattleDetails = {
        animalCategory: '',
        typeOfBreed: '',
        gender: '',
        age: '',
        AnimalDescription: '',
        purpose: '',
        marketValue: '',
        earTagNo: '',
        stabledAnimalAddress: '',
        veterinaryCertificate: 'Yes',
        VeterinaryDoctorServices: '',
        Qualification: '',
        mobileNumber: '',
        doctorAddress: '',
        vetCheckupDetails: '',
        diseaseType: '',
        dateOfIntimation: '',
        previousHistory: '',
        previousClaimDate: '',
        dateOfInceptionofDisease: '',
        treatmentDetails: '',
        DateofDeath: '',
        timeOfDeath: '',
        dateOfPostmortem: '',
        timeOfPostmortem: '',
        causeOfDeath: '',
        VDID: ''
      };
      setCattleDetailsArray([newCattleDetails]);
    }
  }, [])
  return (
    <>
      <Container maxWidth='md'>
        <Grid container justifyContent="end" sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{ mx: 5 }}>
            View Animal List
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCattleDetails}
            disabled={buttonEnable === false}
          >
            Add Cattle Details
          </Button>
        </Grid>

        {location.state === null ? (
          (cattleDetailsArray.map((cattleDetails: any, index: number) => (
            <div key={index}>
              {(location.state === null || index === editIndex) && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                        <Typography variant='h6' gutterBottom>
                          Animal Details
                        </Typography>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Animal Category'
                              autoFocus
                              size='small'
                              name='animalCategory'
                              select

                              value={cattleDetails.animalCategory}
                              onChange={(e) => handleFieldChange(index, 'animalCategory', e.target.value)}
                              fullWidth
                              margin='normal'
                            >
                              <MenuItem value='Cow'>Cow</MenuItem>
                              <MenuItem value='Buffalo'>Buffalo</MenuItem>
                              <MenuItem value='Bullock'>Bullock</MenuItem>
                              <MenuItem value='Other'>Other</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Gender'
                              size='small'
                              name='gender'
                              select // Make it a select input

                              value={cattleDetails.gender}
                              onChange={(e) => handleFieldChange(index, 'gender', e.target.value)}
                              fullWidth
                              margin='normal'
                            >
                              <MenuItem value='Male'>Male</MenuItem>
                              <MenuItem value='Female'>Female</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Age in Year'
                              size='small'
                              name='age'

                              value={cattleDetails.age}
                              onChange={(e) => {
                                validationHandler(e, 'float')
                                handleFieldChange(index, 'age', e.target.value)
                              }}
                              error={Boolean(error.age)}
                              helperText={error.age}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Animal Description'
                              size='small'
                              name='AnimalDescription'

                              value={cattleDetails.AnimalDescription}
                              onChange={(e) => {
                                validationHandler(e, 'message')
                                handleFieldChange(index, 'AnimalDescription', e.target.value)
                              }}
                              error={Boolean(error.AnimalDescription)}
                              helperText={error.AnimalDescription}
                              fullWidth
                              margin='normal'
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label='Type of Breed'
                              size='small'
                              name='typeOfBreed'
                              select // Make it a select input

                              value={cattleDetails.typeOfBreed}
                              onChange={(e) => handleFieldChange(index, 'typeOfBreed', e.target.value)}
                              fullWidth
                              margin='normal'
                            >
                              <MenuItem value='Cross_Breed'>Cross Breed</MenuItem>
                              <MenuItem value='Indigenous'>Indigenous</MenuItem>
                              <MenuItem value='Exotic'>Exotic</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Select Purpose'
                              size='small'
                              name='purpose'
                              select // Make it a select input

                              value={cattleDetails.purpose}
                              onChange={(e) => handleFieldChange(index, 'purpose', e.target.value)}
                              fullWidth
                              margin='normal'
                            >
                              <MenuItem value='Transport'>Transport</MenuItem>
                              <MenuItem value='Farming'>Farming</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Market Value/Sum Assured'
                              size='small'
                              name='marketValue'

                              value={cattleDetails.marketValue}
                              onChange={(e) => {
                                validationHandler(e, 'numeric')
                                handleFieldChange(index, 'marketValue', e.target.value)
                              }}
                              error={Boolean(error.marketValue)}
                              helperText={error.marketValue}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Ear Tag No.'
                              size='small'
                              name='earTagNo'

                              value={cattleDetails.earTagNo}
                              onChange={(e) => {
                                validationHandler(e, 'numeric')
                                handleFieldChange(index, 'earTagNo', e.target.value)
                              }}
                              error={Boolean(error.earTagNo)}
                              helperText={error.earTagNo}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Address if animals stabled'
                              size='small'
                              name='stabledAnimalAddress'

                              value={cattleDetails.stabledAnimalAddress}
                              onChange={(e) => {
                                validationHandler(e, 'address')
                                handleFieldChange(index, 'stabledAnimalAddress', e.target.value)
                              }}
                              error={Boolean(error.stabledAnimalAddress)}
                              helperText={error.stabledAnimalAddress}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                        <Typography variant='h6' gutterBottom>
                          Veterinary Details
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Veterinary Certificate'
                              size='small'
                              name='veterinaryCertificate'

                              value={cattleDetails.veterinaryCertificate}
                              disabled
                              fullWidth
                              //
                              margin='normal'
                            ></TextField>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Veterinary Doctor Name'
                              size='small'
                              name='VeterinaryDoctorServices'
                              select
                              value={cattleDetails.VeterinaryDoctorServices}
                              onChange={(e) => {
                                handleFieldChange(index, 'VeterinaryDoctorServices', e.target.value);
                                vetenaryHide();
                              }}
                              fullWidth
                              margin='normal'
                            >
                              <MenuItem value='select'>Select</MenuItem>
                              {veternList && veternList.length > 0
                                ? veternList.map((item: any) => (
                                  <MenuItem value={item.VeterinaryDoctorServices} key={item.VDID}>
                                    {item.VeterinaryDoctorServices}
                                  </MenuItem>
                                ))
                                : null}
                            </TextField>
                          </Grid>

                          {cattleDetails.VeterinaryDoctorServices !== "" && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Qualification'
                                  size='small'
                                  name='Qualification'
                                  value={cattleDetails.Qualification}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Registration Number'
                                  size='small'
                                  name='registrationNumber'

                                  value={cattleDetails.registrationNumber}

                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Mobile Number'
                                  size='small'
                                  name='mobileNumber'
                                  value={cattleDetails.mobileNumber}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Address of Doctor'
                                  size='small'
                                  name='doctorAddress'
                                  value={cattleDetails.doctorAddress}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>

                            </>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                        <Typography variant='h6' gutterBottom>
                          Insurance Claim Details
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              label='Details of Veterinary Check-Up for Insured Animals'
                              size='small'
                              name='vetCheckupDetails'

                              value={cattleDetails.vetCheckupDetails}
                              onChange={(e) => {
                                validationHandler(e, 'message')
                                handleFieldChange(index, 'vetCheckupDetails', e.target.value)
                              }}
                              error={Boolean(error.vetCheckupDetails)}
                              helperText={error.vetCheckupDetails}
                              fullWidth
                              //
                              margin='normal'

                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              label='Disease Type'
                              size='small'
                              name='diseaseType'

                              value={cattleDetails.diseaseType}
                              onChange={(e) => {
                                validationHandler(e, 'alphabetsAndSpace')
                                handleFieldChange(index, 'diseaseType', e.target.value)
                              }}
                              error={Boolean(error.diseaseType)}
                              helperText={error.diseaseType}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Date of Intimation'
                              size='small'
                              name='dateOfIntimation'

                              value={cattleDetails.dateOfIntimation}
                              onChange={(e) => handleFieldChange(index, 'dateOfIntimation', e.target.value)}
                              fullWidth
                              margin='normal'
                              type='date'
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              label='Previous History'
                              size='small'
                              name='previousHistory'
                              multiline
                              rows={4}

                              value={cattleDetails.previousHistory}
                              onChange={(e) => {
                                validationHandler(e, 'message')
                                handleFieldChange(index, 'previousHistory', e.target.value)
                              }}
                              error={Boolean(error.previousHistory)}
                              helperText={error.previousHistory}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Previous Claim Date'
                              size='small'
                              name='previousClaimDate'
                              type='date'

                              value={cattleDetails.previousClaimDate}
                              onChange={(e) => handleFieldChange(index, 'previousClaimDate', e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Date of Inception of disease'
                              size='small'
                              name='dateOfInceptionofDisease'
                              type='date'

                              value={cattleDetails.dateOfInceptionofDisease}
                              onChange={(e) => handleFieldChange(index, 'dateOfInceptionofDisease', e.target.value)} fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              margin='normal'
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              label='Treatment Details'
                              size='small'
                              name='treatmentDetails'

                              value={cattleDetails.treatmentDetails}
                              onChange={(e) => {
                                validationHandler(e, 'message')
                                handleFieldChange(index, 'treatmentDetails', e.target.value)
                              }}
                              error={Boolean(error.treatmentDetails)}
                              helperText={error.treatmentDetails}
                              fullWidth
                              margin='normal'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label='Date of Death'
                              name='DateofDeath'

                              value={cattleDetails.DateofDeath}
                              onChange={(e) => handleFieldChange(index, 'DateofDeath', e.target.value)}
                              type='date'
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label='Time of Death'
                              type='time'
                              name='timeOfDeath'

                              value={cattleDetails.timeOfDeath}
                              onChange={(e) => handleFieldChange(index, 'timeOfDeath', e.target.value)}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label='Date of Postmortem'
                              name='dateOfPostmortem'

                              value={cattleDetails.dateOfPostmortem}
                              onChange={(e) => handleFieldChange(index, 'dateOfPostmortem', e.target.value)}
                              type='date'
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label='Time of Postmortem'
                              name='timeOfPostmortem'

                              value={cattleDetails.timeOfPostmortem}
                              onChange={(e) => handleFieldChange(index, 'timeOfPostmortem', e.target.value)}
                              type='time'
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label='Cause of Death'
                              name='causeOfDeath'

                              value={cattleDetails.causeOfDeath}
                              onChange={(e) => {
                                validationHandler(e, 'message')
                                handleFieldChange(index, 'causeOfDeath', e.target.value)
                              }}
                              error={Boolean(error.causeOfDeath)}
                              helperText={error.causeOfDeath}
                              multiline
                            // rows={4}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid >
                </>
              )}
              {editClick && (
                cattleDetailsArray.map((cattleDetails: any, index: number) => {
                  return (
                    <div key={index}>
                      {index === editIndex && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                              <Typography variant='h6' gutterBottom>
                                Animal Details
                              </Typography>

                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Animal Category'
                                    autoFocus
                                    size='small'
                                    name='animalCategory'
                                    select

                                    value={cattleDetails.animalCategory}
                                    onChange={(e) => handleFieldChange(index, 'animalCategory', e.target.value)}
                                    fullWidth
                                    margin='normal'
                                  >
                                    <MenuItem value='Cow'>Cow</MenuItem>
                                    <MenuItem value='Buffalo'>Buffalo</MenuItem>
                                    <MenuItem value='Bullock'>Bullock</MenuItem>
                                    <MenuItem value='Other'>Other</MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Gender'
                                    size='small'
                                    name='gender'
                                    select // Make it a select input

                                    value={cattleDetails.gender}
                                    onChange={(e) => handleFieldChange(index, 'gender', e.target.value)}
                                    fullWidth
                                    margin='normal'
                                  >
                                    <MenuItem value='Male'>Male</MenuItem>
                                    <MenuItem value='Female'>Female</MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Age in Year'
                                    size='small'
                                    name='age'

                                    value={cattleDetails.age}
                                    onChange={(e) => {
                                      validationHandler(e, 'float')
                                      handleFieldChange(index, 'age', e.target.value)
                                    }}
                                    error={Boolean(error.age)}
                                    helperText={error.age}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Animal Description'
                                    size='small'
                                    name='AnimalDescription'

                                    value={cattleDetails.AnimalDescription}
                                    onChange={(e) => {
                                      validationHandler(e, 'message')
                                      handleFieldChange(index, 'AnimalDescription', e.target.value)
                                    }}
                                    error={Boolean(error.AnimalDescription)}
                                    helperText={error.AnimalDescription}
                                    fullWidth
                                    margin='normal'
                                  ></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label='Type of Breed'
                                    size='small'
                                    name='typeOfBreed'
                                    select // Make it a select input

                                    value={cattleDetails.typeOfBreed}
                                    onChange={(e) => handleFieldChange(index, 'typeOfBreed', e.target.value)}
                                    fullWidth
                                    margin='normal'
                                  >
                                    <MenuItem value='Cross_Breed'>Cross Breed</MenuItem>
                                    <MenuItem value='Indigenous'>Indigenous</MenuItem>
                                    <MenuItem value='Exotic'>Exotic</MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Select Purpose'
                                    size='small'
                                    name='purpose'
                                    select // Make it a select input

                                    value={cattleDetails.purpose}
                                    onChange={(e) => handleFieldChange(index, 'purpose', e.target.value)}
                                    fullWidth
                                    margin='normal'
                                  >
                                    <MenuItem value='Transport'>Transport</MenuItem>
                                    <MenuItem value='Farming'>Farming</MenuItem>
                                  </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Market Value/Sum Assured'
                                    size='small'
                                    name='marketValue'

                                    value={cattleDetails.marketValue}
                                    onChange={(e) => {
                                      validationHandler(e, 'numeric')
                                      handleFieldChange(index, 'marketValue', e.target.value)
                                    }}
                                    error={Boolean(error.marketValue)}
                                    helperText={error.marketValue}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Ear Tag No.'
                                    size='small'
                                    name='earTagNo'

                                    value={cattleDetails.earTagNo}
                                    onChange={(e) => {
                                      validationHandler(e, 'numeric')
                                      handleFieldChange(index, 'earTagNo', e.target.value)
                                    }}
                                    error={Boolean(error.earTagNo)}
                                    helperText={error.earTagNo}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Address if animals stabled'
                                    size='small'
                                    name='stabledAnimalAddress'

                                    value={cattleDetails.stabledAnimalAddress}
                                    onChange={(e) => {
                                      validationHandler(e, 'address')
                                      handleFieldChange(index, 'stabledAnimalAddress', e.target.value)
                                    }}
                                    error={Boolean(error.stabledAnimalAddress)}
                                    helperText={error.stabledAnimalAddress}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>

                          <Grid item xs={12}>
                            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                              <Typography variant='h6' gutterBottom>
                                Veterinary Details
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Veterinary Certificate'
                                    size='small'
                                    name='veterinaryCertificate'

                                    value={cattleDetails.veterinaryCertificate}
                                    disabled
                                    fullWidth
                                    //
                                    margin='normal'
                                  ></TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Veterinary Doctor Name'
                                    size='small'
                                    name='VeterinaryDoctorServices'
                                    select
                                    value={cattleDetails.VeterinaryDoctorServices}
                                    onChange={(e) => {
                                      handleFieldChange(index, 'VeterinaryDoctorServices', e.target.value);
                                      vetenaryHide();
                                    }}
                                    fullWidth
                                    margin='normal'
                                  >
                                    <MenuItem value='select'>Select</MenuItem>
                                    {veternList && veternList.length > 0
                                      ? veternList.map((item: any) => (
                                        <MenuItem value={item.VeterinaryDoctorServices} key={item.VDID}>
                                          {item.VeterinaryDoctorServices}
                                        </MenuItem>
                                      ))
                                      : null}
                                  </TextField>
                                </Grid>

                                {cattleDetails.VeterinaryDoctorServices !== "" && (
                                  <>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label='Qualification'
                                        size='small'
                                        name='Qualification'
                                        value={cattleDetails.Qualification}
                                        fullWidth
                                        margin='normal'
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label='Registration Number'
                                        size='small'
                                        name='registrationNumber'

                                        value={cattleDetails.registrationNumber}

                                        fullWidth
                                        margin='normal'
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label='Mobile Number'
                                        size='small'
                                        name='mobileNumber'
                                        value={cattleDetails.mobileNumber}
                                        fullWidth
                                        margin='normal'
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label='Address of Doctor'
                                        size='small'
                                        name='doctorAddress'
                                        value={cattleDetails.doctorAddress}
                                        fullWidth
                                        margin='normal'
                                      />
                                    </Grid>

                                  </>
                                )}
                              </Grid>
                            </Paper>
                          </Grid>

                          <Grid item xs={12}>
                            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                              <Typography variant='h6' gutterBottom>
                                Insurance Claim Details
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label='Details of Veterinary Check-Up for Insured Animals'
                                    size='small'
                                    name='vetCheckupDetails'

                                    value={cattleDetails.vetCheckupDetails}
                                    onChange={(e) => {
                                      validationHandler(e, 'message')
                                      handleFieldChange(index, 'vetCheckupDetails', e.target.value)
                                    }}
                                    error={Boolean(error.vetCheckupDetails)}
                                    helperText={error.vetCheckupDetails}
                                    fullWidth
                                    //
                                    margin='normal'

                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    label='Disease Type'
                                    size='small'
                                    name='diseaseType'

                                    value={cattleDetails.diseaseType}
                                    onChange={(e) => {
                                      validationHandler(e, 'alphabetsAndSpace')
                                      handleFieldChange(index, 'diseaseType', e.target.value)
                                    }}
                                    error={Boolean(error.diseaseType)}
                                    helperText={error.diseaseType}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Date of Intimation'
                                    size='small'
                                    name='dateOfIntimation'

                                    value={cattleDetails.dateOfIntimation}
                                    onChange={(e) => handleFieldChange(index, 'dateOfIntimation', e.target.value)}
                                    fullWidth
                                    margin='normal'
                                    type='date'
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    label='Previous History'
                                    size='small'
                                    name='previousHistory'
                                    multiline
                                    rows={4}

                                    value={cattleDetails.previousHistory}
                                    onChange={(e) => {
                                      validationHandler(e, 'message')
                                      handleFieldChange(index, 'previousHistory', e.target.value)
                                    }}
                                    error={Boolean(error.previousHistory)}
                                    helperText={error.previousHistory}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Previous Claim Date'
                                    size='small'
                                    name='previousClaimDate'
                                    type='date'

                                    value={cattleDetails.previousClaimDate}
                                    onChange={(e) => handleFieldChange(index, 'previousClaimDate', e.target.value)}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Date of Inception of disease'
                                    size='small'
                                    name='dateOfInceptionofDisease'
                                    type='date'

                                    value={cattleDetails.dateOfInceptionofDisease}
                                    onChange={(e) => handleFieldChange(index, 'dateOfInceptionofDisease', e.target.value)} fullWidth
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    margin='normal'
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    label='Treatment Details'
                                    size='small'
                                    name='treatmentDetails'

                                    value={cattleDetails.treatmentDetails}
                                    onChange={(e) => {
                                      validationHandler(e, 'message')
                                      handleFieldChange(index, 'treatmentDetails', e.target.value)
                                    }}
                                    error={Boolean(error.treatmentDetails)}
                                    helperText={error.treatmentDetails}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label='Date of Death'
                                    name='DateofDeath'

                                    value={cattleDetails.DateofDeath}
                                    onChange={(e) => handleFieldChange(index, 'DateofDeath', e.target.value)}
                                    type='date'
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label='Time of Death'
                                    type='time'
                                    name='timeOfDeath'

                                    value={cattleDetails.timeOfDeath}
                                    onChange={(e) => handleFieldChange(index, 'timeOfDeath', e.target.value)}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label='Date of Postmortem'
                                    name='dateOfPostmortem'

                                    value={cattleDetails.dateOfPostmortem}
                                    onChange={(e) => handleFieldChange(index, 'dateOfPostmortem', e.target.value)}
                                    type='date'
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label='Time of Postmortem'
                                    name='timeOfPostmortem'

                                    value={cattleDetails.timeOfPostmortem}
                                    onChange={(e) => handleFieldChange(index, 'timeOfPostmortem', e.target.value)}
                                    type='time'
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    label='Cause of Death'
                                    name='causeOfDeath'

                                    value={cattleDetails.causeOfDeath}
                                    onChange={(e) => {
                                      validationHandler(e, 'message')
                                      handleFieldChange(index, 'causeOfDeath', e.target.value)
                                    }}
                                    error={Boolean(error.causeOfDeath)}
                                    helperText={error.causeOfDeath}
                                    multiline
                                  // rows={4}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid >
                      )}
                    </div>
                  )
                })
              )}
            </div >
          )
          ))
        ) : (
          <>
            {editClick && (
              cattleDetailsArray.map((cattleDetails: any, index: number) => {
                return (
                  <div key={index}>
                    {index === editIndex && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                            <Typography variant='h6' gutterBottom>
                              Animal Details
                            </Typography>

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Animal Category'
                                  autoFocus
                                  size='small'
                                  name='animalCategory'
                                  select

                                  value={cattleDetails.animalCategory}
                                  onChange={(e) => handleFieldChange(index, 'animalCategory', e.target.value)}
                                  fullWidth
                                  margin='normal'
                                >
                                  <MenuItem value='Cow'>Cow</MenuItem>
                                  <MenuItem value='Buffalo'>Buffalo</MenuItem>
                                  <MenuItem value='Bullock'>Bullock</MenuItem>
                                  <MenuItem value='Other'>Other</MenuItem>
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Gender'
                                  size='small'
                                  name='gender'
                                  select // Make it a select input

                                  value={cattleDetails.gender}
                                  onChange={(e) => handleFieldChange(index, 'gender', e.target.value)}
                                  fullWidth
                                  margin='normal'
                                >
                                  <MenuItem value='Male'>Male</MenuItem>
                                  <MenuItem value='Female'>Female</MenuItem>
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Age in Year'
                                  size='small'
                                  name='age'

                                  value={cattleDetails.age}
                                  onChange={(e) => {
                                    validationHandler(e, 'float')
                                    handleFieldChange(index, 'age', e.target.value)
                                  }}
                                  error={Boolean(error.age)}
                                  helperText={error.age}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Animal Description'
                                  size='small'
                                  name='AnimalDescription'

                                  value={cattleDetails.AnimalDescription}
                                  onChange={(e) => {
                                    validationHandler(e, 'message')
                                    handleFieldChange(index, 'AnimalDescription', e.target.value)
                                  }}
                                  error={Boolean(error.AnimalDescription)}
                                  helperText={error.AnimalDescription}
                                  fullWidth
                                  margin='normal'
                                ></TextField>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  label='Type of Breed'
                                  size='small'
                                  name='typeOfBreed'
                                  select // Make it a select input

                                  value={cattleDetails.typeOfBreed}
                                  onChange={(e) => handleFieldChange(index, 'typeOfBreed', e.target.value)}
                                  fullWidth
                                  margin='normal'
                                >
                                  <MenuItem value='Cross_Breed'>Cross Breed</MenuItem>
                                  <MenuItem value='Indigenous'>Indigenous</MenuItem>
                                  <MenuItem value='Exotic'>Exotic</MenuItem>
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Select Purpose'
                                  size='small'
                                  name='purpose'
                                  select // Make it a select input

                                  value={cattleDetails.purpose}
                                  onChange={(e) => handleFieldChange(index, 'purpose', e.target.value)}
                                  fullWidth
                                  margin='normal'
                                >
                                  <MenuItem value='Transport'>Transport</MenuItem>
                                  <MenuItem value='Farming'>Farming</MenuItem>
                                </TextField>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Market Value/Sum Assured'
                                  size='small'
                                  name='marketValue'

                                  value={cattleDetails.marketValue}
                                  onChange={(e) => {
                                    validationHandler(e, 'numeric')
                                    handleFieldChange(index, 'marketValue', e.target.value)
                                  }}
                                  error={Boolean(error.marketValue)}
                                  helperText={error.marketValue}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Ear Tag No.'
                                  size='small'
                                  name='earTagNo'

                                  value={cattleDetails.earTagNo}
                                  onChange={(e) => {
                                    validationHandler(e, 'numeric')
                                    handleFieldChange(index, 'earTagNo', e.target.value)
                                  }}
                                  error={Boolean(error.earTagNo)}
                                  helperText={error.earTagNo}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Address if animals stabled'
                                  size='small'
                                  name='stabledAnimalAddress'

                                  value={cattleDetails.stabledAnimalAddress}
                                  onChange={(e) => {
                                    validationHandler(e, 'address')
                                    handleFieldChange(index, 'stabledAnimalAddress', e.target.value)
                                  }}
                                  error={Boolean(error.stabledAnimalAddress)}
                                  helperText={error.stabledAnimalAddress}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                            <Typography variant='h6' gutterBottom>
                              Veterinary Details
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Veterinary Certificate'
                                  size='small'
                                  name='veterinaryCertificate'

                                  value={cattleDetails.veterinaryCertificate}
                                  disabled
                                  fullWidth
                                  //
                                  margin='normal'
                                ></TextField>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Veterinary Doctor Name'
                                  size='small'
                                  name='VeterinaryDoctorServices'
                                  select
                                  value={cattleDetails.VeterinaryDoctorServices}
                                  onChange={(e) => {
                                    handleFieldChange(index, 'VeterinaryDoctorServices', e.target.value);
                                    vetenaryHide();
                                  }}
                                  fullWidth
                                  margin='normal'
                                >
                                  <MenuItem value='select'>Select</MenuItem>
                                  {veternList && veternList.length > 0
                                    ? veternList.map((item: any) => (
                                      <MenuItem value={item.VeterinaryDoctorServices} key={item.VDID}>
                                        {item.VeterinaryDoctorServices}
                                      </MenuItem>
                                    ))
                                    : null}
                                </TextField>
                              </Grid>

                              {cattleDetails.VeterinaryDoctorServices !== "" && (
                                <>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      label='Qualification'
                                      size='small'
                                      name='Qualification'
                                      value={cattleDetails.Qualification}
                                      fullWidth
                                      margin='normal'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      label='Registration Number'
                                      size='small'
                                      name='registrationNumber'

                                      value={cattleDetails.registrationNumber}

                                      fullWidth
                                      margin='normal'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      label='Mobile Number'
                                      size='small'
                                      name='mobileNumber'
                                      value={cattleDetails.mobileNumber}
                                      fullWidth
                                      margin='normal'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      label='Address of Doctor'
                                      size='small'
                                      name='doctorAddress'
                                      value={cattleDetails.doctorAddress}
                                      fullWidth
                                      margin='normal'
                                    />
                                  </Grid>

                                </>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                            <Typography variant='h6' gutterBottom>
                              Insurance Claim Details
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  label='Details of Veterinary Check-Up for Insured Animals'
                                  size='small'
                                  name='vetCheckupDetails'

                                  value={cattleDetails.vetCheckupDetails}
                                  onChange={(e) => {
                                    validationHandler(e, 'message')
                                    handleFieldChange(index, 'vetCheckupDetails', e.target.value)
                                  }}
                                  error={Boolean(error.vetCheckupDetails)}
                                  helperText={error.vetCheckupDetails}
                                  fullWidth
                                  //
                                  margin='normal'

                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  label='Disease Type'
                                  size='small'
                                  name='diseaseType'

                                  value={cattleDetails.diseaseType}
                                  onChange={(e) => {
                                    validationHandler(e, 'alphabetsAndSpace')
                                    handleFieldChange(index, 'diseaseType', e.target.value)
                                  }}
                                  error={Boolean(error.diseaseType)}
                                  helperText={error.diseaseType}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Date of Intimation'
                                  size='small'
                                  name='dateOfIntimation'

                                  value={cattleDetails.dateOfIntimation}
                                  onChange={(e) => handleFieldChange(index, 'dateOfIntimation', e.target.value)}
                                  fullWidth
                                  margin='normal'
                                  type='date'
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  label='Previous History'
                                  size='small'
                                  name='previousHistory'
                                  multiline
                                  rows={4}

                                  value={cattleDetails.previousHistory}
                                  onChange={(e) => {
                                    validationHandler(e, 'message')
                                    handleFieldChange(index, 'previousHistory', e.target.value)
                                  }}
                                  error={Boolean(error.previousHistory)}
                                  helperText={error.previousHistory}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Previous Claim Date'
                                  size='small'
                                  name='previousClaimDate'
                                  type='date'

                                  value={cattleDetails.previousClaimDate}
                                  onChange={(e) => handleFieldChange(index, 'previousClaimDate', e.target.value)}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label='Date of Inception of disease'
                                  size='small'
                                  name='dateOfInceptionofDisease'
                                  type='date'

                                  value={cattleDetails.dateOfInceptionofDisease}
                                  onChange={(e) => handleFieldChange(index, 'dateOfInceptionofDisease', e.target.value)} fullWidth
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  margin='normal'
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  label='Treatment Details'
                                  size='small'
                                  name='treatmentDetails'

                                  value={cattleDetails.treatmentDetails}
                                  onChange={(e) => {
                                    validationHandler(e, 'message')
                                    handleFieldChange(index, 'treatmentDetails', e.target.value)
                                  }}
                                  error={Boolean(error.treatmentDetails)}
                                  helperText={error.treatmentDetails}
                                  fullWidth
                                  margin='normal'
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label='Date of Death'
                                  name='DateofDeath'

                                  value={cattleDetails.DateofDeath}
                                  onChange={(e) => handleFieldChange(index, 'DateofDeath', e.target.value)}
                                  type='date'
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label='Time of Death'
                                  type='time'
                                  name='timeOfDeath'

                                  value={cattleDetails.timeOfDeath}
                                  onChange={(e) => handleFieldChange(index, 'timeOfDeath', e.target.value)}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label='Date of Postmortem'
                                  name='dateOfPostmortem'

                                  value={cattleDetails.dateOfPostmortem}
                                  onChange={(e) => handleFieldChange(index, 'dateOfPostmortem', e.target.value)}
                                  type='date'
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label='Time of Postmortem'
                                  name='timeOfPostmortem'

                                  value={cattleDetails.timeOfPostmortem}
                                  onChange={(e) => handleFieldChange(index, 'timeOfPostmortem', e.target.value)}
                                  type='time'
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label='Cause of Death'
                                  name='causeOfDeath'

                                  value={cattleDetails.causeOfDeath}
                                  onChange={(e) => {
                                    validationHandler(e, 'message')
                                    handleFieldChange(index, 'causeOfDeath', e.target.value)
                                  }}
                                  error={Boolean(error.causeOfDeath)}
                                  helperText={error.causeOfDeath}
                                  multiline
                                // rows={4}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid >
                    )}
                  </div>
                )
              })
            )}
            {(addClick && (
              cattleDetailsArray.map((cattleDetails: any, index: number) => {
                return (
                  <div key={index}>
                    {/* {index === editIndex && ( */}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                          <Typography variant='h6' gutterBottom>
                            Animal Details
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Animal Category'
                                autoFocus
                                size='small'
                                name='animalCategory'
                                select

                                value={cattleDetails.animalCategory}
                                onChange={(e) => handleFieldChange(index, 'animalCategory', e.target.value)}
                                fullWidth
                                margin='normal'
                              >
                                <MenuItem value='Cow'>Cow</MenuItem>
                                <MenuItem value='Buffalo'>Buffalo</MenuItem>
                                <MenuItem value='Bullock'>Bullock</MenuItem>
                                <MenuItem value='Other'>Other</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Gender'
                                size='small'
                                name='gender'
                                select // Make it a select input

                                value={cattleDetails.gender}
                                onChange={(e) => handleFieldChange(index, 'gender', e.target.value)}
                                fullWidth
                                margin='normal'
                              >
                                <MenuItem value='Male'>Male</MenuItem>
                                <MenuItem value='Female'>Female</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Age in Year'
                                size='small'
                                name='age'

                                value={cattleDetails.age}
                                onChange={(e) => {
                                  validationHandler(e, 'float')
                                  handleFieldChange(index, 'age', e.target.value)
                                }}
                                error={Boolean(error.age)}
                                helperText={error.age}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Animal Description'
                                size='small'
                                name='AnimalDescription'

                                value={cattleDetails.AnimalDescription}
                                onChange={(e) => {
                                  validationHandler(e, 'message')
                                  handleFieldChange(index, 'AnimalDescription', e.target.value)
                                }}
                                error={Boolean(error.AnimalDescription)}
                                helperText={error.AnimalDescription}
                                fullWidth
                                margin='normal'
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label='Type of Breed'
                                size='small'
                                name='typeOfBreed'
                                select // Make it a select input

                                value={cattleDetails.typeOfBreed}
                                onChange={(e) => handleFieldChange(index, 'typeOfBreed', e.target.value)}
                                fullWidth
                                margin='normal'
                              >
                                <MenuItem value='Cross_Breed'>Cross Breed</MenuItem>
                                <MenuItem value='Indigenous'>Indigenous</MenuItem>
                                <MenuItem value='Exotic'>Exotic</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Select Purpose'
                                size='small'
                                name='purpose'
                                select // Make it a select input

                                value={cattleDetails.purpose}
                                onChange={(e) => handleFieldChange(index, 'purpose', e.target.value)}
                                fullWidth
                                margin='normal'
                              >
                                <MenuItem value='Transport'>Transport</MenuItem>
                                <MenuItem value='Farming'>Farming</MenuItem>
                              </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Market Value/Sum Assured'
                                size='small'
                                name='marketValue'

                                value={cattleDetails.marketValue}
                                onChange={(e) => {
                                  validationHandler(e, 'numeric')
                                  handleFieldChange(index, 'marketValue', e.target.value)
                                }}
                                error={Boolean(error.marketValue)}
                                helperText={error.marketValue}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Ear Tag No.'
                                size='small'
                                name='earTagNo'

                                value={cattleDetails.earTagNo}
                                onChange={(e) => {
                                  validationHandler(e, 'numeric')
                                  handleFieldChange(index, 'earTagNo', e.target.value)
                                }}
                                error={Boolean(error.earTagNo)}
                                helperText={error.earTagNo}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Address if animals stabled'
                                size='small'
                                name='stabledAnimalAddress'

                                value={cattleDetails.stabledAnimalAddress}
                                onChange={(e) => {
                                  validationHandler(e, 'address')
                                  handleFieldChange(index, 'stabledAnimalAddress', e.target.value)
                                }}
                                error={Boolean(error.stabledAnimalAddress)}
                                helperText={error.stabledAnimalAddress}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                          <Typography variant='h6' gutterBottom>
                            Veterinary Details
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Veterinary Certificate'
                                size='small'
                                name='veterinaryCertificate'

                                value={cattleDetails.veterinaryCertificate}
                                disabled
                                fullWidth
                                //
                                margin='normal'
                              ></TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Veterinary Doctor Name'
                                size='small'
                                name='VeterinaryDoctorServices'
                                select
                                value={cattleDetails.VeterinaryDoctorServices}
                                onChange={(e) => {
                                  handleFieldChange(index, 'VeterinaryDoctorServices', e.target.value);
                                  vetenaryHide();
                                }}
                                fullWidth
                                margin='normal'
                              >
                                <MenuItem value='select'>Select</MenuItem>
                                {veternList && veternList.length > 0
                                  ? veternList.map((item: any) => (
                                    <MenuItem value={item.VeterinaryDoctorServices} key={item.VDID}>
                                      {item.VeterinaryDoctorServices}
                                    </MenuItem>
                                  ))
                                  : null}
                              </TextField>
                            </Grid>

                            {cattleDetails.VeterinaryDoctorServices !== "" && (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Qualification'
                                    size='small'
                                    name='Qualification'
                                    value={cattleDetails.Qualification}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Registration Number'
                                    size='small'
                                    name='registrationNumber'

                                    value={cattleDetails.registrationNumber}

                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Mobile Number'
                                    size='small'
                                    name='mobileNumber'
                                    value={cattleDetails.mobileNumber}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label='Address of Doctor'
                                    size='small'
                                    name='doctorAddress'
                                    value={cattleDetails.doctorAddress}
                                    fullWidth
                                    margin='normal'
                                  />
                                </Grid>

                              </>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
                          <Typography variant='h6' gutterBottom>
                            Insurance Claim Details
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label='Details of Veterinary Check-Up for Insured Animals'
                                size='small'
                                name='vetCheckupDetails'

                                value={cattleDetails.vetCheckupDetails}
                                onChange={(e) => {
                                  validationHandler(e, 'message')
                                  handleFieldChange(index, 'vetCheckupDetails', e.target.value)
                                }}
                                error={Boolean(error.vetCheckupDetails)}
                                helperText={error.vetCheckupDetails}
                                fullWidth
                                //
                                margin='normal'

                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label='Disease Type'
                                size='small'
                                name='diseaseType'

                                value={cattleDetails.diseaseType}
                                onChange={(e) => {
                                  validationHandler(e, 'alphabetsAndSpace')
                                  handleFieldChange(index, 'diseaseType', e.target.value)
                                }}
                                error={Boolean(error.diseaseType)}
                                helperText={error.diseaseType}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Date of Intimation'
                                size='small'
                                name='dateOfIntimation'

                                value={cattleDetails.dateOfIntimation}
                                onChange={(e) => handleFieldChange(index, 'dateOfIntimation', e.target.value)}
                                fullWidth
                                margin='normal'
                                type='date'
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label='Previous History'
                                size='small'
                                name='previousHistory'
                                multiline
                                rows={4}

                                value={cattleDetails.previousHistory}
                                onChange={(e) => {
                                  validationHandler(e, 'message')
                                  handleFieldChange(index, 'previousHistory', e.target.value)
                                }}
                                error={Boolean(error.previousHistory)}
                                helperText={error.previousHistory}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Previous Claim Date'
                                size='small'
                                name='previousClaimDate'
                                type='date'

                                value={cattleDetails.previousClaimDate}
                                onChange={(e) => handleFieldChange(index, 'previousClaimDate', e.target.value)}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label='Date of Inception of disease'
                                size='small'
                                name='dateOfInceptionofDisease'
                                type='date'

                                value={cattleDetails.dateOfInceptionofDisease}
                                onChange={(e) => handleFieldChange(index, 'dateOfInceptionofDisease', e.target.value)} fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                                margin='normal'
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label='Treatment Details'
                                size='small'
                                name='treatmentDetails'

                                value={cattleDetails.treatmentDetails}
                                onChange={(e) => {
                                  validationHandler(e, 'message')
                                  handleFieldChange(index, 'treatmentDetails', e.target.value)
                                }}
                                error={Boolean(error.treatmentDetails)}
                                helperText={error.treatmentDetails}
                                fullWidth
                                margin='normal'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label='Date of Death'
                                name='DateofDeath'

                                value={cattleDetails.DateofDeath}
                                onChange={(e) => handleFieldChange(index, 'DateofDeath', e.target.value)}
                                type='date'
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label='Time of Death'
                                type='time'
                                name='timeOfDeath'

                                value={cattleDetails.timeOfDeath}
                                onChange={(e) => handleFieldChange(index, 'timeOfDeath', e.target.value)}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label='Date of Postmortem'
                                name='dateOfPostmortem'

                                value={cattleDetails.dateOfPostmortem}
                                onChange={(e) => handleFieldChange(index, 'dateOfPostmortem', e.target.value)}
                                type='date'
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label='Time of Postmortem'
                                name='timeOfPostmortem'

                                value={cattleDetails.timeOfPostmortem}
                                onChange={(e) => handleFieldChange(index, 'timeOfPostmortem', e.target.value)}
                                type='time'
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label='Cause of Death'
                                name='causeOfDeath'

                                value={cattleDetails.causeOfDeath}
                                onChange={(e) => {
                                  validationHandler(e, 'message')
                                  handleFieldChange(index, 'causeOfDeath', e.target.value)
                                }}
                                error={Boolean(error.causeOfDeath)}
                                helperText={error.causeOfDeath}
                                multiline
                              // rows={4}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid >
                    {/* // )} */}
                  </div>
                )
              })
            )

            )}
          </>
        )

        }

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogContent>
            <Paper elevation={10}>
              <TableContainer className="scrollBarCss">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Animal Category
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Gender
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Age
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Animal Description
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Type Of Breed
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Ear Tag No
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Purpose
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Market Value
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Animal Address
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                          background: "#0288d1",
                          color: "white",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {location.state === null ? (
                    <TableBody>
                      {getFormStateData.map((cattleDetails: any, index: any) => (
                        <>
                          < TableRow key={index} >
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}
                            >
                              {cattleDetails.animalCategory}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.gender}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.age}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.AnimalDescription}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.typeOfBreed}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.earTagNo}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.purpose}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.marketValue}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.stabledAnimalAddress}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {/* <Button variant="contained" onClick={() => handleEditClick(index)}> */}
                              <Button variant="contained" onClick={() => {
                                handleEditClick(index);
                                setOpen(false); // Close the dialog after clicking Edit
                              }}>
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {getFormStateData.map((cattleDetails: any, index: any) => (
                        <>
                          < TableRow key={index} >
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}
                            >
                              {cattleDetails.animalCategory}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.gender}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.age}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.AnimalDescription}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.typeOfBreed}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.earTagNo}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.purpose}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.marketValue}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {cattleDetails.stabledAnimalAddress}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                fontSize: "15px",
                              }}>
                              {/* <Button variant="contained" onClick={() => handleEditClick(index)}> */}
                              <Button variant="contained" onClick={() => {
                                handleEditClick(index);
                                setOpen(false); // Close the dialog after clicking Edit
                              }}>
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>

          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>

            <Button onClick={handleClose} color="primary" variant='contained' >
              Close
            </Button>


          </DialogActions>
        </Dialog>
        <CommonSnackBar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleCloseSnackBar}
          message={snackMessage}
        />
      </Container >
    </>
  )
}

export default CattalDetails