import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import axios from "axios";
import { Autocomplete, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { GET_CATTLE_BY_OWNER_NAME, GET_IMAGE_BY_VDID, GET_IMAGE_FROM_S3_BUCKET, GET_MULTIPLE_CATTLE_BY_OWNER_NAME, GET_MULTIPLE_CATTLE_LIST, GET_OWNER_DATA_FOR_CATTLE, GET_VETERNARY_DOCTOR_BY_VDID, GET_VETERNARY_LIST } from "../../../Api/ServerConfiguration";
import { getRequestByParams, getRequest } from "../../../Api/ApiEndpointconfiguration";

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontFamily: "Times-Roman",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.7)'
  },
  boldText1: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.7)'
  },
  boldText2: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.7)'
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column1: {
    width: "100%",
    padding: 20,
  },
  column2: {
    width: "35%",
    padding: 20,
  },
  column3: {
    width: "50%",
    padding: 13,
  },
  column4: {
    width: "50%",
    padding: 13,
  },
  column5: {
    width: "30%",
    // padding: 20,
  },
  column6: {
    width: "30%",
    // padding: 20,
  },
  column7: {
    width: "40%",
    // padding: 20,
  },
  column8: {
    width: "5%",
    // padding: 20,
  },
  column9: {
    width: "45%",
    // padding: 20,
  },
  column10: {
    width: "5%",
    // padding: 20,
  },
  column11: {
    width: "45%",
    // padding: 20,
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  text1: {
    fontSize: 10,
    textAlign: "justify",
  },
  text2: {
    fontSize: 11,
    textAlign: "justify",
  },

  image: {
    height: 200,
    width: 200,
  },
  signature1: {
    width: "100%",
    marginLeft: 20,
  },
  right: {
    alignItems: "flex-end", // Center-align the content horizontally
    justifyContent: "flex-end", // Center-align the content vertically
    textAlign: "right",
  },
  divider: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    // marginBottom: 10, // Adjust margin to control spacing between sections
  },
  divider1: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    marginBottom: 10, // Adjust margin to control spacing between sections
    marginTop: 10,
  },
  divider2: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    // marginBottom: 10, // Adjust margin to control spacing between sections
    marginTop: 20,
  },
  divider3: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    marginBottom: 10, // Adjust margin to control spacing between sections
  },
  divider4: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    marginTop: 7, // Adjust margin to control spacing between sections
  },
  divider5: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    marginTop: 3, // Adjust margin to control spacing between sections
  },
  divider6: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    // marginTop: 7, // Adjust margin to control spacing between sections
  },
  center: {
    alignItems: "center", // Center-align the content horizontally
    justifyContent: "center", // Center-align the content vertically
    textAlign: "center", // Center-align the text within the Text component
  },

  table: {
    width: "100%",
    borderTop: 1,
    marginTop: 2,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
  },
  tableRow1: {
    flexDirection: "row",
    // borderBottomWidth: 1,
  },
  tableCell: {
    flex: 1,
    padding: 5,
    // borderStyle: "solid",
    // borderWidth: 1,
    borderRightWidth: 1,
  },
  imageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  imageWrapper: {
    width: '45%', // Adjust the width as needed
    margin: 10,
  },
});


export default function Report() {
  const [data, setData] = useState<any[]>([]);
  const [multipleCattle, setMultipleCattle] = useState<any[]>([])
  const [selectedOwner, setSelectedOwner] = useState("");
  const [Owner, setOwner] = useState<any[]>([]);
  const [image, setImage] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [multipleImage, setMultipleImage] = useState<any[]>([]);
  const [imageDataURI, setImageDataURI] = useState<any[]>([]);
  const [formId, setFormId] = useState<any[]>([]);
  const [registrationNumber, setRegistrationNumber] = useState<any>("");
  const [signature, setSignature] = useState<any>("");
  const [VDID, setVDID] = useState<any>();

  // GET ALL OWNERNAME
  const getOwner = async () => {
    try {
      // const response: any = await getRequest(GET_OWNER_DATA_FOR_CATTLE, "")
      const response: any = await getRequest(GET_MULTIPLE_CATTLE_LIST, "")
      const uniqueOwners: any[] = [];
      const ownerMap: Map<string, Set<string>> = new Map(); // Map to store owner names and unique Aadhar numbers

      response.data.forEach((item: any) => {
        const { ownerName, aadharNumber } = item;
        if (!ownerMap.has(ownerName)) {
          ownerMap.set(ownerName, new Set([aadharNumber]));
          uniqueOwners.push({ ownerName, aadharNumber });
        } else {
          const aadharSet = ownerMap.get(ownerName);
          if (!aadharSet?.has(aadharNumber)) {
            aadharSet?.add(aadharNumber);
            uniqueOwners.push({ ownerName, aadharNumber });
          }
        }
      });

      setOwner(uniqueOwners);
    } catch (error) {
      console.error(error);
    }
  }

  // GET DATA OF CATTLES USING OWNERNAME and ADHAR Number
  const getDetailsByOwnerName = async () => {
    try {
      // const response: any = await getRequestByParams(GET_CATTLE_BY_OWNER_NAME, "", "", selectedOwner)
      const response: any = await getRequestByParams(GET_MULTIPLE_CATTLE_BY_OWNER_NAME, "", "", selectedOwner)
      let collectAllFormId: any = []
      response.data.map(async (data: any) => {
        collectAllFormId.push(data.FormID)
      })

      // let cattleDetails = JSON.parse(response.data[0].cattleDetails)
      let cattleDetails = response.data[0].cattleDetails
      let AllCattleDetailsForOwner = JSON.parse(cattleDetails)
      let id = AllCattleDetailsForOwner[0].VDID;
      setVDID(id);
      if (VDID === null) {
        setSignature("")
        setRegistrationNumber("")
      }
      // } else {
      //   await fetchSignature(VDID);
      //   await fetchVeternaryDoctor(VDID);
      // }
      // setData(response.data);
      setData(response.data)
      setMultipleCattle(AllCattleDetailsForOwner)
      setFormId(collectAllFormId)

    } catch (error) {
      console.error(error);
    }
  };



  const onOwnerChange = (e: any, value: any) => {
    setSelectedOwner(value);
    setImageDataURI([]);
    setFormId([]);
    setVDID("");
    setRegistrationNumber("");
    setSignature("")
  }


  const tableHeaders = [
    "Animal                  Category ",
    "Ear Tag",
    "Breed",
    "Sex",
    "Age",
    "Description",
    "Market Value",
  ];

  const fetchImage = async (formId: any) => {
    try {
      setLoading(true)
      const response: any = await getRequestByParams(GET_IMAGE_FROM_S3_BUCKET, formId, "", "")

      if (response.status === "pending" || response.status === 204) {
        setLoading(true)
      }
      setImageDataURI((prevData) => [...prevData, ...response.data]);
      if (response.data.length === 0) {
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const fetchSignature = async (VDID: any) => {
    try {
      const response: any = await getRequestByParams(GET_IMAGE_BY_VDID, VDID, "", "")
      setSignature(response.data)
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const fetchVeternaryDoctor = async (VDID: any) => {
    try {
      const veternaryDetails: any = await getRequestByParams(GET_VETERNARY_DOCTOR_BY_VDID, VDID, "", "")
      setRegistrationNumber(veternaryDetails.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  useEffect(() => {
    if (formId.length > 0) {
      formId.forEach(formId => {
        fetchImage(formId)
      });
    }

  }, [formId])
  useEffect(() => {
    if (VDID) {
      fetchSignature(VDID);
      fetchVeternaryDoctor(VDID)
    }
  }, [VDID])
  useEffect(() => {
    getOwner();
  }, []);

  useEffect(() => {
    if (selectedOwner) {
      setLoading(true);
      setData([]);
      setMultipleCattle([])
      setImage([]);
      setVDID(""); // Reset VDID when changing the selected owner
      getDetailsByOwnerName();
    }
  }, [selectedOwner]);

  // format date
  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();

    // Pad single-digit day and month with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  useEffect(() => {
    if (imageDataURI.length > 0) {
      setLoading(false)
    }
  }, [imageDataURI])

  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <Autocomplete
        fullWidth
        sx={{ my: 3 }}
        disablePortal
        id="combo-box-demo"
        options={Owner}
        onChange={onOwnerChange}
        getOptionLabel={(option) => `${option.ownerName} - Aadhar(${option.aadharNumber})`}
        renderInput={(params) => <TextField {...params} label="Please Select Owner Name" />}
      />
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} className="progress_circle">
          <CircularProgress />
        </Box>
      )}
      {imageDataURI.map((imgData, index) => (
        <>
          {imgData.contentType === "pdf" && (
            <>
              <Button
                variant="contained"
                size="small"
                sx={{ mb: 2, px: 5, mx: 5 }}
                onClick={() => {
                  window.open(imgData.url);
                }}

              >
                Download PDF
              </Button>
            </>
          )}
        </>
      ))}
      <>
        {selectedOwner && data.length > 0 && (
          <PDFViewer style={{ width: "100%", height: "100%", color: "red" }} >
            <Document title={`${data?.[0].ownerName}_${new Date(Date.now()).toDateString()}`}>
              <>
                <Page size="A4" style={styles.page}>
                  <View style={styles.divider3} />
                  <View>
                    <Text style={{ ...styles.text, ...styles.center, ...styles.boldText1 }}>
                      VETERINARY SURGEON'S CERTIFICATE(CATTLE INSURANCE)
                    </Text>
                    <Text style={[styles.text1, styles.center]}>
                      (To be submitted along with proposal)
                    </Text>
                  </View>
                  <View style={styles.divider1} />
                  <View>
                    <Text style={{ ...styles.text2, marginBottom: 3 }}>
                      1: Name Of Owner(s):{"    "}
                      {data?.[0].ownerName}
                    </Text>
                    <Text style={{ ...styles.text2, marginBottom: 3 }}>
                      2: Address:{"    "}
                      {data[0]?.village} {data?.[0].city} {data[0]?.district} {data?.[0].state} {data?.[0].pinCode}
                    </Text>
                    <Text style={{ ...styles.text2, marginBottom: 3 }}>
                      3: Occupation:{"    "}
                      {data?.[0].occupation}
                    </Text>
                    <Text style={{ ...styles.text2, marginBottom: 3 }}>
                      4: Description Of the animal(s) proposed for insurance:
                    </Text>
                  </View>

                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      {tableHeaders.map((header, headerIndex) => (
                        <Text style={[styles.tableCell, styles.text1, styles.boldText2]} key={headerIndex}>
                          {header}
                        </Text>
                      ))}
                    </View>

                    {multipleCattle ? (
                      multipleCattle.map((row: any, rowIndex: any) => (
                        <View style={styles.tableRow} key={rowIndex}>
                          <Text style={[styles.tableCell, styles.text1]}>
                            {row.animalCategory}
                          </Text>
                          <Text style={[styles.tableCell, styles.text1]}>
                            {row.earTagNo}
                          </Text>
                          <Text style={[styles.tableCell, styles.text1]}>
                            {row.typeOfBreed}
                          </Text>
                          <Text style={[styles.tableCell, styles.text1]}>{row.gender}</Text>
                          <Text style={[styles.tableCell, styles.text1]}>{row.age}</Text>
                          <Text style={[styles.tableCell, styles.text1]}>{row.AnimalDescription}</Text>
                          <Text style={[styles.tableCell, styles.text1]}>
                            {row.marketValue}
                          </Text>
                        </View>
                      ))
                    ) : (
                      <Text>No data available</Text> // Handle case when data.details is not available
                    )}
                  </View>

                  <View style={styles.divider2} />
                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        5. (a) Is/are the animals sound, healthy in good condition and
                        free from vice.
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (b) Have any of the animals ever suffered from any disease,
                        illness or ailments ? If it is so give particulars.
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>No</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (c) Do they appear to be well cared for and regularly fed ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (d) Is there any affection of heart, spleen or liver ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>No</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (e) Is there any sign of Mastitis past or present ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>No</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (f) Is there any contagious or infectious disease prevalent in the
                        stable or its vicinity ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>No</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (g) Have all the animals been protected against Rinderpest,
                        Haemorrhagic Septicaemia, Anthrax, Black Quarter, F.M.D. ? Give
                        Dates of vaccinations for each disease datewise.
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        (h) Have all animals been Subjected to :
                      </Text>
                      <Text style={styles.text2}>
                        (i) Tuberculin test : If so, are they declared free from the
                        infections of Tuberculosis ?
                      </Text>
                      <Text style={{ ...styles.text2, marginTop: 5 }}>
                        (ii) Agglutination test of Tuberculosis and have negative reactors
                        been vaccinated against this disease ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={{ ...styles.text2, marginBottom: 5, marginTop: 10 }}>
                        Yes
                      </Text>
                      <Text style={{ ...styles.text2, marginTop: 10 }}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />


                </Page>
                <Page size="A4" style={styles.page}>
                  <View style={{ ...styles.divider2, marginTop: 40 }} />
                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        6. (a) What is the source of the supply of the stock ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>1. Market / 2. Own</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>(b) Wheather pregnant/dry </Text>
                      <Text style={styles.text2}>
                        (c) If pregnant, how many months ?{" "}
                      </Text>
                      <Text style={styles.text2}>
                        (d) Have the females calved at the owner's premises ? If it is so
                        was the calving normal ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>(e) Date of last calving</Text>
                      <Text style={styles.text2}>(f) Present Lactation in Litres</Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        7. Do you consider the risk normal ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        8. Are the stable conditions good and conducive to good health ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        9. Is there any other information you think the company should
                        know ? If this space is not sufficient give full information in
                        the space provided(below).
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                    </View>
                  </View>
                  <View style={styles.divider} />

                  <View style={styles.para1}>
                    <View style={styles.column3}>
                      <Text style={styles.text2}>
                        10. Do you recommend the company to accept the risk ?
                      </Text>
                    </View>
                    <View style={{ ...styles.column4, borderLeftWidth: 1 }}>
                      <Text style={styles.text2}>Yes</Text>
                    </View>
                  </View>
                  <View style={styles.divider} />
                  <View style={styles.para1}>
                    <Text style={{ ...styles.text2, marginTop: 20 }}>
                      {"                              "}I CERTIFY that I have this day
                      carefully examined the animals described in the above schedule and
                      that the particulars,values and answers the questions are correct to
                      the best of my knowledge and belief.
                    </Text>
                  </View>



                  <View style={{ ...styles.para1, marginTop: 40 }}>
                    <View style={{ ...styles.para1 }}>
                      <View style={styles.column5}>
                        <Text style={styles.text2}></Text>
                      </View>
                      <View style={{ ...styles.column6 }}>
                        <Text
                          style={{
                            ...styles.text2,
                            textAlign: "center",
                            marginLeft: 60,
                            padding: 3,
                          }}
                        >
                          Signature
                        </Text>
                      </View>
                      <View style={{ ...styles.column7, ...styles.center }}>
                        {signature ? (
                          <Image src={signature} style={{ height: "40px", width: "150px" }}></Image>
                        ) : null}
                      </View>

                    </View>
                    <View style={styles.column5}>
                      <Text style={styles.text2}></Text>
                    </View>
                    <View style={{ ...styles.column6 }}>
                      <Text style={{
                        ...styles.text2,
                        textAlign: "center",
                        marginLeft: 60,
                        padding: 3,
                      }}>
                        Qualification:
                      </Text>
                      <Text style={{
                        ...styles.text2,
                        textAlign: "center",
                        marginLeft: 60,
                        padding: 3,
                      }}>
                        Registration No:
                      </Text>
                      <Text style={{
                        ...styles.text2,
                        textAlign: "center",
                        marginLeft: 60,
                        padding: 3,
                      }}>

                      </Text>
                      <Text style={{
                        ...styles.text2,
                        textAlign: "center",
                        marginLeft: 60,
                        padding: 3,
                      }}>
                        Name and Address of Vet. Surgeon:
                      </Text>
                    </View>
                    <View style={{ ...styles.column7 }}>
                      {/* <View style={{ ...styles.divider4, padding: 3 }} /> */}
                      <Text style={{ ...styles.text2, marginTop: 5 }}>{multipleCattle[0].Qualification}</Text>
                      <View style={{ ...styles.divider6 }} />
                      {registrationNumber ? (
                        <>
                          <Text style={{ ...styles.text2, marginTop: 5 }}>{registrationNumber}</Text>
                          <View style={{ ...styles.divider6 }} />
                        </>
                      ) : null}

                      <Text style={{ ...styles.text2, marginTop: 5 }}>{multipleCattle[0].VeterinaryDoctorServices} </Text>
                      <View style={{ ...styles.divider6 }} />
                      <Text style={{ ...styles.text2, marginTop: 5 }}>{multipleCattle[0].doctorAddress} </Text>
                      <View style={{ ...styles.divider6 }} />
                      <View style={{ ...styles.divider4, padding: 3 }} />
                    </View>
                  </View>

                  <View style={{ ...styles.para1, marginTop: 40 }}>
                    <View style={styles.column8}>
                      <Text style={styles.text2}>Date</Text>
                    </View>
                    <View style={styles.column9}>
                      <Text style={{ ...styles.text2, marginLeft: 5 }}> {formatDate(data?.[0].created_date)}</Text>
                      <View style={{ ...styles.divider6, marginLeft: 5 }} />
                    </View>
                    <View style={styles.column10}>
                      <Text style={{ ...styles.text2, marginLeft: 5 }}>Place</Text>
                    </View>
                    <View style={styles.column11}>
                      {data?.[0].district !== "" ? (
                        <>
                          <Text style={{ ...styles.text2, marginLeft: 5 }}>{data?.[0].district}</Text>
                          <View style={{ ...styles.divider6, marginLeft: 5 }} />
                        </>
                      ) : (
                        <>
                          <View style={{ ...styles.divider4, paddingTop: 5 }} />
                        </>
                      )}
                    </View>

                  </View>

                  <View style={{ ...styles.divider5, marginTop: 20 }} />
                  <View style={{ ...styles.divider5 }} />
                  <View>
                    <Text
                      style={{
                        ...styles.text,
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                      NOTE
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        ...styles.text2,
                      }}
                    >
                      {"                      "}
                      *This report should in all cases be sent to the company and should
                      not be handed over or shown to the proposer. Examination fees are
                      payable by the proposer and should be recovered by the Veterinary
                      Surgeon direct from him.
                    </Text>
                  </View>
                  <View style={{ ...styles.divider5, marginTop: 20 }} />
                  <View style={{ ...styles.divider5 }} />
                  <View>
                    <Text
                      style={{
                        ...styles.text,
                        textAlign: "center",
                        padding: 5,
                        ...styles.boldText2,
                      }}
                    >
                      ADDITIONAL INFORMATION
                    </Text>
                  </View>
                </Page>
              </>
              {imageDataURI && imageDataURI.length > 0 && (
                <>
                  <Page size="A4" style={styles.page}>
                    <>
                      {/* 
                      <View style={styles.para1}>
                        {imageDataURI.map((imgData, index) => {
                          if (index % 2 === 0) {
                            const urlParts = imgData.url.split('/');
                            const folderIndex = urlParts.findIndex((part: any) => part.startsWith('cattle'));

                            if (folderIndex !== -1) {
                              const currentFolder = urlParts[folderIndex];
                              const isSameFolder = (otherUrl: string) => otherUrl.includes(currentFolder);

                              return (
                                <React.Fragment key={index}>
                                  <Text style={{ ...styles.text, ...styles.boldText1 }}>
                                    {currentFolder}
                                  </Text>
                                  <View style={styles.tableRow1}>
                                    <View style={{ ...styles.column4 }}>
                                      <Image src={imgData.imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                    </View>
                                    {index + 1 < imageDataURI.length && isSameFolder(imageDataURI[index + 1].url) && (
                                      <View style={{ ...styles.column4 }}>
                                        <Image src={imageDataURI[index + 1].imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                      </View>
                                    )}
                                  </View>
                                </React.Fragment>
                              );
                            }
                          }
                          return null;
                        })}
                      </View> */}
                      {/* <View style={styles.para1}>
                        {imageDataURI.map((imgData, index) => {
                          const urlParts = imgData.url.split('/');
                          const folderIndex = urlParts.findIndex((part: any) => part.startsWith('cattle'));

                          if (folderIndex !== -1) {
                            const currentFolder = urlParts[folderIndex];
                            const isSameFolder = (otherUrl: string) => otherUrl.includes(currentFolder);
                            const { contentType, url } = imgData;
                            console.log("imgData", imgData)
                            return (
                              <React.Fragment key={index}>
                                {contentType === "image" && (
                                  <View style={styles.tableRow1}>

                                    <View style={{ ...styles.column4 }}>
                                      <Text style={{ ...styles.text, ...styles.boldText1 }}>
                                        {currentFolder}
                                      </Text>
                                      <Image src={imgData.imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                    </View>
                                    {index + 1 < imageDataURI.length && isSameFolder(imageDataURI[index + 1].url) && (
                                      <View style={{ ...styles.column4 }}>
                                        <Text style={{ ...styles.text, ...styles.boldText1 }}>
                                          {currentFolder}
                                        </Text>
                                        <Image src={imageDataURI[index + 1].imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                      </View>
                                    )}
                                  </View>
                                )}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </View> */}
                      <View style={styles.para1}>
                        {imageDataURI.map((imgData, index) => {
                          const urlParts = imgData.url.split('/');
                          const folderIndex = urlParts.findIndex((part: any) => {
                            return part.startsWith('cattle') && !part.endsWith('.mp4');
                          });
                          if (folderIndex !== -1 && imgData.contentType === "image") {
                            const currentFolder = urlParts[folderIndex];
                            const isSameFolder = (otherUrl: string) => otherUrl.includes(currentFolder);
                            return (
                              <React.Fragment key={index}>
                                <View style={styles.tableRow1}>
                                  <View style={{ ...styles.column4 }}>
                                    {imgData.contentType !== "video" && (
                                      <Text style={{ ...styles.text, ...styles.boldText1 }}>
                                        {currentFolder}
                                      </Text>
                                    )}
                                    <Image src={imgData.imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                  </View>
                                  {/* {index + 1 < imageDataURI.length && isSameFolder(imageDataURI[index + 1].url) && (
                                    <View style={{ ...styles.column4 }}>
                                      {imgData.contentType !== "video" && (
                                        <Text style={{ ...styles.text, ...styles.boldText1 }}>
                                          {currentFolder}
                                        </Text>
                                      )}
                                      <Image src={imageDataURI[index + 1].imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                    </View>
                                  )} */}
                                </View>
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </View>


                    </>
                  </Page>
                </>
              )}
            </Document>
          </PDFViewer>
        )}
      </>


    </div >
  );
}
