import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TablePagination,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import { deleteRequest, getRequest } from "../../../Api/ApiMethods";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GET_DRREGISTRATION_LIST, DELETE_DRREGISTRATION_LIST } from "../../../Api/ServerConfiguration";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function AddDrRegistrationList() {
  const [users, setUsers] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const [users1, setUsers1] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");
  const [filterUser, setfilterUser] = useState<any[]>([]);
  const [filteredCount, setFilteredCount] = useState(0);

  const navigate = useNavigate();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navgationHandler = () => {
    navigate("/dashboard/Veterinary/AddDrRegistrationList/Create");
  };

  const fetchData = async () => {
    try {
      let response: any = await getRequest(GET_DRREGISTRATION_LIST, "");
      setUsers(response.data);
      setfilterUser(response.data)
      if (users) {
        console.log("GET_DRREGISTRATION_LIST", users);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteData = async (VDID: any) => {
    try {
      const res = await deleteRequest(DELETE_DRREGISTRATION_LIST, VDID, "");
      if (res) {
        // setUsers(res.data);
        // alert("Are you sure you want to delete this item?");
        console.log("deleteData")
      }
      setOpen(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const handleClose = () => {
    try {
      setOpen(false);
    } catch (error) {
      console.error("Error in handleClose:", error);
    }
  };
  useEffect(() => {
    fetchData();

  }, []);

  const handleMultipleFilter = (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue === "") {
      setfilterUser(users);
      setFilteredCount(users.length);
    } else {
      const filterResult = users.filter((item: any) => {
        return (
          Object.values(item).some((value) => {
            if (typeof value === "string" || typeof value === "number") {
              const itemValue = String(value).toLowerCase();
              return itemValue.includes(searchValue);
            }
            return false;
          }
          ));
      });

      setfilterUser(filterResult);
      setFilteredCount(filterResult.length);
    }

    setFilterVal(e.target.value);
  };

  return (
    <>
      <Container style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" size="large" onClick={navgationHandler}>
              Create
            </Button>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12} sm={6}></Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl>
              <TextField
                label="Search"
                size="small"
                sx={{ mx: 3, background: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={filterVal}
                onChange={(e: any) => {
                  setFilterVal(e.target.value);
                  handleMultipleFilter(e); // Call your filtering function here
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
              marginTop: "15px",
            }}
          >
            <Grid>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Dr Registration List
              </Typography>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  { }

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Veterinary Doctor Services
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Qualification
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Registration Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Mobile Number
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Doctor Address
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    District Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Email Address
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filterUser.length > 0 &&
                  filterUser
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item: any, index: any) => (
                      <React.Fragment key={item.VDID}>
                        <TableRow key={item.VDID}>
                          { }
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.VeterinaryDoctorServices}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.Qualification}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.registrationNumber}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.mobileNumber}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.doctorAddress}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.districtName}
                          </TableCell>


                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.emailAddress}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item?.status}
                            <Stack direction={"row"}>
                              <IconButton>
                                <EditIcon
                                  onClick={() =>
                                    navigate("/dashboard/Veterinary/AddDrRegistrationList/Edit", {
                                      state: item,

                                    })
                                  }
                                />
                              </IconButton>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {

                                    setUsers1(item.VDID);
                                    setOpen(true);
                                  }}
                                  sx={{ color: "red" }}
                                />
                              </IconButton></Stack>
                            { }
                          </TableCell>

                        </TableRow>
                      </React.Fragment>
                    ))}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>{"DELETE"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Are you sure you want to delete this?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => deleteData(users1)}>
                      Delete
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </TableBody>
            </Table>
          </TableContainer>
          {filterUser && filterUser.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filterUser?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Paper>
      </Container>
    </>
  );
}
export default AddDrRegistrationList;
