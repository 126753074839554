import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
// import Button from '@mui/material/Button';
import { Grid, Paper, Typography } from "@mui/material";
import useValidation from "../../../Validation/useValidation";
import { useDispatch, useSelector } from "react-redux";

const VeterainAuthorizationForm = () => {
  interface Authorization {
    nameOfThePerson: string;
    designation: string;
    signature: {
      name: String;
      path: String;
    };
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle form submission here
  };

  const { eventHandler } = useValidation();

  // const getFormStateData = useSelector((state: any) => state.AuthorisedReducer);

  const getFormStateData = useSelector((state: any) => state);
  const dispathAuthorisedDetails = useDispatch();
  // const [formData, setFormData] = useState({ ...getFormStateData });
  const [formData, setFormData] = useState({
    nameOfThePerson: getFormStateData.CattleReducer[0].VeterinaryDoctorServices,
    designation: getFormStateData.CattleReducer[0].Qualification,
    signature: getFormStateData.AuthorisedReducer.signature || "",
    status: getFormStateData.AuthorisedReducer.status || "Assigned",
    additionalInformation: getFormStateData?.AuthorisedReducer?.additionalInformation || ""
  })
  const [PreviewSignature, SetPreviewSignature] = useState<any>("")
  const [error, setError] = useState({
    nameOfThePerson: "",
    designation: "",
    signature: "",
    status: "Assigned",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFileChange = (e: any) => {
    const { name, files } = e.target;
    if (files[0]) {
      setFormData({
        ...formData,
        [name]: files[0],
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        SetPreviewSignature(e.target?.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      // No file selected, set formData.signature to an empty string
      setFormData({
        ...formData,
        [name]: "",
      });

    }
  };

  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (formData) {
      dispathAuthorisedDetails({
        type: "Authorised_Update",
        payload: formData,
      });
    }
    return () => { };
  }, [formData]);

  return (
    <>
      <Container maxWidth="sm">
        <Grid item container xs={12}>
          <Paper sx={{ border: "1px solid grey", p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Authorization Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name of the Person"
                  autoFocus
                  name="nameOfThePerson"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"

                  value={formData.nameOfThePerson}
                  onChange={(e: any) => {
                    validationHandler(e, "alphabetsAndSpace");
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Designation"
                  name="designation"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  value={formData.designation}
                  onChange={(e: any) => {
                    validationHandler(e, "alphabetsAndSpace");
                    handleChange(e);
                  }}
                // 
                />
              </Grid>

              {/* {formData.signature !== "" && (
                <Grid item xs={12} sm={6}>
                  <img
                    src={formData.signature}
                    alt="image"
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                </Grid>
              )}
              {PreviewSignature !== "" && (
                <Grid item xs={12} sm={6}>
                  <img
                    src={PreviewSignature}
                    alt="image"
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                </Grid>
              )} */}
              <Grid item xs={12} sm={6}>
                {/* Display formData.signature if it exists */}
                {formData.signature !== "" && PreviewSignature === "" && (
                  <img
                    src={formData.signature}
                    alt="image"
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                )}

                {/* Display PreviewSignature if it exists */}
                {PreviewSignature !== "" && (
                  <img
                    src={PreviewSignature}
                    alt="image"
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>

                <TextField
                  label="PreviewSignature"
                  name="signature"
                  type="file" // Change the type to 'file' for file upload
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="normal"
                  onChange={(e: any) => {
                    // validationHandler(e, "a");
                    handleFileChange(e);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: ".png", // Define accepted file types
                    maxFileSize: 1048576, // 1MB in bytes
                  }}
                  helperText="Upload a PNG with (max 1MB)"
                // 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </>
  );
};

export default VeterainAuthorizationForm;
