import { LOGIN, LOGOUT } from "../Actions/CommonAction"

export const loginInitialState = {
    login: false,
    isAuthenticated: false,
    userToken: ''
}

const LoginReducer = (state = loginInitialState, action: any ) =>{
    switch(action.type){
        case LOGIN: 
        return {
            ...state,
            login: action.payload.login,
            isAuthenticated: action.payload.login,
            userToken: action.payload.userToken
        }
        case LOGOUT: 
        return {
            ...loginInitialState
        }
        default: return {...state}
    }
}

export default LoginReducer;