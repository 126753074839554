import axios from "axios";

const getinitialized = (contetType: any, responseType: any, params?: any) => {
  let api = axios.create({
    params: params ? params : undefined,
    headers: {
      "Content-Type": contetType ? contetType : "application/json",
      "Access-Control-Allow-Origin": "*",
      "Beare-Token": "2232saddasdasd",
    },
  });
  return api;
};
// It will give break me to avoid a cors error

export const getRequest = (url: any, headers: any) => {
  return getinitialized("application/json", headers)
    .get(url, headers)
    .catch((error: any) => {
      console.log(error);
    });
};

export const getRequestById = (url: any, id: any, headers: any) => {
  const updatedUrl = `${url}/${id}`;
  return getinitialized("application/json", headers)
    .get(updatedUrl, headers)
    .catch((error: any) => {
      console.log(error);
    });
};
export const getRequestByParams = (
  url: any,
  id: any,
  headers: any,
  params?: any
) => {
  const updatedUrl = `${url}/${id}`;
  return getinitialized(headers, '', params)
    .get(updatedUrl, headers)
    .catch((error: any) => {
      console.error(error);
    });
};

// this method will use to post data in database
export const postrequestMethod = (url: any, contentType: any, data: any) => {
  return getinitialized(contentType, "application/json")
    .post(url, data)
    .catch((error: any) => {
      console.log(error);
    });
};
// this method will use to post data in database
export const postrequestMethodByParams = (
  url: any,
  id: any,
  contentType: any,
  data: any
) => {
  const updatedUrl = `${url}/${id}`; // Append the ID to the URL
  return getinitialized(contentType, "")
    .post(updatedUrl, data)
    .catch((error: any) => {
      console.log(error);
    });
};

// this method will use for update data in database
export const updateRequest = (url: any, id: any, data: any, headers: any) => {
  const updatedUrl = `${url}/${id}`; // Append the ID to the URL
  return getinitialized(headers, "application/json")
    .put(updatedUrl, data, headers) // Use put() for HTTP PUT requests or patch() for HTTP PATCH requests
    .catch((error: any) => {
      console.log(error);
    });
};

export const updateRequestwithVDID = (
  url: any,
  id: any,
  vdid: any,
  data: any,
  headers: any
) => {
  const updatedUrl = `${url}/${id}/${vdid}`; // Append the ID to the URL
  return getinitialized(headers, "application/json")
    .put(updatedUrl, data, headers) // Use put() for HTTP PUT requests or patch() for HTTP PATCH requests
    .catch((error: any) => {
      console.log(error);
    });
};

// this method will use for delete data in database
export const deleteRequest = (url: any, id: any, headers: any) => {
  const deleteUrl = `${url}/${id}`; // Append the ID to the URL
  return getinitialized("application/json", headers)
    .delete(deleteUrl, headers)
    .catch((error: any) => {
      console.log(error);
    });
};
