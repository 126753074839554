
import React, { useState, ChangeEvent, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Input from "@mui/material/Input";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { getRequestByParams } from "../../../Api/ApiEndpointconfiguration";
import { GET_IMAGE_FROM_S3_BUCKET } from "../../../Api/ServerConfiguration";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PDFViewer } from "@react-pdf/renderer";
import ReactDOM from "react-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Image: React.FC = () => {
  const bodyPart: string[] = [
    "Front view with Owner",
    "Left Side",
    "Right Side",
    "Ear Tag",
    "Additional Image",
    "Video",
  ];
  const allApplicationData = useSelector((state: any) => state);
  const previousAuthoriseData = useSelector((state: any) => state.AuthorisedReducer);
  let cattleDetails = allApplicationData.CattleReducer;
  const dispatchAllImages = useDispatch();
  // let conversionDetails = JSON.parse(allApplicationData?.AuthorisedReducer?.additionalInformation)
  useEffect(() => { }, []);
  const [imageURLs, setImageURLs] = useState<string[]>(Array(6).fill(""));
  const [formInformation, setformInformation] = useState<any>({});
  const [imageDataURI, setImageDataURI] = useState<any[]>([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false)
  const [manualUpload, setManualUpload] = useState(false);
  const [pdfUpload, setPdfUpload] = useState(false);
  const [allImage, setAllImage] = useState<any[]>([]);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [cattle, setCattle] = useState<any[]>([]);
  // const [accordionImages, setAccordionImages] = useState<{ [key: string]: any[] }>({});
  const [accordionImages, setAccordionImages] = useState<{ [key: string]: any[] }>({});
  const [dataURIs, setDataURIs] = useState<string[]>([]);
  const fetchImage = async (formId: any) => {
    try {
      // Use the correct endpoint and parameters in the request
      const response: any = await getRequestByParams(GET_IMAGE_FROM_S3_BUCKET, formId, "", "");
      // Assuming the response is an array of images
      setImageDataURI(response.data);
      setLoader(false)
    } catch (error) {
      console.error('Error fetching images:', error);
      setLoader(false)
    } finally {
      setLoader(false);
    }
    setLoader(false)
  };


  const handleImageUpload = (file: any, index: number, accordionIndex: number) => {
    const accordionKey = `cattle${accordionIndex + 1}`;
    let accordionImagesCopy = { ...accordionImages };

    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        const isVideo = file.type.startsWith('video/');

        accordionImagesCopy[accordionKey] = accordionImagesCopy[accordionKey] || [];
        accordionImagesCopy[accordionKey][index] = {
          contentType: isVideo ? 'video' : 'image',
          imageDataURI: isVideo ? event.target.result.toString() : event.target.result.toString(),
          file,
        };

        if (isVideo) {
          setIsVideoUploaded(true);
        }

        setAccordionImages(accordionImagesCopy);
      }
    };

    // Read as data URL
    reader.readAsDataURL(file);
  };

  const handlePdfUpload = (file: any, accordionIndex: number) => {
    const accordionKey = `cattle${accordionIndex + 1}`;
    let accordionImagesCopy = { ...accordionImages };

    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        accordionImagesCopy[accordionKey] = accordionImagesCopy[accordionKey] || [];
        accordionImagesCopy[accordionKey][0] = {
          contentType: 'pdf',
          imageDataURI: event.target.result.toString(),
          file,
        };

        setAccordionImages(accordionImagesCopy);
      }
    };

    // Read as data URL
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imageDataURI.length > 0) {
      dispatchAllImages({
        type: "Authorised_Update",
        payload: {
          status: "Completed"
        }
      })
    }
  }, [imageDataURI])



  useEffect(() => {
    setLoader(true)
    const formID = location?.state?.status;
    if (formID) {
      fetchImage(formID);
    }
  }, []);

  useEffect(() => {
    setCattle(cattleDetails)
  }, [cattleDetails])


  useEffect(() => {
    const newArray = Object.entries(accordionImages).map(([key, value]) => ({ [key]: value }));
    // Merge the array of objects into a single object
    const mergedObject = Object.assign({}, ...newArray);
    setAllImage(mergedObject);
    dispatchAllImages({
      type: "UPDATE_IMAGES",
      payload: mergedObject,
    });

  }, [accordionImages, dispatchAllImages]);

  useEffect(() => {
    if (accordionImages && Object.keys(accordionImages).length > 0) {
      dispatchAllImages({
        type: "Authorised_Update",
        payload: {
          ...previousAuthoriseData,
          status: "Completed"
        }
      });
    }
  }, [accordionImages, dispatchAllImages]);

  const getCattleName = (url: any) => {
    // Define a regular expression to extract the cattle name from the URL path
    const regex = /\/cattle(\d+)\//;
    const match = url.match(regex);

    // Check if a match is found and return the cattle name (or a default value)
    return match ? `Cattle ${match[1]}` : 'Unknown Cattle';
  };

  return (
    <>
      <Grid container spacing={2} className="imageContainerOuter">
        <>
          {location.state === null ?
            (
              null
            ) : location.state !== null && imageDataURI.length === 0 ? (
              <>
                <Grid item xs={12} sx={{ display: 'flex' }} >
                  <RadioGroup
                    value={manualUpload ? "manual" : pdfUpload ? "pdf" : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setManualUpload(value === "manual");
                      setPdfUpload(value === "pdf");
                    }}
                    row
                  >
                    <FormControlLabel value="manual" control={<Radio />} label="Manual Image Upload" />
                    <FormControlLabel value="pdf" control={<Radio />} label="Upload PDF" />
                  </RadioGroup>
                </Grid>
              </>
            ) : null}
        </>
        {location.state === null ? (
          null
        ) : loader && imageDataURI && imageDataURI.length === 0 ? (
          <>
            <Box sx={{ display: 'flex' }} className="progress_circle">
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            {imageDataURI && imageDataURI.length > 0 ? (
              <>
                {imageDataURI.map((imageURL, index) => (
                  <Grid item xs={12} sm={8} md={8} lg={4} key={index}>

                    <Card>
                      {imageURL?.contentType === 'pdf' ? ( // Only render CardHeader if content type is not 'pdf'
                        <CardHeader
                          title={
                            <Typography variant="h6" style={{ fontFamily: "YourFontFamily" }}>

                            </Typography>
                          }
                        />
                      ) : (
                        // <CardHeader
                        //   title={
                        //     <Typography variant="h6" style={{ fontFamily: "YourFontFamily" }}>
                        //       {/* {bodyPart[index]} */}
                        //     </Typography>
                        //   }
                        // />
                        <CardHeader
                          title={<Typography variant="h6">{getCattleName(imageURL.url)}</Typography>}
                        />
                      )}
                      {imageURL?.contentType === 'image' ? ( // If it's an image
                        <CardMedia component="img" height={"200px"} width={"200px"} image={imageURL.imageDataURI || ""}
                          onClick={() => {
                            window.open(imageURL.url);
                          }}
                        />
                      ) : imageURL?.contentType === 'video' ? ( // If it's a video
                        <CardMedia component="video" height={"200px"} width={"200px"} controls
                          onClick={() => {
                            window.open(imageURL.url);
                          }}>
                          <source src={imageURL.imageDataURI} type="video/mp4" />
                        </CardMedia>
                      ) : imageURL?.contentType === "pdf" ? (
                        <>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{ mx: 12, px: 2, my: 8 }}
                            onClick={() => {
                              window.open(imageURL.url);
                            }}

                          >
                            Download PDF
                          </Button>
                        </>
                      ) : (
                        <div>Error: Unsupported media type</div>
                      )}
                    </Card>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {manualUpload ? (
                  <>
                    <Grid container spacing={2}>
                      {cattle.map((cattleItem: any, accordionIndex: any) => (
                        <Grid item xs={12} key={accordionIndex}>
                          <Accordion >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${accordionIndex + 1}-content`}
                              id={`panel${accordionIndex + 1}-header`}
                            >
                              Cattle {accordionIndex + 1}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                {
                                  bodyPart.map((part, index) => (
                                    <Grid item xs={12} sm={8} md={8} lg={4} key={index}>
                                      <Card>
                                        <CardHeader
                                          title={
                                            <Typography
                                              variant="h6"
                                              style={{ fontFamily: "YourFontFamily" }}
                                            >
                                              {part}
                                            </Typography>
                                          }
                                        />
                                        {index === 5 ? ( // If it's the "Video" part
                                          <>
                                            {accordionImages[`cattle${accordionIndex + 1}`] && accordionImages[`cattle${accordionIndex + 1}`][index] && (
                                              < CardMedia
                                                component="video"
                                                height={"200px"}
                                                width={"200px"}
                                                controls
                                              >
                                                <source src={accordionImages[`cattle${accordionIndex + 1}`][index]?.imageDataURI} type="video/mp4" />
                                              </CardMedia>

                                            )}
                                            <CardContent>
                                              <TextField
                                                type="file"
                                                name="Images"
                                                key={`cattle_${index + 1}`}
                                                inputProps={{ accept: "video/*" }}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                  const file = e.target.files?.[0];
                                                  if (file) {
                                                    handleImageUpload(file, index, accordionIndex)
                                                  }
                                                }}
                                              />
                                              <Typography variant="body2">Upload a video file (max 10MB).</Typography>
                                            </CardContent>
                                          </>
                                        ) : (
                                          <>
                                            <Card>
                                              {/* <CardMedia component="img" height={"200px"} width={"200px"} image={imageURLs[index] || ""} /> */}
                                              {accordionImages[`cattle${accordionIndex + 1}`] && accordionImages[`cattle${accordionIndex + 1}`][index] && (
                                                <CardMedia
                                                  component="img"
                                                  height={"200px"}
                                                  width={"200px"}
                                                  image={accordionImages[`cattle${accordionIndex + 1}`][index].imageDataURI || ""}
                                                  onClick={() => {
                                                    window.open(accordionImages[`cattle${accordionIndex + 1}`][index]?.url);
                                                  }}
                                                />
                                              )}

                                              <CardContent>
                                                <TextField
                                                  type="file"
                                                  name="Images"
                                                  key={`cattle_${index + 1}`}
                                                  inputProps={{ accept: "image/*" }}
                                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    const file: any = e.target.files?.[0];
                                                    if (file) {
                                                      handleImageUpload(file, index, accordionIndex);
                                                    }
                                                  }}
                                                />
                                                <Typography variant="body2">
                                                  Upload a JPG or PNG file (max 1MB).
                                                </Typography>
                                              </CardContent>
                                            </Card>
                                          </>
                                        )}
                                      </Card>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      ))}
                    </Grid>

                  </>
                ) : (
                  <>
                    {pdfUpload && (
                      <>
                        {/* <Grid container spacing={2}>
                          {cattle.map((cattleItem: any, index: any) => (
                            <Grid item xs={12}>
                              <Accordion key={index}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${index + 1}-content`}
                                  id={`panel${index + 1}-header`}
                                >
                                  Cattle {index + 1}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container>
                                    <CardContent>
                                      <TextField
                                        type="file"
                                        name="Images"
                                        key={`cattle_${index + 1}`}
                                        inputProps={{ accept: ".pdf" }}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                          const file: any = e.target.files?.[0];
                                          if (file) {
                                            setAllImage([file])
                                          }
                                        }}
                                      />
                                      <Typography variant="body2">Upload a PDF file (max 10MB).</Typography>
                                    </CardContent>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          ))}
                        </Grid> */}
                        <Grid container spacing={2}>
                          {cattle.map((cattleItem: any, accordionIndex: any) => (
                            <Grid item xs={12} key={accordionIndex}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${accordionIndex + 1}-content`}
                                  id={`panel${accordionIndex + 1}-header`}
                                >
                                  Cattle {accordionIndex + 1}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container>
                                    <CardContent>
                                      <TextField
                                        type="file"
                                        name="Pdf"
                                        inputProps={{ accept: ".pdf" }}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                          const file: any = e.target.files?.[0];
                                          if (file) {
                                            handlePdfUpload(file, accordionIndex);
                                          }
                                        }}
                                      />
                                      <Typography variant="body2">Upload a PDF file (max 10MB).</Typography>
                                    </CardContent>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </>)}
              </>
            )
            }
          </>
        )
        }
      </Grid >
    </>
  );
};

export default Image;