import { updateOwnerData } from '../Actions/CommonAction'

export const OwnerInitialState = {
  twhandleBar: '',
  twlhShocker: '',
  twfork: '',
  twrhShocker: '',
  twindicators: '',
  twmudguard: '',
  twchassis: '',
  twlegGuard: '',
  twwheelRim: '',
  twengine: '',
  twlhCowl: '',
  twrhCowl: '',
  twtailLight: '',
  twrearShocker: '',
  twrearMudguard: '',
  twremarks: '',
}

const TwoWheelerReducer = (state = OwnerInitialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_TWOWHEELER_DATA':
      return {
        ...state,
        twhandleBar: action.payload.twhandleBar,
        twlhShocker: action.payload.twlhShocker,
        twfork: action.payload.twfork,
        twrhShocker: action.payload.twrhShocker,
        twindicators: action.payload.twindicators,
        twmudguard: action.payload.twmudguard,
        twlegGuard: action.payload.twlegGuard,
        twchassis: action.payload.twchassis,
        twengine: action.payload.twengine,
        twlhCowl: action.payload.twlhCowl,
        twrhCowl: action.payload.twrhCowl,
        twtailLight: action.payload.twtailLight,
        twrearShocker: action.payload.twrearShocker,
        twrearMudguard: action.payload.twrearMudguard,
        twremarks: action.payload.twremarks,
        twwheelRim: action.payload.twwheelRim
      }
    case 'CLEAR_TWOWHEELER_DATA':
      return { ...OwnerInitialState }
    default:
      return state
  }
}

export default TwoWheelerReducer
