import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TablePagination,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { GET_AUTOMOBILE_LIST, GET_IMAGE_BY_FORMID } from "../../Api/ServerConfiguration";
import { useDispatch } from "react-redux";
import { getRequestByParams } from "../../Api/ApiEndpointconfiguration";
import SearchIcon from "@mui/icons-material/Search";

function AutomobileList() {
  const [users, setUsers] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatchAutomobile = useDispatch();
  const [filterUser, setFilterUsers] = useState<any[]>([]);
  const [filteredCount, setFilteredCount] = useState(0);
  const [filterVal, setFilterVal] = useState("");


  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    try {
      return <Slide direction="up" ref={ref} {...props} />;
    } catch (error) {
      console.error("Error in Transition component:", error);
      return null;
    }
  });

  const navgationHandler = () => {
    dispatchAutomobile({ type: "RESET_AUTOMOBILE_DATA" });
    dispatchAutomobile({ type: "RESET_OWNER_AUTO_DATA" });
    dispatchAutomobile({ type: "AUTO_AUTHORISED_RESET" });
    navigate("/dashboard/Automobiles/Create");
  };

  const loadUser = async () => {
    axios
      .get(GET_AUTOMOBILE_LIST)
      .then((response: any) => {
        // const output: any = [];
        if (response.data) {
          const today = new Date();
          const lastSevenDays = new Date(
            today.getTime() - 7 * 24 * 60 * 60 * 1000
          );
          const filteredRecords = response.data.filter((record: any) => {
            const recordDate = new Date(record.db_added_date);
            return recordDate >= lastSevenDays && recordDate <= today;
          });
          const sortedFilteredRecords = filteredRecords.sort(
            (a: any, b: any) => {
              // Sort records based on the created_date in descending order
              return (
                new Date(b.db_added_date).getTime() -
                new Date(a.db_added_date).getTime()
              );
            }
          );
          setUsers(response.data);
          setFilterUsers(sortedFilteredRecords);
        }

      })
      .catch((error: any) => {
        // Handle any errors
        console.error(error);
      });
  };

  const handleMultipleFilter = (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue === "") {
      // If the search input is empty, display the whole list
      const today = new Date();
      const lastSevenDays = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      const filteredRecords = users.filter((record: any) => {
        const recordDate = new Date(record.db_added_date);
        return recordDate >= lastSevenDays && recordDate <= today;
      });
      const sortedFilteredRecords = filteredRecords.sort((a: any, b: any) => {
        // Sort records based on the created_date in descending order
        return (
          new Date(b.db_added_date).getTime() -
          new Date(a.db_added_date).getTime()
        );
      });
      setFilterUsers(sortedFilteredRecords);
      setFilteredCount(users.length);
    } else {
      const filterResult = users.filter((item: any) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });

      setFilterUsers(filterResult);
      setFilteredCount(filterResult.length);
    }
    setFilterVal(e.target.value);
  };
  const navgationEditHandler = async (item: any) => {
    if (item) {
      dispatchAutomobile({ type: "UPDATE_VEHICLE_DATA", payload: item });
      dispatchAutomobile({ type: "UPDATE_AUTO_OWNER_DATA", payload: item });

      try {
        const formId = item.formId;
        if (formId === null || formId === undefined || formId === "") {
          console.log("NO formId FOUND");
        } else {
          const response: any = await getRequestByParams(GET_IMAGE_BY_FORMID, formId, "", "");
          if (response !== undefined) {
            dispatchAutomobile({
              type: "Authorised_Update",
              payload: {
                ...item,
                signature: response.data, // Assuming `image` is the base64-encoded string of the image
              },

            });
          } else {
            dispatchAutomobile({
              type: "Authorised_Update",
              payload: {
                ...item,
                signature: "",
              },
            })
          }
        }
        dispatchAutomobile({ type: "UPDATE_TWOWHEELER_DATA", payload: item });
        dispatchAutomobile({ type: "UPDATE_FOURWHEELER_DATA", payload: item });
        dispatchAutomobile({ type: "UPDATE_COMMERCIAL_DATA", payload: item });
        dispatchAutomobile({ type: "UPDATE_OTHERS_DATA", payload: item });
      } catch (error) {
        console.log(error);
      }
      // dispatchAutomobile({ type: "Authorised_Update", payload: item });
      navigate("/dashboard/Automobiles/Create", { state: { status: item.formId } });
    }
  }

  const exportToCSV = () => {
    const fileType: string =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension: string = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(users);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `myFile${fileExtension}`);
  };

  useEffect(() => {
    loadUser();
  }, []);



  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" size="large" onClick={navgationHandler}>
              Create
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="contained" onClick={exportToCSV}>
              Export
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            // sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl>
              <TextField
                label="Search"
                size="small"
                value={filterVal}
                // onChange={(e: any) => handleFilter(e)}
                // onChange={(e: any) => handleMultipleFilter(e)}
                onChange={(e: any) => {
                  setFilterVal(e.target.value);
                  handleMultipleFilter(e); // Call your filtering function here
                }}
                sx={{ mx: 3, background: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

        </Grid>

        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
              marginTop: "15px",
            }}
          >
            <Grid>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Automobile List
              </Typography>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Form No.
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Inspection Agency
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Job Type
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Owner Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Insurance Company
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action / Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filterUser.length > 0 &&
                  filterUser
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <React.Fragment key={item._id}>
                        <TableRow key={item._id}>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.formId}
                          </TableCell>

                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.inspectionAgency}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.jobType}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.ownerName}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.insuranceCompany}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            <div style={{ fontSize: 15, fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>{item?.status}</div>
                            <Button
                              variant="contained"
                              size="large"
                              onClick={() => navgationEditHandler(item)}
                            >
                              Edit
                            </Button>
                            {/* <Button variant="contained" onClick={()=>navgationHandler(item)}>Edit</Button> */}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          {filterUser.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filterUser.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Paper>
      </Container>
    </>
  );
}

export default AutomobileList;
