import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';
import useValidation from '../../../Validation/useValidation';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

const Others = () => {
  
  const getAutomobileFormStateData = useSelector((state: any) => state.OthersReducer);
  
  const [formData, setFormData] = useState({...getAutomobileFormStateData}  );
  const {eventHandler}= useValidation()
  const [error, setError] = useState({
    otherfrontBumper: '',
    othercabin: '',
    othercabinGlasses: '',
    otherboom: '',
    othertyres: '',
    otherhydraulicSystems: '',
    otherremarks: '',
    otherengine: '',
   });

   const [companies, setCompanies] = useState([]);
    const fetchCompanies = (selectedChannel:any) => {
    
      if (selectedChannel === 'Insurance') {
   
      } else if (selectedChannel === 'Bank') {
  
      } else {
        setCompanies([]);
      }
    };
  

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const dispatchOthers = useDispatch();

  useEffect(() => {
    if (formData) {
      dispatchOthers({ type: "UPDATE_OTHERS_DATA", payload: formData });
    }
  }, [formData]);

  const handleSubmit = (e:any) => {
    e.preventDefault();
  };

  const validationHandler = async (e:any, alterName:any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {}
       <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
             <Typography variant="h6" gutterBottom>
                Others
              </Typography>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
            <TextField
              label="Front Bumper"
              size='small' 
              name="otherfrontBumper"
              value={formData.otherfrontBumper}
              onChange={handleChange}
              fullWidth
              margin="normal"
              />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="cabin"
              size='small' 
              name="othercabin"
              value={formData.othercabin}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="cabin Glasses"
              size='small' 
              name="othercabinGlasses"
              value={formData.othercabinGlasses}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={12}>
            <TextField
              label="boom"
              size='small' 
              name="otherboom"
              value={formData.otherboom}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={12}>
            <TextField
              label="tyres"
              size='small' 
              name="othertyres"
              value={formData.othertyres}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
              label="engine"
              size='small' 
              name="otherengine"
              value={formData.otherengine}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
              label="Hydraulic Systems"
              size='small' 
              name="otherhydraulicSystems"
              value={formData.otherhydraulicSystems}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
              label="remarks"
              size='small' 
              name="otherremarks"
              value={formData.otherremarks}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          {}
       </Grid>
         </Paper>
          </Grid>
          {}
          {}
          {}
          {}
       </Grid>
         </form>
          </Container>
  );
};

export default Others;