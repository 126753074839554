import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  Container,
  ImageList,
  ImageListItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Aboutus_Image from "../../Assets/Images/about-img.png";
import { useNavigate } from "react-router-dom";


export default function About() {
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate("/About_Content");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Container maxWidth="xl" className="about_container1">

        <Container maxWidth="lg" className="about_container2">
          <Box>
            <Grid container columnSpacing={10}>
              <Grid item xs={12} sm={12} md={6} marginTop={5}>
                <Stack direction="row" spacing={2} className="about_stack">
                  <Typography variant="h4" className="about_typography1">
                    About Our Company
                  </Typography>
                </Stack>
                <Typography variant="subtitle2" className="about_typography2">
                  Finguard Technologies Pvt. Ltd was incorporated in 2022 with
                  its Registered office located at Pune under the leadership of
                  various professionals having experience of Insurance,
                  Marketing and Finance .The vision of the Company is to provide
                  quality professional services to different sectors like
                  Insurance, Banking and other Financial Institutions with its
                  head office at Pune and Branch office at Mumbai (Thane). We
                  have also on our Board, Associated Risk Management Services
                  which will provide Technical as well as Manpower support. The
                  vision of Finguard Technologies is to provide Quality services
                  along with AI based Technological support catering to the
                  needs of the industry. The Management Team has an experience
                  of overseeing loss shipment claims, valuations etc in
                  Properties, Marine, Motor Own Damage, Motor Third Party,
                  Liability, Rural and Miscellaneous, We have also done close to
                  1,00,000 pre inspection of vehicles so far. With our
                  conviction to make a difference, we have invested into making
                  a state of art App which is in an advanced form of
                  development.
                </Typography>

                <Grid item xs={12} md={6} className="about_grid">
                  <Button
                    variant="contained"
                    className="about_grid_button"
                    onClick={handleReadMoreClick}
                  >
                    Read More <ArrowForwardIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ImageList className="about_grid_imagelist" cols={1}>
                  <ImageListItem>
                    <img
                      src={Aboutus_Image}
                      loading="lazy"
                      alt="About university"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  );
}
