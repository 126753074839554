import { updateOwnerData } from '../Actions/CommonAction'

export const VehicleInitialState = {
  permitValidFromDate: '',
  // registrationCertificateNumber: '',
  registrationDate: '',
  registeredAtRto: '',
  rcStatus: '',
  manufacturingDate: '',
  // ownerSerialNumber: '',
  fuelNorms: '',
  engineCubicCapacity: '',
  ncrbStatus: '',
  blackListStatus: '',
  nocDetails: '',
  permitNo: '',
  permitIssueDate: '',
  // vehicleType: '',
  permitType: '',
  permitValidUptoDate: '',
  rcFitnessValidity: '',
  rcTaxValidity: '',
  insurancePolicyNumber: '',
  rcStatusAsOn: '',
  insuranceValidity: '',
  dateOfIntimation: '',
  date: '',
  time: '',
  nameOfHospital: '',
  firInitial: '',
  firFinal: '',
  fireDepartment: '',
  nameOfDriver: '',
  driverDisability: '',
  advocateName: '',
  city: '',
  state: '',
  referenceNumber: '',
  zipCode: '',
  recommandationForInsurance: '',
  salesChannel: '',
  remark: '',
  company: '',
  registrationCertificateNo: '',
  executiveAssign: '',
  vehicleType: ''
}

const vehicleDataReducer = (state = VehicleInitialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_VEHICLE_DATA':
      return {
        ...state,
        permitValidFromDate: action.payload.permitValidFromDate,
        // registrationCertificateNumber: action.payload. registrationCertificateNumber,
        registrationDate: action.payload.registrationDate,
        registeredAtRto: action.payload.registeredAtRto,
        rcStatus: action.payload.rcStatus,
        manufacturingDate: action.payload.manufacturingDate,
        // ownerSerialNumber: action.payload.ownerSerialNumber,
        fuelNorms: action.payload.fuelNorms,
        engineCubicCapacity: action.payload.engineCubicCapacity,
        // veterinaryCertificate: 'Yes',

        ncrbStatus: action.payload.ncrbStatus,
        blackListStatus: action.payload.blackListStatus,
        nocDetails: action.payload.nocDetails,
        permitNo: action.payload.permitNo,
        permitIssueDate: action.payload.permitIssueDate,
        vehicleType: action.payload.vehicleType,
        permitType: action.payload.permitType,
        permitValidUptoDate: action.payload.permitValidUptoDate,
        rcFitnessValidity: action.payload.rcFitnessValidity,
        rcTaxValidity: action.payload.rcTaxValidity,
        insurancePolicyNumber: action.payload.insurancePolicyNumber,
        rcStatusAsOn: action.payload.rcStatusAsOn,
        insuranceValidity: action.payload.insuranceValidity,
        dateOfIntimation: action.payload.dateOfIntimation,
        date: action.payload.date,
        time: action.payload.time,
        nameOfHospital: action.payload.nameOfHospital,
        firInitial: action.payload.firInitial,
        firFinal: action.payload.firFinal,
        fireDepartment: action.payload.fireDepartment,
        nameOfDriver: action.payload.nameOfDriver,
        driverDisability: action.payload.driverDisability,
        advocateName: action.payload.advocateName,
        city: action.payload.city,
        state: action.payload.state,
        referenceNumber: action.payload.referenceNumber,
        zipCode: action.payload.zipCode,
        recommandationForInsurance: action.payload.recommandationForInsurance,
        salesChannel: action.payload.salesChannel,
        remark: action.payload.remark,
        company: action.payload.company,
        registrationCertificateNo: action.payload.registrationCertificateNo,
        executiveAssign: action.payload.executiveAssign

        // Update the owner data with the payload
      }

    case 'RESET_AUTOMOBILE_DATA':
      return { ...VehicleInitialState }
    default:
      return state
  }
}

export default vehicleDataReducer
