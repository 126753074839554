import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';
import useValidation from '../../../Validation/useValidation';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';



const Twowheelerform = () => {
  const getAutomobileFormStateData = useSelector((state: any) => state.TwoWheelerReducer);
  const [formData, setFormData] = useState({...getAutomobileFormStateData});
  const {eventHandler}= useValidation()
  const [error, setError] = useState({
    twhandleBar: '',
    twfork:'',
    twlhShocker: '',
    twrhShocker: '',
    twindicators: '',
    twmudguard: '',
    twchassis: '',
    twlegGuard: '',
    twwheelRim: '',
    twengine: '',
    twlhCowl: '',
    twrhCowl: '',
    twtailLight:'',
    twrearShocker: '',
    twrearMudguard: '',
    twtwremarks: '',
    });

const [companies, setCompanies] = useState([]);
   
const fetchCompanies = (selectedChannel:any) => {
    
      if (selectedChannel === 'Insurance') {

      } else if (selectedChannel === 'Bank') {
   
      } else {
        setCompanies([]);
      }
    };
  

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const dispatchTwoWheeler = useDispatch();

  useEffect(() => {
    if (formData) {
      dispatchTwoWheeler({ type: "UPDATE_TWOWHEELER_DATA", payload: formData });
    }
  }, [formData]);

  const handleSubmit = (e:any) => {
    e.preventDefault();
  };

  const validationHandler = async (e:any, alterName:any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Two Wheeler
              </Typography>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
            <TextField
              label="Handle Bar"
              size='small' 
              name="twhandleBar"
              value={formData.twhandleBar}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
              label="Fork"
              size='small' 
              name="twfork"
              value={formData.twfork}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="LH Shocker"
              size='small' 
              name="twlhShocker"
              value={formData.twlhShocker}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
              label="RH Shocker"
              size='small' 
              name="twrhShocker"
              value={formData.twrhShocker}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
              label="Indicator"
              size='small' 
              name="twindicators"
              value={formData.twindicators}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
            
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Mudguard"
              size='small' 
              name="twmudguard"
              value={formData.twmudguard}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
             
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Wheel Rim"
              size='small' 
              name="twwheelRim"
              value={formData.twwheelRim}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
            
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Leg Guard"
              size='small' 
              name="twlegGuard"
              value={formData.twlegGuard}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
            
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Chassis"
             size='small' 
              name="twchassis"
              value={formData.twchassis}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Engine"
             size='small' 
              name="twengine"
              value={formData.twengine}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="LH Cowl"
             size='small' 
              name="twlhCowl"
              value={formData.twlhCowl}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="RH Cowl"
             size='small' 
              name="twrhCowl"
              value={formData.twrhCowl}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Tail Light"
             size='small' 
              name="twtailLight"
              value={formData.twtailLight}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Rear Shocker"
             size='small' 
              name="twrearShocker"
              value={formData.twrearShocker}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12}>
            <TextField
              label="Rear mudguard"
             size='small' 
              name="twrearMudguard"
              value={formData.twrearMudguard}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12}>
            <TextField
              label="Remarks"
             size='small' 
              name="twremarks"
              value={formData.twremarks}
              onChange={(e:any)=>validationHandler(e,"")}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
            </Paper>
          </Grid>
       {}
      {}
     {}
    {}
  </Grid>
 </form>
</Container>
  );
};

export default Twowheelerform;


