import { updateOwnerData } from "../Actions/CommonAction";

export const OwnerInitialState = {
  ownerName: '',
  mobileNumber: '',
  email: '',
  aadharNumber: '',
  jobType: '',
  inspectionAgency: '',
  insuranceRepository: '',
  executiveAssign: '',
  salesChannelName: '',
  businessSector: '',
  salesChannel: '',
  // stabledAnimalAddress: '',
  village: '',
  city: '',
  district: '',
  state: 'Maharashtra',
  pinCode: '',
  fieldExecutiveEmail: '',
  fieldExecutive: ''
}

const ownerDataReducer = (state = OwnerInitialState, action: any) => {

  switch (action.type) {
    case "UPDATE_OWNER_DATA":
      return {
        ...state,
        ownerName: action.payload.ownerName,
        mobileNumber: action.payload.mobileNumber,
        email: action.payload.email,
        aadharNumber: action.payload.aadharNumber,
        jobType: action.payload.jobType,
        inspectionAgency: action.payload.inspectionAgency,
        insuranceRepository: action.payload.insuranceRepository,
        fieldExecutive: action.payload.fieldExecutive,
        fieldExecutiveEmail: action.payload.fieldExecutiveEmail,
        salesChannelName: action.payload.salesChannelName,
        businessSector: action.payload.businessSector,
        salesChannel: action.payload.salesChannel,
        proposingOrganisation: action.payload.proposingOrganisation,
        // stabledAnimalAddress: action.payload.stabledAnimalAddress,
        village: action.payload.village,
        city: action.payload.city,
        district: action.payload.district,
        state: "Maharashtra",
        pinCode: action.payload.pinCode, // Update the owner data with the payload
      };
    case "RESET_OWNER_DATA":
      return { ...OwnerInitialState };

    default:
      return state;
  }
};

export default ownerDataReducer;
