import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';
import useValidation from '../../../Validation/useValidation';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

const Fourwheelerform = () => {
  const getAutomobileFormStateData = useSelector((state: any) => state.FourWheelerReducer);
  

  const [formData, setFormData] = useState({...getAutomobileFormStateData});
  const {eventHandler}= useValidation()
  const [error, setError] = useState({
    fwfrontBumper: '',
    fwjobType: '',
    fwheadLight: '',
    fwfrontGrill: '',
    fwbonet: '',
    fwfender: '',
    fwrhFrontDoor: '',
    fwrhRearDoor: '',
    fwrhQuarterPanel: '',
    fwlhFrontDoor: '',
    fwlhRearDoor: '',
    fwlhQuarterPanel: '',
    fwdickyDoor: '',
    fwrearBumper: '',
    fwrearGlass: '',
    fwlhTailLight: '',
    fwrhTailLight: '',
    fwengine: '',
    fwdashboard: '',
    fwunderCarraige: '',
    fwtyre4: '',
    fwremarks: '',
    });

    const [companies, setCompanies] = useState([]);
    const fetchCompanies = (selectedChannel:any) => {
     if (selectedChannel === 'Insurance') {
      
      } else if (selectedChannel === 'Bank') {
      
      } else {
        setCompanies([]);
      }
    };
   
    const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const dispatchFourWheeler = useDispatch();

  useEffect(() => {
    if (formData) {
      dispatchFourWheeler({ type: "UPDATE_FOURWHEELER_DATA", payload: formData });
    }
  }, [formData]);

  const handleSubmit = (e:any) => {
    e.preventDefault();
  };

  const validationHandler = async (e:any, alterName:any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
         {}
        <Grid item xs={12} >
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
             <Typography variant="h6" gutterBottom>
                Four Wheeler
              </Typography>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
            <TextField
              label="Front Bumper"
              size='small' 
              name="fwfrontBumper"
              value={formData.fwfrontBumper}
              onChange={handleChange}
              fullWidth
              // required
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="Head Light RH/LH"
              size='small' 
              name="fwheadLight"
              value={formData.fwheadLight}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="Front Grill"
              size='small' 
              name="fwfrontGrill"
              value={formData.fwfrontGrill}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
              label="Bonet"
              size='small' 
              name="fwbonet"
              value={formData.fwbonet}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
              label="RH/LH fender"
              size='small' 
              name="fwfender"
              value={formData.fwfender}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
              label="RH Front Door"
              size='small' 
              name="fwrhFrontDoor"
              value={formData.fwrhFrontDoor}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="RH Rear Door"
              size='small' 
              name="fwrhRearDoor"
              value={formData.fwrhRearDoor}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label="RH Quarter Panel"
              size='small' 
              name="fwrhQuarterPanel"
              value={formData.fwrhQuarterPanel}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="LH Front Door"
             size='small' 
              name="fwlhFrontDoor"
              value={formData.fwlhFrontDoor}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="LH Rear Door"
             size='small' 
              name="fwlhRearDoor"
              value={formData.fwlhRearDoor}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="LH Quarter Panel"
             size='small' 
              name="fwlhQuarterPanel"
              value={formData.fwlhQuarterPanel}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Dickey"
             size='small' 
              name="fwdickyDoor"
              value={formData.fwdickyDoor}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Rear Bumper"
             size='small' 
              name="fwrearBumper"
              value={formData.fwrearBumper}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Rear Glass"
             size='small' 
              name="fwrearGlass"
              value={formData.fwrearGlass}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="LH Tail Light"
             size='small' 
              name="fwlhTailLight"
              value={formData.fwlhTailLight}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="RH Tail Light"
             size='small' 
              name="fwrhTailLight"
              value={formData.fwrhTailLight}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Engine"
             size='small' 
              name="fwengine"
              value={formData.fwengine}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="dashboard"
             size='small' 
              name="fwdashboard"
              value={formData.fwdashboard}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Under Carraige"
             size='small' 
              name="fwunderCarraige"
              value={formData.fwunderCarraige}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="Tyre 4"
             size='small' 
              name="fwtyre4"
              value={formData.fwtyre4}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        <Grid item xs={12}>
            <TextField
              label="remarks"
             size='small' 
              name="fwremarks"
              value={formData.fwremarks}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
            </Grid>
             </Paper>
               </Grid>
          {}
          {}
          {}
          {}
       </Grid>
        </form>
         </Container>
       );
       };

export default Fourwheelerform;