import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  ImageList,
  ImageListItem,
  Button,
  List,
  ListItem,
} from "@mui/material";
import AboutusImg1 from "../../Assets/Images/About_1.jpg";
import AboutusImg2 from "../../Assets/Images/About_2.jpg";
import HeaderComponent from "../CommonComponents/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent";

const About_Content = () => {
  return (
    <>
      <HeaderComponent />

      <Container
        className="Information"
        maxWidth="lg"
        // style={{ paddingTop: "20px" }}
        sx={{ py: "100px" }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography
                  sx={{
                    textAlign: "start",
                    fontWeight: "500",

                    mt: 5,
                  }}
                  variant="h4"
                  component="h2"
                >
                  Why Choose US
                </Typography>
              </Box>
              <Typography
                sx={{ mt: 2, alignItems: "center", textAlign: "justify" }}
              >
                We believe in having Business ethics of highest standards,
                focused in meeting delivery standards with Utmost sincerity and
                meeting clients expectations always. We have a young and dynamic
                Team comprising of people having a background of Engineering,
                Management and Financial sector. Above all we have a drive and
                passion which makes us most different, as we are willing to go
                that extra mile to achieve our targets. Finguard Technologies
                along with its associated risk management companies enjoys very
                strong professional ties with its clients customers and other
                relevant stakeholders associated with us .Within a short span of
                time we have been able to forge valuable alliances with our
                clients. Some of the factors that makes us different from others
                are:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImageList
                className="AboutusImg1"
                cols={1}
                style={{ overflow: "hidden" }}
              >
                <ImageListItem
                  className="zoom-image"
                  style={{ overflow: "hidden" }}
                >
                  <img src={AboutusImg1} alt="About_information" />
                </ImageListItem>
              </ImageList>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ImageList
                  className="AboutusImg2"
                  cols={1}
                  style={{ overflow: "hidden" }}
                >
                  <ImageListItem
                    className="zoom-image"
                    style={{ overflow: "hidden" }}
                  >
                    <img src={AboutusImg2} alt="About_information" />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    textAlign: { xs: "center", sm: "start" },
                    fontWeight: "500",
                    mt: 2,
                  }}
                  variant="h4"
                >
                  Experience
                </Typography>
                <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
                  Practicing Surveyors and loss assessors who have over 25 years
                  experience in working with PSUs and Private Insurance
                  companies Approved surveyors for extended warranty claims for
                  Mrs Maruti Udyog Itd General Motors ltd
                </Typography>

                <List
                  sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                    spacing: 3,
                  }}
                  className="listFont"
                >
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Panel surveyors for Maruti dealers in Pune.
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Conducted risk inspection for various industrial units in
                    vicinity of Pune.
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Motor valuer for various banks like Canara Bank, IDBI, CBI,
                    SBI, Karur Vyasa Bank ltd, District Central Co-op. Bank of
                    Pune, Ahmednagar, Kolhapur, Satara, Sangali, Solapur,
                    Nashik, Aurangabad, Jalna, Nanded, Parbhani, Hingoli, The
                    Seva Vikas Bank Itd.
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Panel of Ex DGMs and RMs who are in our panel on
                    consultation basis.
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Panel of Retired ACP and Police Officers in our panel on
                    consultation basis. Quality Standards: High performance
                    standards subject to continuous refinement which can be
                    measured in terms of client satisfaction. We strive to ease
                    your mind and exceed your expectations.
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: { xs: "center", sm: "start" },
                    fontWeight: "500",
                    mt: 2,
                  }}
                >
                  Executive Team
                </Typography>
                {/* Add your team member details here */}
                <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
                  <span style={{ fontWeight: "bold", fontSize: "18px",textDecoration: "underline"}}>
                  Rupesh Ramesh Waghmare:
                  </span>
                  
                  <br /> Qualified Automobile engineer with Post Graduation in
                  Finance Management holds licence to act as Surveyor and Loss
                  Assessor for Motor by IRDAI, Fellow Member with IIISLA and
                  have conducted more than 50,000 service till date have worked
                  with organizations like Auto Risk Management Services, Secure
                  Auto Services, Triple A Risk Management Services and MCL.
                  Having handled their Pre Inspection, Valuation and Motor Own
                  Damage and Third Party Investigation with the help of
                  professional background next player off. Empanelled with all
                  PSU and other Private insurers and has handled multiple Motor
                  OD, TP and Theft cases and Rural claims with result oriented
                  approach.
                </Typography>
                <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px",textDecoration: "underline" }}>
                Advocate Sunita Kale:
                  </span>
                   <br />
                  Having experience of more than a decade as a leading lawyer in
                  Pune, with specialization in OD, TP and Theft claims.
                </Typography>
                <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px",textDecoration: "underline" }}>
                Adv. Monohar Firange:
                  </span>
                  
                  <br /> PSU Retired Chief Manager having experience of more
                  than 3 decades as a Expert in OD, TP and Theft claims. He also
                  has vast experience of handling Legal cases whilst working as
                  legal officer in a PSU. He has post retirement taken up
                  started his own Legal firm in Pune.
                </Typography>
                <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px",textDecoration: "underline" }}>
                Dr. Sunil Gaurkhede:
                  </span>
                  
                  <br /> PSU Retired Regional Manager having experience of more
                  than 3 decades in Rural, Crop Insurance, Motor OD, TP and
                  Theft claims. He is also a leading Veterinary Practitioner in
                  Pune.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ textAlign: "center", mt: 7 }}>
                  The company is fully functional, having well-equipped in-house
                  departments like:
                </Typography>
                <List
                  sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  <List
                    sx={{
                      listStyleType: "disc",
                      listStylePosition: "inside",
                    }}
                    className="listFont"
                  >
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Accounts{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Finance{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Marketing{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Sales{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Information Technology{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Legal{" "}
                    </ListItem>
                  </List>
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    textAlign: "start",
                    fontWeight: "500",

                    mt: 7,
                    ml: 3,
                  }}
                  variant="h4"
                >
                  Our Mission & Vision
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  component="img"
                  sx={{ width: "100%", height: "auto" }}
                  src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image4.png?updatedAt=1680074186489"
                ></Box>
                <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
                  Vision: When you consider Finguard Technologies Pvt. Ltd.,
                  picture a business that wants to make people's lives safer and
                  more secure.
                  {/* <Typography
                    sx={{ alignItems: "center", textAlign: "justify" }}
                  >
                    Mission: To uphold all the promises we make to you, our
                    clients, by offering the most convenient dealing process,
                    cultivating sustainable principles, and empowering all of
                    our workers. We aim to become the most admired and trusted
                    insurance company in India.
                  </Typography> */}
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontWeight: "500",

                      mt: 5,
                    }}
                    variant="h5"
                  >
                    We Stand For
                  </Typography>
                  <List
                    sx={{
                      listStyleType: "disc",
                      listStylePosition: "inside",
                    }}
                    className="listFont"
                  >
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Building relationships based on trust with all of our
                      stakeholders{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Treating everyone properly while exhibiting dignity and
                      respect{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Passion - We radiate good vibes and are enthusiastic about
                      what we do.{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Agility - We recognize opportunities and act quickly.{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Commitment - We are dedicated to achieving our goals and
                      keeping our word.{" "}
                    </ListItem>
                  </List>
                </Typography>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={3}>
                <Box
                  component="img"
                  sx={{ width: "100%", height: "auto" }}
                  src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image1.png?updatedAt=1680072948562"
                  className="image1"
                ></Box>
                <Typography
                  sx={{
                    textAlign: "start",
                    fontWeight: "500",

                    mt: 5,
                  }}
                  variant="h5"
                >
                  We support
                </Typography>
                <Typography
                  sx={{
                    alignItems: "center",
                    textAlign: "justify",
                    fontSize: 14,
                  }}
                >
                  Investigating the intrinsic learning motivations of pupils
                  with various attitudes towards distance learning is the
                  study's goal. The following are the study's primary goals:
                  <List
                    sx={{
                      listStyleType: "disc",
                      listStylePosition: "inside",
                    }}
                    className="listFont"
                  >
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      We value long-term customer relationships, honesty in all
                      dealings, fairness, and assistance for our customers
                      throughout their journeys. {" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      We also value long-term partner partnerships and helping
                      our partners develop with us. {" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    >
                      Offering all of our employees rewarding and challenging
                      jobs via personal development and empowerment.{" "}
                    </ListItem>
                  </List>
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  component="img"
                  sx={{ width: "592px", height: "328px" }}
                  src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image2.png?updatedAt=1680073351688"
                  className="image1"
                ></Box>
                <Typography
                  sx={{
                    textAlign: "start",
                    fontWeight: "500",

                    mt: 5,
                  }}
                  variant="h5"
                >
                  Network and Presence:
                </Typography>
                <Typography
                  sx={{
                    alignItems: "center",
                    textAlign: "justify",
                    fontSize: 14,
                  }}
                >
                  We are having registered office at Pune and representative
                  Offices all over the state of Maharashtra majorly situated at
                </Typography>
                <List
                  sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                  className="listFont"
                >
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Pune{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Mumbai{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Thane{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Nagpur{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Nashik{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Aurangabad{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Nanded{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Ahmednagar{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Dhule{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Satara{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Kolhapur{" "}
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    Ratnagiri{" "}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <br />
      <FooterComponent />
    </>
  );
};

export default About_Content;
