import { updateOwnerData } from "../Actions/CommonAction";

export const FourWheelerInitialState = {
    fwfrontBumper: '',
    fwsalesChannel: '',
    fwheadLight: '',
    fwfrontGrill: '',
    fwbonet: '',
    fwfender: '',
    fwrhFrontDoor: '',
    fwrhRearDoor: '',
    fwrhQuarterPanel: '',
    fwlhFrontDoor: '',
    fwlhRearDoor: '',
    fwlhQuarterPanel: '',
    fwdickyDoor: '',
    fwrearBumper: '',
    fwrearGlass: '',
    fwlhTailLight: '',
    fwrhTailLight: '',
    fwengine: '',
    fwdashboard: '',
    fwunderCarraige: '',
    fwtyre4: '',
    fwremarks: '',
    fwcompany: '',
};

const FourWeelerReducer = (state = FourWheelerInitialState, action: any) => {
  switch (action.type) {
    case "UPDATE_FOURWHEELER_DATA":
      return {
        ...state,
        fwfrontBumper: action.payload.fwfrontBumper,
        fwsalesChannel: action.payload.fwsalesChannel,
        fwheadLight: action.payload.fwheadLight,
        fwfrontGrill: action.payload.fwfrontGrill,
        fwbonet: action.payload.fwbonet,
        fwfender: action.payload.fwfender,
        fwrhFrontDoor: action.payload.fwrhFrontDoor,
        fwrhRearDoor: action.payload.fwrhRearDoor,
        fwrhQuarterPanel: action.payload.fwrhQuarterPanel,
        fwlhFrontDoor: action.payload.fwlhFrontDoor,
        fwlhRearDoor: action.payload.fwlhRearDoor,
        fwlhQuarterPanel: action.payload.fwlhQuarterPanel,
        fwdickyDoor: action.payload.fwdickyDoor,
        fwrearBumper: action.payload.fwrearBumper,
        fwrearGlass: action.payload.fwrearGlass,
        fwlhTailLight: action.payload.fwlhTailLight,
        fwrhTailLight: action.payload.fwrhTailLight,
        fwengine: action.payload.fwengine,
        fwdashboard: action.payload.fwdashboard,
        fwunderCarraige: action.payload.fwunderCarraige,
        fwremarks: action.payload.fwremarks,
        fwcompany: action.payload.fwcompany,
        fwtyre4: action.payload.fwtyre4,

      };
    case "CLEAR_FOURWHEELER_DATA": return { ...FourWheelerInitialState };
    default:
      return state;
  }
};

export default FourWeelerReducer;
