import { CenterFocusStrong } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import QRCode from 'qrcode.react'
import React from 'react'


function Qrcodeview1() {

  return (
    <>
      <Stack direction={"column"}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold", justifyContent: "center", display: "flex" }}>
          Cattle App
        </Typography>
        <Typography sx={{ m: "40px" }}>
          <QRCode value={"https://test-techmudra.s3.ap-southeast-1.amazonaws.com/MobileBuild/cattle/app-release.apk"} size={340} />
        </Typography>
        <Typography sx={{ justifyContent: "center", display: "flex", fontSize: "20px" }}>
          Please scan the QR code to Download Mobile App
        </Typography>
      </Stack>
      <Stack direction={"column"}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold", justifyContent: "center", display: "flex" }}>
          Automobile App
        </Typography>
        <Typography sx={{ m: "40px" }}>
          <QRCode value={"https://test-techmudra.s3.ap-southeast-1.amazonaws.com/MobileBuild/automobile/app-release.apk"} size={340} />
        </Typography>
        <Typography sx={{ justifyContent: "center", display: "flex", fontSize: "20px" }}>
          Please scan the QR code to Download Mobile App
        </Typography>
      </Stack>
    </>

  )
}

export default Qrcodeview1
