import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import rfd from "../../Assets/Images/rfd.jpg";
import accurate from "../../Assets/Images/accurate.jpg";
import healthCattle from "../../Assets/Images/healthCattle.jpg";
import transprance from "../../Assets/Images/transprance.jpg";
import { useNavigate } from "react-router-dom";

interface CardData {
  title: string;
  image: string;
  content: string;
  service: string;
}

const cards: CardData[] = [
  {
    title: "Card 1",
    image: accurate,
    content: "",
    service: "Accurate and Real-time Valuation",
  },
  {
    title: "Card 2",
    image: transprance,
    content:
      "We provide a detailed breakdown of how we calculate the valuation, giving you confidence in the accuracy of our results.",
    service: "Transparency",
  },
  {
    title: "Card 4",
    image: healthCattle,
    content:
      "Track the health and behavior of your cattle, helping you identify issues early.",
    service: "Health Monitoring",
  },
];

const defaultTheme = createTheme();

export default function Service() {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/ServiceContent");
    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <main>
        <Container maxWidth="lg">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Our Services
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Our Automobiles Insurance coverage 
            offers peace of mind for all your vehicles, from personal 
            cars to commercial fleets. For cattle owners, our Cattle
             Insurance is designed to protect your livestock and livelihood.
              We offer coverage that covers a range of risks, including accidents,
               illnesses, and natural disasters
          </Typography>

          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.title} xs={12} sm={6} md={3}>
                <Card className="service_card">
                  <CardMedia
                    component="div"
                    className="service_card_media"
                    image={card.image}
                  />
                  <CardContent className="service_card_content">
                    <Typography gutterBottom variant="h5" component="h2" className="Title">
                      {card.service}
                    </Typography>
                    <Typography>{card.content}</Typography>
                  </CardContent>
                  <CardActions className="service_card_action">
                    <Button className="service_card_button">
                      Read More
                      <DoubleArrowIcon />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Stack
            className="service_stack"
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              className="service_View"
              variant="contained"
              color="secondary"
              onClick={handleViewMoreClick}
            >
              View More
            </Button>
          </Stack>
        </Container>
      </main>
    </ThemeProvider>
  );
}
