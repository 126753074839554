
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { FormControl, InputLabel, Paper, Typography } from "@mui/material";
import '../../CommonComponents/styles.css';
import data from "./location.json";
import useValidation from "../../../Validation/useValidation";
import { useDispatch, useSelector } from "react-redux";
import { updateOwnerData } from "../../../Redux/Actions/CommonAction";
import { getRequest } from "../../../Api/ApiEndpointconfiguration";
import { GET_EXECUTIVE_LIST } from "../../../Api/ServerConfiguration";
import { useLocation } from "react-router-dom";
const OwnerDetail = () => {
  const { eventHandler } = useValidation();
  const dispatchOwner = useDispatch();
  const getFormStateData = useSelector((state: any) => state.OwnerReducer);
  const statusof = useSelector((state: any) => state);
  const [formData, setFormData] = useState<any>({ ...getFormStateData });
  const [executive, setexecutive] = useState<any>([]);
  const [insuranceCompany, setInsuranceCompany] = useState<any>([]);
  const location = useLocation();
  const [error, setError] = useState({
    fieldExecutive: "",
    inspectionAgency: "",
    jobType: "",
    ownerName: "",
    mobileNumber: "",
    email: "",
    businessSector: "",
    proposingOrganisation: "",
    salesChannelName: "",
    aadharNumber: "",
    village: "",
    city: "",
    district: "",
    state: "Maharashtra",
    pinCode: "",
  });

  const getExecutiveList = () => {
    getRequest(GET_EXECUTIVE_LIST, "")
      .then((res: any) => {
        setexecutive(res.data.executiveList);
        setInsuranceCompany(res.data.companyList)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getExecutiveList();
  }, []);

  useEffect(() => {
    if (formData) {
      dispatchOwner({ type: "UPDATE_OWNER_DATA", payload: formData });
    }
  }, [formData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFieldExecutive = (e: any) => {
    const filterExe = e.target.value ? executive.filter((exe: any) => exe.fullname === e.target.value) : {};
    const { fieldExecutiveEmail, firstname, fullname, lastname } = filterExe[0];
    setFormData({
      ...formData,
      fieldExecutive: fullname,
      fieldExecutiveEmail: fieldExecutiveEmail
    });
  }
  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value;

    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // You can handle form submission here or send the data to an API
  };

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ border: "1px solid grey", p: 2, mb: 2 }}>
              <Typography component="span">Agency Details</Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    label="Field Executive"
                    size="small"
                    name="fieldExecutive"
                    select // Make it a select input
                    value={formData.fieldExecutive}
                    onChange={handleFieldExecutive}
                    fullWidth
                    required
                    margin="normal"
                  >
                    {/* Add your custom options here */}
                    {executive &&
                      executive.map((item: any) => {
                        return (
                          <MenuItem value={item.fullname}>
                            {item.fullname}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Insurance Company"
                    size="small"
                    name="insuranceRepository"
                    select // Make it a select input
                    value={formData.insuranceRepository}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    {/* Add your custom options here */}
                    {insuranceCompany && insuranceCompany.map((item: any) => {
                      return (
                        <MenuItem value={item.companyName} key={item.uniqueCId}>
                          {item.companyName}
                        </MenuItem>
                      );
                    })}
                    {/* <MenuItem value="THE NEW INDIA ASSURANCE COMPANY LIMITED">
                      THE NEW INDIA ASSURANCE COMPANY LIMITED
                    </MenuItem>
                    <MenuItem value="UNITED INDIA INSURANCE COMPANY LIMITED">
                      UNITED INDIA INSURANCE COMPANY LIMITED
                    </MenuItem>
                    <MenuItem value="THE ORIENTAL INSURANCE COMPANY LIMITED">
                      THE ORIENTAL INSURANCE COMPANY LIMITED
                    </MenuItem>
                    <MenuItem value="NATIONAL INSURANCE COMPANY LIMITED">
                      NATIONAL INSURANCE COMPANY LIMITED
                    </MenuItem>
                    <MenuItem value="SAHAKARI SAKHAR KARKHANA">
                      SAHAKARI SAKHAR KARKHANA
                    </MenuItem> */}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Inspection Agency"
                    size="small"
                    name="inspectionAgency"
                    select
                    required
                    value={formData.inspectionAgency}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="ARM">ARM</MenuItem>
                    <MenuItem value="Finguard">Finguard</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Type of Job"
                    size="small"
                    name="jobType"
                    select
                    value={formData.jobType}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="Tagging">Tagging</MenuItem>
                    <MenuItem value="Claims">Claims</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ border: "1px solid grey", p: 2, mb: 2 }}>
              <Typography component="span">Owner Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Cattle Owner"
                    size="small"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={(e: any) => {
                      validationHandler(e, "alphabetsAndSpace");
                      handleChange(e);
                    }}
                    error={Boolean(error.ownerName)}
                    helperText={error.ownerName}
                    fullWidth
                    required
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile Number"
                    size="small"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={(e: any) => {
                      validationHandler(e, "mobile");
                      handleChange(e);
                    }}
                    error={Boolean(error.mobileNumber)}
                    helperText={error.mobileNumber}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email ID"
                    size="small"
                    name="email"
                    value={formData.email}
                    onChange={(e: any) => {
                      validationHandler(e, "email");
                      handleChange(e);
                    }}
                    error={Boolean(error.email)}
                    helperText={error.email}
                    type="email"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Aadhar Number"
                    size="small"
                    name="aadharNumber"
                    value={formData.aadharNumber}
                    onChange={(e: any) => {
                      validationHandler(e, "aadhaar");
                      handleChange(e);
                    }}
                    error={Boolean(error.aadharNumber)}
                    helperText={error.aadharNumber}
                    fullWidth
                    required={location.state !== null}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="State"
                    size="small"
                    disabled={true}
                    name="state"
                    value={formData.state}
                    // onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="district">District</InputLabel>
                    <Select
                      label="District"
                      size="small"
                      name="district"
                      value={formData.district}
                      onChange={handleChange}
                      fullWidth
                      inputProps={{
                        id: "district",
                      }}
                    >
                      {data.map((district: any, index: any) => (
                        <MenuItem key={index} value={district.name}>
                          {district.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="city">City</InputLabel>
                    <Select
                      label="City"
                      size="small"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      fullWidth
                      inputProps={{
                        id: "city",
                      }}
                    >
                      {data
                        .filter((city) => city.name === formData.district) // Filter data based on selected city
                        .map((city) =>
                          city.tahasil.map((tahasil, index) => (
                            <MenuItem key={index} value={tahasil}>
                              {tahasil}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Village"
                    size="small"
                    name="village"
                    value={formData.village}
                    // onChange={handleChange}
                    onChange={(e: any) => {
                      validationHandler(e, "alphabetsAndSpace");
                      handleChange(e);
                    }}
                    error={Boolean(error.village)}
                    helperText={error.village}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Pin Code"
                    size="small"
                    name="pinCode"
                    value={formData.pinCode}
                    // onChange={handleChange}
                    onChange={(e: any) => {
                      validationHandler(e, "zipcode");
                      handleChange(e);
                    }}
                    error={Boolean(error.pinCode)}
                    helperText={error.pinCode}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ border: "1px solid grey", p: 2, mb: 2 }}>
              <Typography component="span">Sales Channel</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Business Sector"
                    size="small"
                    name="businessSector"
                    select
                    value={formData.businessSector}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="Rural">Rural</MenuItem>
                    <MenuItem value="Urban">Urban</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Proposing Organisation"
                    size="small"
                    name="proposingOrganisation"
                    select
                    value={formData.proposingOrganisation}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="Individual">Individual</MenuItem>
                    <MenuItem value="Dairy">Dairy</MenuItem>
                    <MenuItem value="Sugar Factory">Sugar Factory</MenuItem>
                    <MenuItem value="Co-Operative Soc">Co-Operative Soc</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={`Sales Channel Name`}
                    size="small"
                    name="salesChannelName"
                    // value={formData.salesChannelName}
                    // onChange={handleChange}
                    value={formData.salesChannelName}
                    // onChange={handleChange}
                    onChange={(e: any) => {
                      validationHandler(e, "alphabetsAndSpace");
                      handleChange(e);
                    }}
                    error={Boolean(error.salesChannelName)}
                    helperText={error.salesChannelName}
                    fullWidth
                    margin="normal"
                  ></TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OwnerDetail;
