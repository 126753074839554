import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { ImageListItem, Stack } from "@mui/material";
import "../CommonComponents/styles.css";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../../Assets/Images/Logo.png";
import "../CommonComponents/styles.css";
import { useNavigate } from "react-router-dom";



function HeaderComponent(params: any) {

  const navigate = useNavigate();
  interface Page {
    name: string;
    active?: boolean;
  }

  const pages: Page[] = [
    { name: "Home", active: true },
    { name: "Service" },
    { name: "About" },
    { name: "Contact" },
    { name: "Career" },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    navigate("/");
    window.scrollTo(0, 0);
  };

  const ViewMore = ()=>{
    navigate("/");
    window.scrollTo(0, 0);
  }
  

 
  return (
    <>
      <AppBar position="fixed" className="appbar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              className="appbar_typograpghy"
            >
              <ImageListItem className="logo">
                <img src={Logo} loading="lazy" alt="" />
              </ImageListItem>
            </Typography>

            <Box display={{ xs: "flex", md: "none" }} className="appbar_box1">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{color:"black"}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page: any) => (
                  <ScrollLink key={page.name} to={page.name} smooth={true}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  </ScrollLink>
                ))}
              </Menu>
            </Box>

            <Box
              display={{ xs: "none", md: "flex" }}
              className="appbar_box2"
              style={{ justifyContent: "flex-end" }}
            >
              {pages.map((page) => (
                <ScrollLink key={page.name} to={page.name} smooth={true}>
                  <Button
                    onClick={handleCloseNavMenu}
                    className={`appbar_box2_button ${
                      page.active ? "active-button" : ""
                    }`}
                    variant="contained"
                    color="secondary"
                    style={{ margin: "0 7px" }}
                  >
                    {page.name}
                  </Button>
                </ScrollLink>
              ))}
            </Box>

            <Box className="appbar_box3">
              <ScrollLink to="Login" smooth={true}>
                <Tooltip title="Login">
                  <Button variant="contained" className="appbar_box3_button" onClick={ViewMore}>
                    Login
                  </Button>
                </Tooltip>
              </ScrollLink>
            </Box>
            <Box className="appbar_box3" sx={{m:"10px"}}>
              
                <Tooltip title="QR Reader">
                <Stack direction={"column"} spacing={2}>
    <ScrollLink to="Qrview" smooth={true}>
                <Tooltip title="Login">
      <Button  className="appbar_box3_button" sx={{color:"white"}}>Mobile App</Button>
      </Tooltip>
              </ScrollLink>
      </Stack>
         
                </Tooltip>
              
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default HeaderComponent;
