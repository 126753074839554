import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useValidation from "../../Validation/useValidation";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import { postrequestMethod, updateRequest } from "../../../Api/ApiMethods";
import {
  POST_DRREGISTRATION,
  PUT_DRREGISTRATION,
} from "../../../Api/ServerConfiguration";
import CommonSnackBar from "../../CommonComponents/CommonSnackbar";

function DrRegistrationForm() {
  const { eventHandler } = useValidation();
  const navigate = useNavigate();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };
  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };
  const [data, setData] = useState({
    VeterinaryDoctorServices: location.state
      ? location.state.VeterinaryDoctorServices
      : "",
    Qualification: location.state ? location.state.Qualification : "",
    registrationNumber: location.state ? location.state.registrationNumber : "",
    mobileNumber: location.state ? location.state.mobileNumber : "",
    doctorAddress: location.state ? location.state.doctorAddress : "",
    // VDID: location.state
    //   ? location.state.VDID
    //   : "",
    districtName: location.state ? location.state.districtName : "",
    emailAddress: location.state ? location.state.emailAddress : "",
  });

  const VDID = location.state;
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    VeterinaryDoctorServices: "",
    Qualification: "",
    registrationNumber: "",
    mobileNumber: "",
    doctorAddress: "",
    // VDID: "",
    districtName: "",
    emailAddress: "",
  });

  const validationHandler = async (e: any, alterName?: any) => {
    let fileExtension: any = null;
    if (alterName === "Pdf") {
      const selectedFile = e.target.files[0];
      const fileName = selectedFile?.name;
      fileExtension = fileName.split(".").pop();
      setData({ ...data, [e.target.name]: selectedFile });
    } else {
      fileExtension = e.target.value;
      setData({ ...data, [e.target.name]: fileExtension });
    }
    if (alterName) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(alterName, fileExtension));
        }
      });
      prom.then((res: any) => {
        if (res === "") {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: "",
          }));
        } else {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: res,
          }));
        }
      });
    }
  };

 
    const validator = () => {
    for (let field in errors) {
      if (errors[field as keyof typeof errors] !== "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validator()) {
      try {
        setLoading(true);
        if (location.state === null) {
          const response = await postrequestMethod(
            POST_DRREGISTRATION,
            "",
            data
          );
          if (response) {
            handleOpenSnackBar("Dr Registration added successfully!");
            window.scrollTo(0, 0);
            setTimeout(() => {
              navigate("/dashboard/Veterinary/AddDrRegistrationList");
            }, 3000);
          }
        } else {
          let VDID = location.state?.VDID;
          console.log("data", data);
          await updateRequest(PUT_DRREGISTRATION, VDID, data, "");
          handleOpenSnackBar(" Dr Registration updated successfully!");
          setTimeout(() => {
            navigate("/dashboard/Veterinary/AddDrRegistrationList");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting job:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Container>
        <Paper elevation={20} sx={{ p: 3 }}>
        {isLoading && <LinearProgress />}  
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ alignItems: "center", color: "#0288d1" }}
            >
              Dr.Registration Form
            </Typography>
            <Box
              sx={{ mt: 3 }}
              component="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <TextField
                      sx={{ background: "white" }}
                      fullWidth
                      required
                      id="VeterinaryDoctorServices"
                      label="Veterinary Doctor Services"
                      name="VeterinaryDoctorServices"
                      type="text"
                      value={data.VeterinaryDoctorServices}
                      error={Boolean(errors.VeterinaryDoctorServices)}
                      helperText={errors.VeterinaryDoctorServices}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        validationHandler(e, "alphabets")
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="qualification"
                    label="Qualification"
                    name="Qualification"
                    type="text"
                    value={data.Qualification}
                    error={Boolean(errors.Qualification)}
                    helperText={errors.Qualification}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphabet")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="registrationNumber"
                    label="Registration Number"
                    name="registrationNumber"
                    value={data.registrationNumber}
                    // type="number"
                    error={Boolean(errors.registrationNumber)}
                    helperText={errors.registrationNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphanumericAndSpace")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    // required
                    id="mobileNumber"
                    label="Mobile Number"
                    name="mobileNumber"
                    value={data.mobileNumber}
                    type="number"
                    error={Boolean(errors.mobileNumber)}
                    helperText={errors.mobileNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "number")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    // required
                    id="doctorAddress"
                    label="Doctor Address"
                    name="doctorAddress"
                    value={data.doctorAddress}
                    type="text"
                    error={Boolean(errors.doctorAddress)}
                    helperText={errors.doctorAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphabetsCommaSpace")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    id="districtName"
                    label="District Name"
                    name="districtName"
                    value={data.districtName}
                    type="text"
                    error={Boolean(errors.districtName)}
                    helperText={errors.districtName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphabet")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    id="emailAddress"
                    label="Email Address"
                    name="emailAddress"
                    value={data.emailAddress}
                    type="text"
                    error={Boolean(errors.emailAddress)}
                    helperText={errors.emailAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "email")
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                  <CommonSnackBar message={snackMessage} open={openSnackBar} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default DrRegistrationForm;
function validator() {
  throw new Error("Function not implemented.");
}

function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function handleOpenSnackBar(arg0: string) {
  throw new Error("Function not implemented.");
}

function setOpenSnackBar(arg0: boolean) {
  throw new Error("Function not implemented.");
}
// function setOpenSnackBar(arg0: boolean) {
//   throw new Error("Function not implemented.");
// }

// function setOpenSnackBar(arg0: boolean) {
//   throw new Error("Function not implemented.");
// }
