import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import { Paper } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useValidation from "../../Validation/useValidation";
import LinearProgress from "@mui/material/LinearProgress";
import {
  POST_EXECUTIVE,
  PUT_EXECUTIVE,
} from "../../../Api/ServerConfiguration";
import { postrequestMethod, updateRequest } from "../../../Api/ApiMethods";
import CommonSnackBar from "../../CommonComponents/CommonSnackbar";

function AddExecutive() {
  const location = useLocation();
  const navigate = useNavigate();
  const { eventHandler } = useValidation();

  //for progress bar
  const [isLoading, setLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  const [data, setData] = useState({
    firstname: location.state ? location.state.firstname : "",
    lastname: location.state ? location.state.lastname : "",
    phonenumber: location.state ? location.state.phonenumber : "",
    emailAddress: location.state ? location.state.emailAddress : "",
    executiveAddress: location.state ? location.state.executiveAddress : "",
    executiveDistrict: location.state ? location.state.executiveDistrict : "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    firstname: "",
    lastname: "",
    phonenumber: "",
    emailAddress: "",
    //  fullname: "",
    //   fieldExecutiveEmail: "",
    executiveAddress: "",
    executiveDistrict: "",
  });


  const validationHandler = async (e: any, alterName?: any) => {
    let fileExtension: any = null;
    if (alterName === "Pdf") {
      const selectedFile = e.target.files[0];
      const fileName = selectedFile?.name;
      fileExtension = fileName.split(".").pop();
      setData({ ...data, [e.target.name]: selectedFile });
    } else {
      fileExtension = e.target.value;
      setData({ ...data, [e.target.name]: fileExtension });
    }
    if (alterName) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(alterName, fileExtension));
        }
      });
      prom.then((res: any) => {
        if (res === "") {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: "",
          }));
        } else {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: res,
          }));
        }
      });
    }
  };

  
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validator()) {
      try {
        setLoading(true);
        if (location.state === null) {
          const response: any = await postrequestMethod(POST_EXECUTIVE, "", data);
          if (response.data.message) {
            if (response.status === 201) {
              handleOpenSnackBar(response.data.message);
              setTimeout(() => {
                setOpenSnackBar(false);
              }, 2000)
            } else {
              handleOpenSnackBar(response.data.message);
              setTimeout(() => {
                navigate("/dashboard/Executive/Create");
              }, 2000)
            }
          }
        } else {
          let executive_id = location.state?.executive_id;
          await updateRequest(PUT_EXECUTIVE, executive_id, data, "");
          handleOpenSnackBar("Executive updated successfully!");
          setTimeout(() => {
            navigate("/dashboard/Executive/Create");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting job:", error);
        if (error) {
          handleOpenSnackBar("An error occurred while submitting the form");
        } else {
          handleOpenSnackBar("An unexpected error occurred while submitting the form");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const validator = () => {
    for (let field in errors) {
      if (errors[field as keyof typeof errors] !== "") {
        return false;
      }
    }
    return true;
  };
  return (
    <>
      <Container>
        <Paper elevation={20} sx={{ p: 3 }}>
          {isLoading && <LinearProgress />}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ alignItems: "center", color: "#0288d1" }}
            >
              ADD Executive
            </Typography>
            <Box
              sx={{ mt: 3 }}
              component="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <TextField
                      sx={{ background: "white" }}
                      fullWidth
                      required
                      id="firstName"
                      label="First Name"
                      name="firstname"
                      value={data.firstname}
                      type="text"
                      error={Boolean(errors.firstname)}
                      helperText={errors.firstname}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        validationHandler(e, "alphabet")
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastname"
                    value={data.lastname}
                    type="text"
                    error={Boolean(errors.lastname)}
                    helperText={errors.lastname}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphabet")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="phoneNumber"
                    label="Phone Number"
                    name="phonenumber"
                    value={data.phonenumber}
                    type="number"
                    error={Boolean(errors.phonenumber)}
                    helperText={errors.phonenumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "phone")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="emailAddress"
                    label="Email Address"
                    name="emailAddress"
                    value={data.emailAddress}
                    type="email"
                    error={Boolean(errors.emailAddress)}
                    helperText={errors.emailAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "email")
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="fieldExecutiveEmail"
                    label="Filed Executive Email"
                    name="fieldExecutiveEmail"
                    value={data.fieldExecutiveEmail}
                    type="email"
                    error={Boolean(errors.fieldExecutiveEmail)}
                    helperText={errors.fieldExecutiveEmail}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "email")
                    }
                  /> */}

                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    id="executiveAddress"
                    label="Executive Address"
                    name="executiveAddress"
                    value={data.executiveAddress}
                    type="text"
                    //  error={Boolean(errors.executiveAddress)}
                    //   helperText={errors.executiveAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    id="executiveDistrict"
                    label="Executive District"
                    name="executiveDistrict"
                    value={data.executiveDistrict}
                    type="text"
                    // error={Boolean(errors.executiveDistrict)}
                    //  helperText={errors.executiveDistrict}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e)
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>

                  <CommonSnackBar message={snackMessage} open={openSnackBar} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default AddExecutive;
