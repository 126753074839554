import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
 import TwitterIcon from "@mui/icons-material/Twitter";
//import FacebookIcon from '@mui/icons-material/Facebook';
import Stack from "@mui/material/Stack";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./styles.css";
import { Link as ScrollLink } from "react-scroll";
import { Grid, Link, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import "../CommonComponents/styles.css";

function FooterComponent(params: any) {
  interface Page {
    name: string;
    active?: boolean;
  }

  const pages: Page[] = [
    { name: "Home", active: true },
    { name: "Service" },
    { name: "About" },
    { name: "Contact" },
    { name: "Career" },
  ];

  return (
    <>
      <Box className="footer_box">
        <Container maxWidth="lg">
          <Grid container direction="row" mt={6}>
            <Grid item md={4} xs={12} textAlign={{ xs: "center", md: "start" }}>
              <Typography variant="h4" gutterBottom>
                Finguard Technologies
              </Typography>
              
              <Typography
                variant="h5"
                gutterBottom
                //textAlign={{ xs: "center", md: "start" }}
                sx={{marginTop:"110px"}}
              >
                Follow Us
              </Typography>

              <Stack
                direction="row"
                spacing={2}
                marginTop={"1rem"}
                justifyContent={{ xs: "center", md: "start" }}
              >
                <Link
                  href="https://www.facebook.com/p/Prerana-Academy-100063770839956/"
                  underline="none"
                  className="footer_link"
                >
                  <FacebookIcon/>
                </Link>
                <Link
                  href="https://www.facebook.com/p/Prerana-Academy-100063770839956/"
                  underline="none"
                  className="footer_link"
                >
                  <TwitterIcon />
                </Link>
                <Link
                  href="https://www.facebook.com/p/Prerana-Academy-100063770839956/"
                  underline="none"
                  className="footer_link"
                >
                  <LinkedInIcon />
                </Link>
                <Link
                  href="https://www.facebook.com/p/Prerana-Academy-100063770839956/"
                  underline="none"
                  className="footer_link"
                >
                  <InstagramIcon />
                </Link>
              </Stack>
            </Grid>

            <Grid
              item
              md={4}
              xs={12}
              marginBottom={"2rem"}
              textAlign={{ xs: "center" }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Quick Links
                </Typography>

                {pages.map((page) => (
                  <ScrollLink key={page.name} to={page.name} smooth={true}>
                    <Link underline="none">
                      <Typography className="footer_link">
                        {page.name}
                      </Typography>
                    </Link>
                  </ScrollLink>
                ))}
              </Stack>
            </Grid>

            <Grid item md={4} xs={12} paddingBottom="20px">
              <Stack
                direction="column"
                spacing={2}
                textAlign={{ xs: "center", md: "start" }}
              >
                <Typography variant="h6" gutterBottom>
                  Contact Details
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Finguard Technologies Pvt. Ltd.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Pune office: Office number 4, Buena Vista Plot Number 58 near
                  Kailash Supermarket, Viman Nagar, Pune-411014
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Mumbai office: Kalpana Nivas, Bungalow number 54, RSC 11-12,
                  near Niwara Housing Society, Veer Savarkar Nagar, Thane (West)
                </Typography>
                <Typography gutterBottom>
                  Phone: 9175012125 / 9822001462
                </Typography>
                <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                  <Typography gutterBottom>Email:</Typography>
                  <a href="mailto:ceo@finguardtech.com">ceo@finguardtech.com</a>
                  <Typography variant="caption" component="span">
                    ,
                  </Typography>
                  <a href="mailto:ceo@finguardtech.com">info@finguardtech.com </a>
                </Stack>
              </Stack>

             
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Typography>
              © 2023 All Rights Reserved By Finguard
            </Typography>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default FooterComponent;
