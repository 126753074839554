export const tableHeaders1 = [
    "FRONT SHOW",
    "Safe",
    "Accidental Remark",
    "",
    "Safe",
    "Accidental Remark",
];

export const tableHeaders2 = [
    "",
    "Safe",
    "Accidental Remark",
    "",
    "Safe",
    "Accidental Remark",
];

