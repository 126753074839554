import { legacy_createStore as createStore, combineReducers } from "@reduxjs/toolkit";
import LoginReducer from "../Reducer/LoginReducer";
import OwnerReducer from "../Reducer/OwnerReducer";
import cattleDataReducer from "../Reducer/CattleReducer";
import CattleReducer from "../Reducer/CattleReducer";
import AuthorisedReducer from "../Reducer/AuthorisedReducer";
import TokenReducer from "../Reducer/TokenReducer"
import OwnerAutoReducer from "../Reducer/OwnerAutoReducer";
import VehicleReducer from "../Reducer/VehicleReducer";
import TwoWheelerReducer from "../Reducer/TwoWheelerReducer";
import FourWheelerReducer from "../Reducer/FourWheelerReducer";
import CommercialReducer from "../Reducer/CommercialReducer";
import OthersReducer from "../Reducer/OthersReducer";
import AuthorisedAutoReducer from "../Reducer/AuthorisedAutoReducer";
import ImageReducer from "../Reducer/ImageReducer";

const allApplicationReducer = combineReducers({
    LoginReducer: LoginReducer,
    OwnerReducer: OwnerReducer,
    CattleReducer: CattleReducer,
    AuthorisedReducer: AuthorisedReducer,
    TokenReducer: TokenReducer,
    OwnerAutoReducer: OwnerAutoReducer,
    VehicleReducer: VehicleReducer,
    TwoWheelerReducer: TwoWheelerReducer,
    FourWheelerReducer: FourWheelerReducer,
    CommercialReducer: CommercialReducer,
    OthersReducer: OthersReducer,
    AuthorisedAutoReducer: AuthorisedAutoReducer,
    ImageReducer: ImageReducer,
});

const store = createStore(allApplicationReducer);

export default store;