import React, { useEffect, useState } from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { FARMER_PACKAGE_REPORT } from "../../../Api/ServerConfiguration";

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontFamily: "Times-Roman",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.7)'
  },
  boldText1: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.7)'
  },
  boldText2: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.7)'
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  col1: {
    width: "100%",
  },
  col2: {
    width: "40%",
    padding: 2,
  },
  col3: {
    width: "30%",
    padding: 2,
  },
  col4: {
    width: "10%",
    padding: 2,
  },
  col5: {
    width: "20%",
    padding: 2,
  },
  col6: {
    width: "25%",
    padding: 2,
  },
  col7: {
    width: "50%",
    padding: 2,
  },
  col8: {
    width: "25%",
    padding: 2,
  },
  col9: {
    width: "20%",
    padding: 2,
  },
  col10: {
    width: "30%",
    padding: 2,
  },
  col11: {
    width: "20%",
    padding: 2,
  },
  col12: {
    width: "30%",
    padding: 2,
  },
  col13: {
    width: "20%",
    padding: 2,
  },
  col14: {
    width: "30%",
    padding: 2,
  },
  col15: {
    width: "50%",
    padding: 2,
  },
  center: {
    alignItems: "center", // Center-align the content horizontally
    justifyContent: "center", // Center-align the content vertically
    textAlign: "center", // Center-align the text within the Text component
  },
  right: {
    alignItems: "flex-end", // Center-align the content horizontally
    justifyContent: "flex-end", // Center-align the content vertically
    textAlign: "right",
  },
  divider: {
    borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
    // marginBottom: 10, // Adjust margin to control spacing between sections
  },
});
export default function Report2() {
  const currentDate = new Date();
  const date = currentDate.toDateString();

  const [data, setData] = useState({
    certificateNo: "",
    insuranceFrom: "",
    insuranceTo: "",
    accNo: undefined,
    policyNo: undefined,
    niaCinNo: "",
    mobileNo: undefined,
    niaGstNo: "",
    gstINUIN: "",
    SAC: "",
  });

  const getData = async () => {
    try {
      const response: any = await axios.get(
        FARMER_PACKAGE_REPORT
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);




  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <PDFViewer style={{ width: "100%", height: "100%", color: "red" }}>

        <Document title="Farmer's Package Certificate">
          <Page size="A4" style={styles.page}>
            {/* <View style={{ ...styles.para1 }}>
          <View style={styles.col1}>
            <Text style={styles.text2}>THE NEW INDIA ASSURANCE CO. LTD. </Text>
            <Text style={styles.text2}>(Goverment of India Undertaking) </Text>
          </View>
        </View> */}
            <View>
              <Text style={{ ...styles.text2, ...styles.center, ...styles.boldText, marginTop: 5 }}>
                THE NEW INDIA ASSURANCE CO. LTD.
              </Text>
              {/* <Text style={[styles.text2, styles.center]}>
            REGISTERED & HEAD OFFICE: 87, MAHATMA GANDHI ROAD, MUMBAI 400001.
          </Text> */}
              <Text style={{ ...styles.text, ...styles.center, ...styles.boldText1, marginTop: 5 }}>
                FARMER PACKAGE POLICY CERTIFICATE
              </Text>
            </View>
            <View style={{ ...styles.divider, marginTop: 5 }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col2, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>
                  <Text style={styles.boldText2}>Certificte No.</Text> : {data.certificateNo}{" "}
                </Text>
              </View>
              <View style={{ ...styles.col3, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Period of Insurance</Text>
              </View>
              <View style={{ ...styles.col4, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>From </Text>
                <Text style={styles.text2}>To </Text>
              </View>
              <View
                style={{ ...styles.col5, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>
                  {" "}
                  {data.insuranceFrom}
                </Text>
                <Text style={styles.text2}>
                  {" "}
                  {data.insuranceTo}
                </Text>

              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col2, borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, ...styles.boldText2 }}>
                  Loknete Gopinath Mundhe Ustod Kamgar Kalyan Mahamandal,Pune{" "}
                </Text>
              </View>
              <View style={{ ...styles.col3, borderLeftWidth: 1 }}>
                <Text style={styles.text2}></Text>
              </View>
              <View style={{ ...styles.col4, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>:</Text>
              </View>
              <View
                style={{ ...styles.col5, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}></Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col2, borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, ...styles.boldText2 }}>A/C - {data.accNo}</Text>
              </View>
              <View style={{ ...styles.col3, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Master Policy No.</Text>
              </View>
              <View style={{ ...styles.col4, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>:</Text>
              </View>
              <View
                style={{ ...styles.col5, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>{data.policyNo}</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col2, borderLeftWidth: 1 }}>
                <Text style={styles.text2}></Text>
              </View>
              <View style={{ ...styles.col3, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>NIA CIN NO</Text>
              </View>
              <View style={{ ...styles.col4, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>:</Text>
              </View>
              <View
                style={{ ...styles.col5, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>{data.niaCinNo}</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col2, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Mobile No. : {data.mobileNo}</Text>
              </View>
              <View style={{ ...styles.col3, borderLeftWidth: 1 }}>
                <Text style={styles.text2}></Text>
              </View>
              <View style={{ ...styles.col4, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>NIA GST NO. </Text>
              </View>
              <View
                style={{ ...styles.col5, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>27AAACN4165C3ZP</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col2, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>GSTIN/UIN</Text>
              </View>
              <View style={{ ...styles.col3, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>{data.gstINUIN}</Text>
              </View>
              <View style={{ ...styles.col4, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>SAC </Text>
              </View>
              <View
                style={{ ...styles.col5, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>{data.SAC}</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View>
              <Text style={{ ...styles.text, ...styles.center, ...styles.boldText1, marginVertical: 5 }}>
                Coverages Of Farmer Package Policy
              </Text>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Name Of Section and Perils</Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Details Of Cover</Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>Sum Insured </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Sec I -Hut Fire & Allied Perils</Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>
                  Hut : Cover as per Fire & allied perils & EQ{" "}
                </Text>
                <Text style={styles.text2}>Contents : Household articles</Text>
                <Text style={styles.text2}>(Cover Crushing Season Only)</Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>3000 </Text>
                <Text style={styles.text2}>7000 </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Sec II - JPA</Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Janta Personal Accident Cover</Text>
                <Text style={styles.text2}>(Cover for One Year)</Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>100000 </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Sec III - Baggage Insurance</Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>
                  Baggage Insurance Cover Excluding Theft & Burglary
                </Text>
                <Text style={styles.text2}>(Cover for Crushing Season Only )</Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>1000</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Sec IV - Cattle Insurance </Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Bullock 1 - Tag No - XXXXXXXXX</Text>
                <Text style={styles.text2}>(Cover for Crushing Season Only )</Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>37500</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Sec IV - Cattle Insurance </Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Bullock 1 - Tag No - XXXXXXXXXX</Text>
                <Text style={styles.text2}>(Cover for Crushing Season Only )</Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>37500</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col6, borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, ...styles.boldText2 }}>Premium Rs. 1507/- </Text>
                <Text style={{ ...styles.text2, ...styles.boldText2 }}>GST Rs. 271/- </Text>
              </View>
              <View style={{ ...styles.col7, borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, ...styles.boldText2 }}>
                  Rs. One Thousand Seven Hundred Seventy Eight Only
                </Text>
              </View>
              <View
                style={{ ...styles.col8, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={{ ...styles.text2, ...styles.boldText2 }}>Total - Rs. 1778/-</Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={{ borderLeftWidth: 1, borderRightWidth: 1 }}>
              <Text
                style={{
                  marginVertical: 5,
                }}
              ></Text>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={{ ...styles.para1, borderRightWidth: 1 }}>
              <View style={{ borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, padding: 5 }}>
                  {" "}
                  Terms and conditions forming part of this Policy No. XXXXXXXXXXXX
                  may be downloaded from our website : newindia.co.in{" "}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={{ ...styles.para1, borderRightWidth: 1 }}>
              <View style={{ borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, padding: 5 }}>
                  Address of Policy Issuing office and Notice or communication to be
                  given in respect of claims.
                </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col9, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>
                  Address of Policy {"          "}Issuing Office
                </Text>
              </View>
              <View style={{ ...styles.col10, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Ahmednagar 0.0. Code 151800 </Text>
                <Text style={styles.text2}>
                  Abbot Build, Zendi Gate, Ahmednagar 414001{" "}
                </Text>
                <Text style={styles.text2}>
                  Phone 0241-2343372, 2321538, 2329761
                </Text>
              </View>
              <View style={{ ...styles.col11, borderLeftWidth: 1 }}>
                <Text style={styles.text2}>Contact Details</Text>
              </View>
              <View
                style={{ ...styles.col12, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>TOLL FREE: 1800-209-1415 </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={styles.para1}>
              <View style={{ ...styles.col13, borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, marginBottom: 5 }}>EmailId</Text>
                <Text style={{ ...styles.text2, marginBottom: 5 }}>Website</Text>
              </View>
              <View style={{ ...styles.col14, borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, marginBottom: 5 }}>
                  nia.151800@newindia.co.in
                </Text>
                <Text style={{ ...styles.text2, marginBottom: 5 }}>
                  www//newindia.com
                </Text>
              </View>
              <View
                style={{ ...styles.col15, borderLeftWidth: 1, borderRightWidth: 1 }}
              >
                <Text style={styles.text2}>
                  In witness where of the undersigned being duly authorized by the
                  insurers and on behalf of the insurers has (have) here under set
                  his (their) hand(s) on this {date}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={{ ...styles.divider, marginTop: 10 }} />
            <View style={styles.para1}>
              <View style={{ borderLeftWidth: 1, borderRightWidth: 1 }}>
                <Text style={{ ...styles.text2, padding: 5 }}>
                  {" "}
                  This certificate is issued based on the data submitted by
                  Mahamandal/Broker of their Beneficiaries in writing / through
                  mail/internet in respect of insured person and /or nominee such
                  application shall be the basis of this contact of Insurance
                  Coverage.{" "}
                </Text>
                <Text style={{ ...styles.text2, padding: 5 }}>
                  The benefits in respect of the within mentioned insured persons
                  are subject to definitions, terms, conditions and exclusions under
                  the respective policies.
                </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={{ ...styles.para1, borderRightWidth: 1 }}>
              <View style={{ borderLeftWidth: 1 }}>
                <Text style={{ ...styles.text2, padding: 5 }}>
                  This policy is subject to Farmer Package PolicyClause attached.
                  hereto
                </Text>
              </View>
            </View>
            <View style={{ ...styles.divider }} />
            <View>
              <Text style={{ ...styles.text, ...styles.right, marginTop: 10 }}>
                For The New India Assurance Company Limited
              </Text>
              <View>

                <Text style={{ marginTop: 29 }}>
                  <Text style={{ ...styles.text, ...styles.right, marginTop: 20 }}>
                    Authorized Signatory
                  </Text>
                </Text>
              </View>
            </View>
            {/* <View>
          <Text style={{ ...styles.text2, ...styles.center, marginTop: 30 }}>
            Bhagwan Pawar
          </Text>
          <Text style={{ ...styles.text2, ...styles.center, marginBottom: 7 }}>
            Divisional Manager
          </Text>
        </View> */}
            <View style={{ flex: 1, justifyContent: "flex-end" }}>

              <Text style={{ ...styles.text2, ...styles.center, fontSize: 9 }}>
                Regd. & Head Office: New India Assurance Bldg, 87 M.G.Road,
                Mumbai-400 001. TOLL FREE No. 1800 209 1415
              </Text>
              <Text style={{ ...styles.text2, fontSize: 9 }}>
                For redressal of your grievance, if any you may approach any one of
                the following offices - 1.Policy Issuing Office 2.Regional Office 3.
                Head Office in case you are not satisfied with our own grievence
                redressal mechanism; you may also approach Insurance Ombudsman.For
                details of our office addresses and addresses of office of Insurance
                Ombudsman, please visit our
              </Text>
              <Text style={{ ...styles.text2, ...styles.center, fontSize: 9 }}>
                website http://newindia.co.in.
              </Text>
            </View>
          </Page>
          <Page size="A4" style={styles.page}>
            {" "}
            <View
              style={{ ...styles.para1 }}
            >
              {/* <View style={styles.col1}>
            <Text style={styles.text2}>THE NEW INDIA ASSURANCE CO. LTD. </Text>
            <Text style={styles.text2}>(Goverment of India Undertaking) </Text>
          </View> */}
            </View>
            <View style={{ ...styles.divider, marginTop: 20 }} />
            <View style={{ borderRightWidth: 1, borderLeftWidth: 1, padding: 10, }}>
              <Text
                style={{
                  padding: 7,
                  // fontSize: 14,
                  ...styles.center,
                  ...styles.boldText1,
                }}
              >
                <Text> IMPORTANT</Text>
              </Text>
              <Text style={{ ...styles.text, padding: 7 }}>
                This policy issued subject to the terms and conditions contained in
                the policy document(Clauses).{" "}
              </Text>
              <Text style={{ ...styles.text, padding: 7 }}>
                This policy is governed by IRDAI(Protection of Policyholders
                Interest) Regulations,2017.
              </Text>
              <Text style={{ ...styles.text, padding: 7 }}>
                This schedule come attached with the policy document(Clauses).If not
                attached, please ask for the same. {"                 "}
                Protection of Policy holders Interest Regulations,2017 are available
                on the website of IRDAI.
              </Text>
              <Text style={{ ...styles.text, padding: 7 }}>
                Cover for Sect. I,III,IV is subject to as per MOU prepared by
                Advisor, NICL & Mahamandal.
              </Text>
              <Text style={{ ...styles.text, padding: 7 }}>
                Beware of spurious calls offering all urings benefits. Never share
                any policy details with unknown callers.
              </Text>
              <Text style={{ ...styles.text, padding: 7 }}>
                Call 1800-209-1415 for any enquiry or contact the nearest operating
                office of New India Assurance Co Ltd.
              </Text>
            </View>
            <View style={{ ...styles.divider }} />
            <View style={{ flex: 1, justifyContent: "flex-end" }}>

              <Text style={{ ...styles.text2, ...styles.center, fontSize: 9 }}>
                Regd. & Head Office: New India Assurance Bldg, 87 M.G.Road,
                Mumbai-400 001. TOLL FREE No. 1800 209 1415
              </Text>
              <Text style={{ ...styles.text2, fontSize: 9 }}>
                For redressal of your grievance, if any you may approach any one of
                the following offices - 1.Policy Issuing Office 2.Regional Office 3.
                Head Office in case you are not satisfied with our own grievence
                redressal mechanism; you may also approach Insurance Ombudsman.For
                details of our office addresses and addresses of office of Insurance
                Ombudsman, please visit our
              </Text>
              <Text style={{ ...styles.text2, ...styles.center, fontSize: 9 }}>
                website http://newindia.co.in.
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>

    </div>

  );
}
