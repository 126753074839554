import "./App.css";
 import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
  import Routing from "./Components/Routing/Routing";
// import DrRegistrationForm from "./Components/Webapp/Cattle/DrRegistrationForm";
//import AddExecutiveList from "./Components/Webapp/Cattle/AddDrRegistrationList";
// import AddDrRegistrationList from "./Components/Webapp/Cattle/AddDrRegistrationList";



function App() {
  return (
    <>
    <ErrorBoundary>
      <Routing />
      {/* <AddDrRegistrationList/> */}
     {/* <ExcelDownloadButton/> */}
     {/* <DrRegistrationForm/> */}
     {/* <AddExecutiveList /> */}
    </ErrorBoundary>
    </>
  );
}

export default App;
