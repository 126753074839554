import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import s1Image1 from "../../Assets/Images/slider-bg.jpg";

const cards = [
  {
    id: 1,
    title: "Field Executive",
    image: s1Image1,

    content:
      "Field executives are responsible for identifying and approaching potential clients who may require automobile insurance. This involves networking, attending events, and cold calling to build a client base.",
  },
  {
    id: 2,
    title: "Agency",
    image: s1Image1,

    content:
      "Running an agency that deals with both cattle and automobiles is an intriguing venture that straddles two distinct industries. While these sectors may seem unrelated, there are potential synergies and challenges to consider in managing such a business.",
  },
  {
    id: 3,
    title: "Office Administration",
    image: s1Image1,
    content: [
      "Our administrators are experts in handling a diverse set of responsibilities, from customer inquiries and policy documentation to compliance with industry regulations. They serve as the first point of contact, providing the warm and knowledgeable assistance our clients have come to expect."
     
    ],
  },
  {
    id: 4,
    title: "Specialized Coverage",
    image: s1Image1,
    content: [
      "",
    ],
  },
];
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Career() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <main>
        <Container maxWidth="lg">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Our Career
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
             Our career is a journey, not a destination. We offer a clear path for advancement, 
            acknowledging your hard work and dedication. Whether you're starting your career
             or looking to take the next step, we have opportunities that align with your aspirations.
          </Typography>

          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={3}>
                <Card className="service_card">
                  <CardContent className="service_card_content">
                    <Typography gutterBottom variant="h5" component="h2" className="Title">
                      {card.title}
                    </Typography>
                    <Typography>{card.content}</Typography>
                  </CardContent>
                  <CardActions className="service_card_action">
                    <Button className="service_card_button">
                      Read More
                      <DoubleArrowIcon />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Stack
            className="service_stack"
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              className="career_view"
              variant="contained"
              color="secondary"
            >
              View More
            </Button>
          </Stack>
        </Container>
      </main>
    </ThemeProvider>
  );
}
