import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Stack } from "@mui/material";
import OwnerDetail from "./OwnerDetail";
import Image from "./Image";
import CattalDetails from "./CattalDetails";
import VeterainAuthorizationForm from "./Authorization";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import CommonSnackBar from "../../CommonComponents/CommonSnackbar";
import {
  CATTLE_EDIT_CATTLE,
  CATTLE_POST_CATTLE,
  EDIT_MULTIPLE_CATTLE,
  POST_MULTIPLE_CATTLE,
  UPLOAD_IMAGES_FOR_MULTIPLE_CATTLE,
} from "../../../Api/ServerConfiguration";
import CattleInitialState from "../../../Redux/Reducer/CattleReducer";
import { OwnerInitialState } from "../../../Redux/Reducer/OwnerReducer";
import { initialState } from "../../../Redux/Reducer/AuthorisedReducer";
import {
  postrequestMethod,
  postrequestMethodByParams,
  updateRequest,
  updateRequestwithVDID,
} from "../../../Api/ApiEndpointconfiguration";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Cattle() {
  const [value, setValue] = React.useState(0);
  const allApplicationData = useSelector((state: any) => state);
  const statusof = useSelector((state: any) => state);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [clickedTab, setClickedTab] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatchAllImages = useDispatch();
  const location = useLocation();
  const FormID = location?.state?.status;


  const isCurrentTabValid = () => {
    switch (value) {

      case 0: // Validation for the first tab
        return (
          allApplicationData.OwnerReducer.ownerName &&
          allApplicationData.OwnerReducer.fieldExecutive &&
          allApplicationData.OwnerReducer.inspectionAgency &&
          allApplicationData.OwnerReducer.insuranceRepository &&
          allApplicationData.OwnerReducer.jobType &&
          (location.state !== null ? allApplicationData.OwnerReducer.aadharNumber : true)
        )
      default:
        return true; // No validation for other tabs
    }
  };

  const ChangeTab = (event: any, newValue: any) => {
    const isTabValid = isCurrentTabValid();
    if (isTabValid) {
      setValue(newValue);
    }
    else {
      // Display an error message or prevent navigation
      handleOpenSnackBar(
        "Please fill in all required fields before proceeding."
      );
    }
  };

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };
  const handleNext = () => {
    const isTabValid = isCurrentTabValid();
    if (isTabValid) {
      // Calculate the next tab index
      const nextTabIndex = (value + 1) % 4; // Assuming you have 4 tabs

      // Update the active tab
      setValue(nextTabIndex);
    } else {
      // Display an error message or prevent navigation
      handleOpenSnackBar(
        "Please fill in all required fields before proceeding."
      );
    }
  };

  const handlePrevious = () => {
    const PreviewTabIndex = (value - 1) % 4; // Assuming you have 4 tabs
    // Update the active tab
    setValue(PreviewTabIndex);
    // } else {
    //   // Display an error message or prevent navigation
    //   alert("Please fill in all required fields before proceeding.");
    // }
  };


  const finalSubmitData = async () => {
    setLoading(true);
    const images = allApplicationData.ImageReducer.Images;
    const tempObject = {
      ...allApplicationData.OwnerReducer,
      ...allApplicationData.AuthorisedReducer,
      ...allApplicationData.ImageReducer,
      cattleDetails: [...allApplicationData.CattleReducer]
    };
    if (images) {
      const keys = Object.keys(images);
      const uploadPromises = keys.map(async (fieldName) => {
        const formData = new FormData();
        const allimages = images[fieldName];
        // Check if allimages is an array before using forEach
        allimages.forEach(async (image: any, index: any) => {
          formData.append(fieldName, image.file);
        });

        try {

          const response: any = await updateRequestwithVDID(
            UPLOAD_IMAGES_FOR_MULTIPLE_CATTLE,
            FormID,
            fieldName,
            formData,
            "multipart/form-data"
          );
          if (response.status === "pending" || response.status === 204) {
            setLoading(true)
          }
          if (response.data) {
            dispatchAllImages({
              type: "Authorised_Update",
              payload: {
                ...allApplicationData.AuthorisedReducer,
                status: "Completed"
              }
            });
          }
        } catch (error) {
          console.error("Error uploading images for field", fieldName, ":", error);
        }
      });

      try {
        // Wait for all image upload promises to resolve
        await Promise.all(uploadPromises);
      } catch (error) {
        // Handle errors if necessary
        console.error("Error uploading images:", error);
        setLoading(false);
      }
    }
    const formData = new FormData();
    formData.append(
      "cattleDetails",
      JSON.stringify(tempObject.cattleDetails)
    );
    // Append each field of tempObject to the FormData
    Object.keys(tempObject).forEach((tempKey) => {
      // Skip appending "cattleDetails" as it's already appended
      if (tempKey !== "cattleDetails") {
        formData.append(tempKey, tempObject[tempKey]);
      }
    });
    // Append each image to the FormData for the current key
    // if (Array.isArray(images)) {
    //   images.forEach((image: any, index: any) => {
    //     formData.append(`Images`, image);
    //   });
    // }
    if (location?.state) {
      const VDID = allApplicationData.CattleReducer[0].VDID;
      updateRequestwithVDID(
        EDIT_MULTIPLE_CATTLE,
        FormID,
        VDID,
        formData,
        "multipart/form-data"
      )
        .then((response: any) => {
          setLoading(false);
          if (response.status === 200) {
            handleOpenSnackBar("Record updated Successfully.");
            setTimeout(() => {
              navigate("/dashboard/cattle");
            }, 1000);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          // Handle any errors
          setLoading(false);
          console.error(error);
        });
    } else {
      const VDID = allApplicationData.CattleReducer[0].VDID;
      const result = postrequestMethodByParams(
        POST_MULTIPLE_CATTLE,
        VDID,
        "multipart/form-data",
        formData
      );
      result
        .then((response: any) => {
          setLoading(false)
          if (response) {
            navigate("/dashboard/cattle");
          }
          // window.location.reload();
        })
        .catch((error: any) => {
          // Handle any errors
          console.error(error);
        })
    }
  };

  useEffect(() => {
    if (allApplicationData.ImageReducer.Images > 0) {
      dispatchAllImages({
        type: "Authorised_Update",
        payload: {
          ...allApplicationData.AuthorisedReducer,
          status: "Completed"
        }
      });
    }
  }, [])

  return (
    <>
      <Box
        component="form"
        sx={{ width: "100%" }}
        className="MainForm"
      >
        {loading &&
          (
            <Box sx={{ display: 'flex' }} className="progress_circle">
              <CircularProgress />
              {/* <Typography>hsbchsbhcbshchsbchbshbchbajxjaj</Typography> */}
            </Box>
          )
        }
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

          <Tabs
            value={value}
            onChange={ChangeTab}
            aria-label="basic tabs example"
            className="TabsMainOuter"
            variant="scrollable"
          >
            <Tab label="Owner Detail" {...a11yProps(0)} />
            <Tab label="Cattle Details" {...a11yProps(1)} />
            <Tab label="Authorized Signature" {...a11yProps(2)} />
            <Tab label="Upload Image" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <OwnerDetail />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CattalDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <VeterainAuthorizationForm />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Image />
        </CustomTabPanel>
        <Box display="flex" justifyContent="center" sx={{ marginTop: "20px" }}>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handlePrevious}
              disabled={value == 0}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              Previous
            </Button>

            <Button
              onClick={handleNext}
              disabled={value == 3}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              Next
            </Button>

            <Button
              disabled={value !== 3}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              onClick={finalSubmitData}
            >
              Submit
            </Button>
          </Stack>
        </Box>
        <CommonSnackBar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleCloseSnackBar}
          message={snackMessage}
        />
      </Box>
    </>
  );
}
function setAuthorization(auth: any) {
  throw new Error("Function not implemented.");
}
// function dispatchOwner(arg0: { type: string; payload: any }) {
//   throw new Error("Function not implemented.");
// }


export default Cattle


