import React from "react";
import { Snackbar, SnackbarProps } from '@mui/material';
interface SnackBarProps extends SnackbarProps {
  message: string;
}
const CommonSnackBar: React.FC<SnackBarProps> = ({ message, ...props }) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...props}
        message={message}
      />
    </>
  );
};

export default CommonSnackBar;
