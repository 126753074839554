import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material'
import useValidation from '../../../Validation/useValidation'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Twowheelerform from './TwoWheeler'
import Fourwheelerform from './FourWheeler'
import CommercialForm from './Commercial'
import Others from './Others'
import { useDispatch, useSelector } from 'react-redux'
import { getRequest } from '../../../Api/ApiMethods'
import { GET_EXECUTIVE_LIST } from '../../../Api/ServerConfiguration'
import { useLocation } from 'react-router-dom'

const OwnerDetails = () => {
  const getAutomobileFormStateData = useSelector(
    (state: any) => state.OwnerAutoReducer
  )
 
  const [formData, setFormData] = useState({ ...getAutomobileFormStateData })
  // vehicle type selection
  const [value, setValue] = React.useState('twowheeler')
  const [executive, setexecutive] = useState<any>([])
  const [insuranceCompany, setInsuranceCompany] = useState<any>([])
  const { eventHandler } = useValidation()
  const dispatchTwoWheeler = useDispatch();
  const location = useLocation();
  const [error, setError] = useState({
    ownerName: '',
    locationOfVehicle: '',
    permanentAddress: '',
    mobileNumber: '',
    emailID: '',
    jobType: '',
    inspectionAgency: '',
    color: '',
    bodyType: '',
    chassisNumber: '',
    vehicleCompany: '',
    vehicleNumber: '',
    fuelType: '',
    vehicleModel: '',
    engineNumber: '',
    odometerReading: '',
    vehicleCategory: '',
    vehicleClassDescription: '',
    vehicleType: '',
    permitType: '',
    ownerSerialNumber: '',
    insuranceCompany: '',
    fieldExecutive: '',
    fieldExecutiveEmail: ''
  })

  const [companies, setCompanies] = useState([])
  // Define your companies data structure as needed

  // Function to fetch companies based on selected sales channel
  const fetchCompanies = (selectedChannel: any) => {
    // Replace this with your actual data fetching logic
    if (selectedChannel === 'Insurance') {
      // setCompanies(['Company A', 'Company B', 'Company C']);
    } else if (selectedChannel === 'Bank') {
      // setCompanies(['Bank X', 'Bank Y', 'Bank Z']);
    } else {
      setCompanies([])
    }
  }

  const hadleVehicleType = (event: any) => {
    const { name, value } = event.target
    setValue(value);
    setFormData({
      ...formData,
      [name]: value
    })
    dispatchTwoWheeler({ type: "CLEAR_TWOWHEELER_DATA", payload: {} });
    dispatchTwoWheeler({ type: "CLEAR_FOURWHEELER_DATA", payload: {} });
    dispatchTwoWheeler({ type: "CLEAR_COMMERCIAL_DATA", payload: {} });
    dispatchTwoWheeler({ type: "CLEAR_OTHER_DATA", payload: {} });
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleFieldExecutive = (e: any) => {
    const filterExe = e.target.value
      ? executive.filter((exe: any) => exe.fullname === e.target.value)
      : {}
    const { fieldExecutiveEmail, firstname, fullname, lastname } = filterExe[0]
    setFormData({
      ...formData,
      fieldExecutive: fullname,
      fieldExecutiveEmail: fieldExecutiveEmail
    })
  }

  const dispatchOwner = useDispatch()

  useEffect(() => {
    if (formData) {
      dispatchOwner({ type: "UPDATE_AUTO_OWNER_DATA", payload: formData });
    }
  }, [formData])

  const getExecutiveList = () => {
    getRequest(GET_EXECUTIVE_LIST, '')
      .then((res: any) => {
        setexecutive(res.data.executiveList)
        setInsuranceCompany(res.data.companyList)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getExecutiveList()
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    // You can handle form submission here or send the data to an API
  }

  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value

    const id = alterName

    if (id) {
      let prom = new Promise(resolve => {
        if (true) {
          resolve(eventHandler(id, val))
        }
      })

      prom.then(res => setError({ ...error, [e.target.name]: res }))
    }

    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <Container maxWidth='md'>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
              <Typography variant='h6' gutterBottom>
                Agency Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Inspection Agency'
                    size='small'
                    name='inspectionAgency'
                    select // Make it a select input
                    value={formData.inspectionAgency}
                    onChange={(e: any) => {
                      validationHandler(e, 'alphabetsAndSpace')
                      handleChange(e)
                    }}
                    error={Boolean(error.inspectionAgency)}
                    helperText={error.inspectionAgency}
                    fullWidth
                    required
                    margin='normal'
                  >
                    <MenuItem value='ARM'>ARM</MenuItem>
                    <MenuItem value='Finguard'>Finguard</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Insurance Company'
                    size='small'
                    name='insuranceCompany'
                    select
                    value={formData.insuranceCompany}
                    onChange={(e: any) => {
                      // validationHandler(e);
                      handleChange(e)
                    }}
                    error={Boolean(error.insuranceCompany)}
                    helperText={error.insuranceCompany}
                    fullWidth
                    required
                    margin='normal'
                  >
                    {insuranceCompany && insuranceCompany.map((item: any) => {
                      return (
                        <MenuItem value={item.companyName} key={item.uniqueCId}>
                          {item.companyName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Job Type'
                    size='small'
                    name='jobType'
                    select
                    value={formData.jobType}
                    onChange={(e: any) => {
                      handleChange(e)
                    }}
                    error={Boolean(error.jobType)}
                    helperText={error.jobType}
                    fullWidth
                    required
                    margin='normal'
                  >
                    <MenuItem value='Preinspection'>Preinspection</MenuItem>
                    <MenuItem value='Valuation'>Valuation</MenuItem>
                    <MenuItem value='Survey'>Survey</MenuItem>
                    <MenuItem value='Investigation'>Investigation</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Field Executive'
                    size='small'
                    name='fieldExecutive'
                    select // Make it a select input
                    value={formData.fieldExecutive}
                    onChange={handleFieldExecutive}
                    fullWidth
                    margin='normal'
                    required
                  >
                    {/* Add your custom options here */}
                    {executive &&
                      executive.map((item: any) => {
                        return (
                          <MenuItem value={item.fullname}>
                            {item.fullname}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
              <Typography variant='h6' gutterBottom>
                Owner Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Owner Name'
                    size='small'
                    name='ownerName'
                    value={formData.ownerName}
                    onChange={(e: any) => {
                      validationHandler(e, 'alphabetsAndSpace')
                      handleChange(e)
                    }}
                    error={Boolean(error.ownerName)}
                    helperText={error.ownerName}
                    fullWidth
                    margin='normal'
                    type='text'
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Permanent Address'
                    size='small'
                    name='permanentAddress'
                    value={formData.permanentAddress}
                    onChange={(e: any) => handleChange(e)}
                    fullWidth
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Mobile No'
                    size='small'
                    name='mobileNumber'
                    value={formData.mobileNumber}
                    onChange={(e: any) => {
                      validationHandler(e, 'mobile')
                      handleChange(e)
                    }}
                    error={Boolean(error.mobileNumber)}
                    helperText={error.mobileNumber}
                    // onChange={handleChange}
                    fullWidth
                    required
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Email ID'
                    size='small'
                    name='emailID'
                    type='email'
                    value={formData.emailID}
                    onChange={(e: any) => {
                      validationHandler(e, 'email')
                      handleChange(e)
                    }}
                    error={Boolean(error.emailID)}
                    helperText={error.emailID}
                    fullWidth
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    label='Owner Serial Number'
                    size='small'
                    name='ownerSerialNumber'
                    value={formData.ownerSerialNumber}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
              <Typography variant='h6' gutterBottom>
                Vehicle Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Location Of Vehicle'
                    size='small'
                    name='locationOfVehicle'
                    value={formData.locationOfVehicle}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Color'
                    size='small'
                    name='color'
                    value={formData.color}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Body Type'
                    size='small'
                    name='bodyType'
                    value={formData.bodyType}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    label='Chassis Number'
                    size='small'
                    name='chassisNumber'
                    value={formData.chassisNumber}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Vehicle Company/Make'
                    size='small'
                    name='vehicleCompany'
                    value={formData.vehicleCompany}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Vehicle No"
                    size='small'
                    name="vehicleNumber"
                    value={formData.vehicleNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Fuel Type'
                    size='small'
                    name='fuelType'
                    value={formData.fuelType}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Vehicle Model'
                    size='small'
                    name='vehicleModel'
                    value={formData.vehicleModel}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Engine Number'
                    size='small'
                    name='engineNumber'
                    value={formData.engineNumber}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Odometer Reading'
                    size='small'
                    name='odometerReading'
                    value={formData.odometerReading}
                    onChange={handleChange}
                    fullWidth
                    // required
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label='Vehicle Category'
                    size='small'
                    name='vehicleCategory'
                    value={formData.vehicleCategory}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label='Vehicle Class Description'
                    size='small'
                    name='vehicleClassDescription'
                    value={formData.vehicleClassDescription}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl required={location.state === null}>
                    <FormLabel id='demo-controlled-radio-buttons-group'>
                      Vehicle Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='vehicleType'
                      value={formData.vehicleType}
                      onChange={hadleVehicleType}
                    >
                      <Stack direction={'row'} spacing={4}>
                        {location.state === null ? (
                          <>
                            <FormControlLabel
                              value='twowheeler'
                              control={<Radio />}
                              label='Two wheeler'
                            />
                            <FormControlLabel
                              value='fourwheeler'
                              control={<Radio />}
                              label='Four wheeler'
                            />
                            <FormControlLabel
                              value='commercial'
                              control={<Radio />}
                              label='Commercial'
                            />
                            <FormControlLabel
                              value='others'
                              control={<Radio />}
                              label='Others'
                            />
                          </>
                        ) : (
                          <>
                            <FormControlLabel
                              value={formData.vehicleType}  // Set the vehicle type from location.state
                              control={<Radio />}
                              label={formData.vehicleType}  // Set the label based on location.state
                            />
                          </>
                        )}

                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {formData.vehicleType === 'twowheeler' ? (
                  <Twowheelerform />
                ) : formData.vehicleType === 'fourwheeler' ? (
                  <Fourwheelerform />
                ) : formData.vehicleType === 'commercial' ? (
                  <CommercialForm />
                ) : formData.vehicleType === 'others' ? (
                  <Others />
                ) : null}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default OwnerDetails
