import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TablePagination,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import { deleteRequest, getRequest } from "../../../Api/ApiMethods";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  GET_EXECUTIVE_LIST,
  DELETE_EXECUTIVE,
} from "../../../Api/ServerConfiguration";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CommonSnackBar from "../../CommonComponents/CommonSnackbar";

function AddExecutiveList() {
  const [users, setUsers] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const [users1, setUsers1] = useState<any[]>([]);
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };
  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };
  const navigate = useNavigate();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navgationHandler = () => {
    navigate("/dashboard/Cattle/Executive");
  };

  const fetchData = async () => {
    try {
      let response: any = await getRequest(GET_EXECUTIVE_LIST, "");

      setUsers(response.data.executiveList);
      if (users) {
        console.log("GET_EXECUTIVE_ffffffLIST", users);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteData = async (executive_id: any) => {
    try {
      const res: any = await deleteRequest(DELETE_EXECUTIVE, executive_id, "");
      if (res.data) {
        // setUsers(res.data);
        // alert("Are you sure you want to delete this item?");
        handleOpenSnackBar("Record Deleted Successfully");
        setTimeout(() => {
          setOpenSnackBar(false);
        }, 1000)
      }
      setOpen(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const handleClose = () => {
    try {
      setOpen(false);
    } catch (error) {
      console.error("Error in handleClose:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const filteredUsers = users?.filter(
    (item: any) =>
      item.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.phonenumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.emailAddress.toLowerCase().includes(searchQuery.toLowerCase())

    // Add additional fields for searching if needed
  );

  return (
    <>
      <Container style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" size="large" onClick={navgationHandler}>
              Create
            </Button>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12} sm={6}></Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl>
              <TextField
                label="Search"
                size="small"
                sx={{ mx: 3, background: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
              marginTop: "15px",
            }}
          >
            <Grid>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Executive List
              </Typography>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Form No.
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Phone Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    email Address
                  </TableCell>

                  {/* <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Full Name
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Field Executive Email
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Executive Address
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Executive District
                  </TableCell> */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users &&
                  filteredUsers
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item: any, index: any) => (
                      <React.Fragment key={item.executive_id}>
                        <TableRow key={item.executive_id}>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.executive_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.firstname}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.lastname}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.phonenumber}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.emailAddress}
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.fullname}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.fieldExecutiveEmail}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.executiveAddress}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.executiveDistrict}
                          </TableCell> */}
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item?.status}

                            <IconButton>
                              <EditIcon
                                onClick={() =>
                                  navigate("/dashboard/Cattle/Edit", {
                                    state: item,
                                  })
                                }
                              />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  //  deleteData(item.executive_id)
                                  setUsers1(item.executive_id);
                                  setOpen(true);
                                }}
                                sx={{ color: "red" }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {filteredUsers && users.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filteredUsers?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Paper>

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        // aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"DELETE"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteData(users1)}>Delete</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <CommonSnackBar message={snackMessage} open={openSnackBar} />
      </Container>
    </>
  );
}
export default AddExecutiveList;
