
export const initialState = {
    token:""
}

const TokenReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case "Token_Update":
            return{
                ...state,
                token: action.payload.token,
                
            }
    
        default:
            return{ ...state }
    }
}

export default TokenReducer;