const initialState = {
    Images: [],
}

const ImageReducer = (state = initialState, action?: any) => {
    switch (action.type) {
        case "UPDATE_IMAGES":
            return {
                ...state,
                Images: action.payload
            }
        default:
            return state;
    }
}

export default ImageReducer;