import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { useState } from 'react'
import Image from './Image'
import OwnerDetails from './OwnerDetails'
import VehicleDetails from './VehicleDetails'
import { Button, Stack } from '@mui/material'
import VeterainAuthorizationForm from './Authorization'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { postrequestMethod, updateRequest } from '../../../Api/ApiMethods'
import {
  EDIT_AUTOMOBILE_LEAD,
  POST_AUTOMOBILE
} from '../../../Api/ServerConfiguration'
import CommonSnackBar from "../../CommonComponents/CommonSnackbar";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0)
  const [snackMessage, setSnackMessage] = useState<string>('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const allApplicationData = useSelector((state: any) => state)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const FormID = location?.state?.status
  const [formData, setFormData] = useState({
    ownerName: '',
    locationOfVehicle: '',
    permanentAddress: '',
    mobileNumber: '',
    emailID: '',
    jobType: '',
    inspectionAgency: '',
    color: '',
    bodyType: '',
    chassisNumber: '',
    vehicleCompany: '',
    vehicleNo: '',
    fuelType: '',
    vehicleModel: '',
    engineNumber: '',
    vehicleCategory: '',
    vehicleClassDescription: '',
    vehicleType: '',
    permitType: ''
  })

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  const ChangeTab = (event: any, newValue: any) => {
    // setValue(newValue);
    const isTabValid = isCurrentTabValid()
    if (isTabValid) {
      setValue(newValue)
    } else {
      // Display an error message or prevent navigation
      handleOpenSnackBar(
        'Please fill in all required fields before proceeding.'
      )
    }
  }

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message)
    setOpenSnackBar(true)
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault()
  }

  const isCurrentTabValid = () => {
    switch (value) {
      case 0: // Validation for the first tab
        return (
          allApplicationData.OwnerAutoReducer.ownerName &&
          allApplicationData.OwnerAutoReducer.inspectionAgency &&
          allApplicationData.OwnerAutoReducer.insuranceCompany &&
          allApplicationData.OwnerAutoReducer.jobType &&
          allApplicationData.OwnerAutoReducer.fieldExecutive &&
          allApplicationData.OwnerAutoReducer.vehicleType
        )
      // return true
      default:
        return true // No validation for other tabs
    }
  }

  const handlePrevious = () => {
    const PreviewTabIndex = (value - 1) % 4
    setValue(PreviewTabIndex);
  }

  const handleNext = () => {
    const isTabValid = isCurrentTabValid();
    if (isTabValid) {
      // Calculate the next tab index
      const nextTabIndex = (value + 1) % 4; // Assuming you have 4 tabs

      // Update the active tab
      setValue(nextTabIndex);
    } else {
      // Display an error message or prevent navigation
      handleOpenSnackBar(
        "Please fill in all required fields before proceeding."
      );
    }
  }

  const finalSubmiatData = () => {
    setLoading(true)
    const tempObject = {
      ...allApplicationData.VehicleReducer,
      ...allApplicationData.OwnerAutoReducer,
      ...allApplicationData.AuthorisedAutoReducer,
      ...allApplicationData.AutoMobileImageReducer,
      ...allApplicationData.TwoWheelerReducer,
      ...allApplicationData.FourWheelerReducer,
      ...allApplicationData.CommercialReducer,
      ...allApplicationData.OthersReducer
    }
    const images = allApplicationData.AutoMobileImageReducer?.Images
    const formData = new FormData()

    // Append each field of tempObject to the FormData
    Object.keys(tempObject).forEach(key => {
      formData.append(key, tempObject[key])
    })

    // Append each image to the FormData
    if (images) {
      images.forEach((image: any, index: any) => {
        formData.append('Images', image)
      })
    }
    if (location?.state) {
      updateRequest(
        EDIT_AUTOMOBILE_LEAD,
        FormID,
        formData,
        'multipart/form-data'
      )
        .then((response: any) => {
          setLoading(false)
          if (response.status === 200) {
            handleOpenSnackBar('Record updated Successfully.')
            setTimeout(() => {
              navigate('/dashboard/Automobiles')
            }, 1000)
          }
          // window.location.reload();
        })
        .catch((error: any) => {
          // Handle any errors
          setLoading(false)
          console.error(error)
        })
    } else {
      const result = postrequestMethod(
        POST_AUTOMOBILE,
        'multipart/form-data',
        tempObject
      )
      result
        .then((response: any) => {
          navigate('/dashboard/Automobiles')
          // window.location.reload();
        })
        .catch((error: any) => {
          // Handle any errors
          console.error(error)
        })
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={ChangeTab}
          aria-label='basic tabs example'
        >
          <Tab label='Owner Details' {...a11yProps(0)} />
          <Tab label='Vehicle Details' {...a11yProps(1)} />
          { }
          <Tab label='Vehicle Inspector' {...a11yProps(2)} />
          <Tab label='Upload Image' {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <OwnerDetails />
      </CustomTabPanel>
      { }
      <CustomTabPanel value={value} index={1}>
        <VehicleDetails />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <VeterainAuthorizationForm />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Image />
      </CustomTabPanel>
      <Box display='flex' justifyContent='center' sx={{ marginTop: '20px' }}>
        <Stack direction='row' spacing={2}>
          <Button
            onClick={handlePrevious}
            variant='contained'
            color='primary'
            disabled={value == 0}
          >
            {' '}
            Previous
          </Button>
          <Button
            onClick={handleNext}
            variant='contained'
            color='primary'
            disabled={value == 3}
          >
            {' '}
            Next
          </Button>
          <Button
            disabled={value !== 3}
            variant='contained'
            color='primary'
            sx={{ textTransform: 'none' }}
            onClick={finalSubmiatData}
          >
            Submit
          </Button>
        </Stack>
      </Box>
      <CommonSnackBar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
        message={snackMessage}
      />
    </Box>
  )
}
