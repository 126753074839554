


import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, TextField, Typography } from '@mui/material';
import useValidation from '../../Validation/useValidation';
import { useDispatch, useSelector } from 'react-redux';

const VeterainAuthorizationForm = () => {
  // Define your interface for authorization data
  interface Authorization {
    nameOfThePerson: string;
    designation: string;
    signature: {
      name: string;
      path: string;
    };
  }

  const getAutomobileFormStateData = useSelector((state: any) => state.AuthorisedAutoReducer);
  const VehicleDetail = useSelector((state: any) => state);
  const [PreviewSignature, SetPreviewSignature] = useState<any>("")
  // Initialize your state variables
  const [formData, setFormData] = useState(
    // { ...getAutomobileFormStateData }
    {
      nameOfThePerson: getAutomobileFormStateData.nameOfThePerson || "",
      designation: getAutomobileFormStateData.designation || "",
      signature: getAutomobileFormStateData.signature || "",
      status: getAutomobileFormStateData.status || "",
    }
    //   {
    //   nameOfThePerson: '',
    //   designation: '',
    //   signature: {
    //     name: '',
    //     path: '',
    //   },
    // }
  );

  const [error, setError] = useState({
    nameOfThePerson: '',
    designation: '',
    signature: '',
    status: "Assigned"
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { eventHandler } = useValidation();

  // Get form state data from Redux store
  const getFormStateData = useSelector((state: any) => state.AuthorisedReducer);

  const dispathAuthorisedDetails = useDispatch();

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission here, you can dispatch the formData or perform any other actions
    dispathAuthorisedDetails({ type: 'Authorised_Update', payload: formData });
  };

  useEffect(() => {
    // Make sure to dispatch the data when the component mounts
    if (formData) {
      dispathAuthorisedDetails({ type: 'Authorised_Update', payload: formData });
    }
  }, [formData, dispathAuthorisedDetails]);

  const handleFileChange = (e: any) => {
    const { name, files } = e.target;
    if (files[0]) {
      setFormData({
        ...formData,
        [name]: files[0],
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        SetPreviewSignature(e.target?.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      // No file selected, set formData.signature to an empty string
      setFormData({
        ...formData,
        [name]: "",
      });

    }
  };
  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ border: '1px solid grey', p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Authorization Details
              </Typography>
              <TextField
                label="Name of the Person"
                name="nameOfThePerson"
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                value={formData.nameOfThePerson}
                onChange={handleChange}
              />
              <TextField
                label="Designation"
                name="designation"
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                value={formData.designation}
                onChange={handleChange}
              />
              {formData.signature !== "" && PreviewSignature === "" && (
                <img
                  src={formData.signature}
                  alt="image"
                  style={{ maxWidth: '100%', maxHeight: '100%', width: '500px', height: '300px' }}
                />
              )}
              {PreviewSignature !== "" && (
                <img
                  src={PreviewSignature}
                  alt="image"
                  style={{ maxWidth: '100%', maxHeight: '100%', width: '500px', height: '300px' }}
                />
              )}
              <TextField
                label="Signature"
                name="signature"
                type="file"
                variant="outlined"
                fullWidth
                size="small"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => {
                  // validationHandler(e, "a");
                  handleFileChange(e);
                }}
                inputProps={{
                  accept: ".png", // Define accepted file types
                  maxFileSize: 1048576, // 1MB in bytes
                }}
                helperText="Upload a PNG with (max 1MB)"
              // required
              // Add onChange handler for file input if needed
              />
              {/* Display error messages */}
              <div>{error.nameOfThePerson}</div>
              <div>{error.designation}</div>
              <div>{error.signature}</div>
            </Paper>
          </Grid>
          {/* <Grid item xs={12}>
            <button type="submit">Submit</button>
          </Grid> */}
        </Grid>
      </form>
    </Container>
  );
};

export default VeterainAuthorizationForm;
