import React, { useEffect, useState } from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { Font } from "@react-pdf/renderer";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { tableHeaders1 } from '../../CommonComponents/ReportHeaders'
import { getRequest, getRequestByParams } from "../../../Api/ApiEndpointconfiguration";
import { GET_AUTOMOBILE_DETAILS_BY_OWNERNAME, GET_AUTOMOBILE_LIST, GET_COMMERCIAL_DATA, GET_FOUR_WHEELER_DATA, GET_IMAGE_FOR_AUTOMOBILE_FROM_S3_BUCKET, GET_IMAGE_FROM_S3_BUCKET, GET_OTHER_DATA, GET_TWO_WHEELER_DATA } from "../../../Api/ServerConfiguration";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
// Register the FontAwesome font
Font.register({
    family: "FontAwesome",
    src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf",
});
const styles = StyleSheet.create({
    page: {
        padding: 10,
        fontFamily: "Times-Roman",
    },
    boldText: {
        fontFamily: "Helvetica-Bold",
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.7)'
    },
    boldText1: {
        fontFamily: "Helvetica-Bold",
        fontSize: 13,
        color: 'rgba(0, 0, 0, 0.7)'
    },
    boldText2: {
        fontFamily: "Helvetica-Bold",
        fontSize: 9,
        color: 'rgba(0, 0, 0, 0.7)'
    },
    boldText3: {
        fontFamily: "Helvetica-Bold",
        fontSize: 9,
        color: 'rgba(0, 0, 0, 0.7)'
    },
    para1: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    para5: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
    },
    col1: {
        width: "70%",
        padding: 15,
    },
    col2: {
        width: "30%",
        // padding: 20,
    },
    right: {
        alignItems: "flex-end", // Center-align the content horizontally
        justifyContent: "flex-end", // Center-align the content vertically
        textAlign: "right",
    },
    left: {
        alignItems: "flex-start", // Center-align the content horizontally
        justifyContent: "flex-start", // Center-align the content vertically
        textAlign: "left",
    },
    center: {
        alignItems: "center", // Center-align the content horizontally
        justifyContent: "center", // Center-align the content vertically
        textAlign: "center", // Center-align the text within the Text component
    },
    text2: {
        fontSize: 9,
        textAlign: "justify",
    },
    text3: {
        fontSize: 10,
        textAlign: "justify",
    },
    text1: {
        fontSize: 11,
        textAlign: "justify",
    },
    icon: {
        fontFamily: "FontAwesome", // Use a font that includes icons, e.g., FontAwesome
        fontSize: 12, // Adjust the font size to control icon size
        marginRight: 5, // Add margin for spacing
    },
    divider3: {
        borderBottom: 1, // Add a 1-point (default unit) solid line as a divider
        marginBottom: 5, // Adjust margin to control spacing between sections
    },
    squareBox: {
        width: 25, // Adjust the width and height as needed
        height: 15,
        border: 1, // Border width
        borderColor: "black", // Border color
        marginRight: 5, // Adjust margin as needed
    },
    squareBox1: {
        width: 200, // Adjust the width and height as needed
        height: 60,
        border: 2, // Border width
        borderColor: "black", // Border color
        marginRight: 5,
        marginLeft: 20 // Adjust margin as needed
    },
    squareBox2: {
        width: 50, // Adjust the width and height as needed
        height: 25,
        border: 2, // Border width
        borderColor: "black", // Border color
        marginRight: 10,
        marginLeft: 70,
        marginTop: 5
    },
    table: {
        flexDirection: "row",
        width: "100%",
        // marginBottom: 10,
    },
    tableCell: {
        width: "20%", // Set your desired column width here
        borderStyle: "solid",
        borderColor: "#000",
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        padding: 1,
        textAlign: "center",
    },
    tableRow1: {
        flexDirection: "row",
        // borderBottomWidth: 1,
    },
    image: {
        height: 200,
        width: 200,
    },
    column4: {
        width: "50%",
        padding: 13,
    },
})

export default function Report3() {
    const [twoWheelerData, setTwoWheelerData] = useState<any>([]);
    const [fourWheelerData, setFourWheelerData] = useState<any>([]);
    const [CommercialData, setCommercialData] = useState<any>([]);
    const [otherData, setOtherData] = useState<any>([]);
    const mapTwoWheelerData: any = [];
    const mapFourWheelerData: any = [];
    const mapCommercialData: any = [];
    const mapOtherData: any = [];
    const [Owner, setOwner] = useState<any[]>([]);
    const [selectedOwner, setSelectedOwner] = useState<any>("");
    const [autoData, setAutoData] = useState<any>({});
    const [type, setType] = useState<any>("")
    const [FormId, setFormId] = useState<any>("")
    const [imageDataURI, setImageDataURI] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const getTwoWheelerData = async () => {
        try {
            // const response: any = await axios.get(
            //     "http://localhost:5100/report/getTwoWheelerData"
            // );
            const response: any = await getRequest(GET_TWO_WHEELER_DATA, "")
            setTwoWheelerData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getFourWheelerData = async () => {
        try {
            // const response: any = await axios.get(
            //     "http://localhost:5100/report/getFourWheelerData"
            // );
            const response: any = await getRequest(GET_FOUR_WHEELER_DATA, "")
            setFourWheelerData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getCommercialData = async () => {
        try {
            // const response: any = await axios.get(
            //     "http://localhost:5100/report/getCommercialData"
            // );
            const response: any = await getRequest(GET_COMMERCIAL_DATA, "")
            setCommercialData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getOtherData = async () => {
        try {
            // const response: any = await axios.get(
            //     "http://localhost:5100/report/getOtherData"
            // );
            const response: any = await getRequest(GET_OTHER_DATA, "")
            setOtherData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // GET ALL OWNERNAME
    const getOwner = async () => {
        try {
            // const response: any = await getRequest(GET_OWNER_DATA_FOR_CATTLE, "")
            const response: any = await getRequest(GET_AUTOMOBILE_LIST, "")
            const uniqueOwners: any[] = [];
            const ownerMap: Map<string, Set<string>> = new Map(); // Map to store owner names and unique mobile numbers

            response.data.forEach((item: any) => {
                const { ownerName, mobileNumber, formId } = item;
                if (!ownerMap.has(ownerName)) {
                    ownerMap.set(ownerName, new Set([mobileNumber]));
                    uniqueOwners.push({ ownerName, mobileNumber, formId });
                } else {
                    const aadharSet = ownerMap.get(ownerName);
                    if (!aadharSet?.has(mobileNumber)) {
                        aadharSet?.add(mobileNumber);
                        uniqueOwners.push({ ownerName, mobileNumber, formId });
                    }
                }
            });

            setOwner(uniqueOwners);
        } catch (error) {
            console.error(error);
        }
    }

    const onOwnerChange = (e: any, value: any) => {
        setSelectedOwner(value);
        setImageDataURI([]);
    }

    // GET DATA OF CATTLES USING OWNERNAME and ADHAR Number
    const getDetailsByOwnerName = async () => {
        try {
            const response: any = await getRequestByParams(GET_AUTOMOBILE_DETAILS_BY_OWNERNAME, "", "", selectedOwner)
            setAutoData(response.data)
            setType(response.data.automobileDetails[0].vehicleType)
            let id = selectedOwner.formId;
            setFormId(id);
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    };
    const fetchImage = async (formId: any) => {
        try {
            setLoading(true)
            // const response: any = await getRequestByParams(GET_IMAGE_FROM_S3_BUCKET, formId, "", "")
            const response: any = await getRequestByParams(GET_IMAGE_FOR_AUTOMOBILE_FROM_S3_BUCKET, formId, "", "")
            setImageDataURI((prevData) => [...prevData, ...response.data]);
            if (response.data.length === 0) {
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };


    twoWheelerData.forEach((row: any) => {
        const mappedRow = [
            [row.handleBar, autoData?.TwoWheelerDetails?.[0]?.twhandleBar || '', '', row.fork, autoData?.TwoWheelerDetails?.[0]?.twfork || '', , ''],
            [row.lhShocker, autoData?.TwoWheelerDetails?.[0]?.twlhShocker || '', , '', row.engine, autoData?.TwoWheelerDetails?.[0]?.twengine || '', , ''],
            [row.rhShocker, autoData?.TwoWheelerDetails?.[0]?.twrhShocker || '', , '', row.lhCowl, autoData?.TwoWheelerDetails?.[0]?.twlhCowl || '', , ''],
            [row.indicators, autoData?.TwoWheelerDetails?.[0]?.twindicators || '', , '', row.rhCowl, autoData?.TwoWheelerDetails?.[0]?.twrhCowl || '', , ''],
            [row.mudGuard, autoData?.TwoWheelerDetails?.[0]?.twmudguard || '', , '', row.tailLight, autoData?.TwoWheelerDetails?.[0]?.twtailLight || '', , ''],
            [row.wheelRim, autoData?.TwoWheelerDetails?.[0]?.twwheelRim || '', , '', row.rearShocker, autoData?.TwoWheelerDetails?.[0]?.twrearShocker || '', , ''],
            [row.legGuard, autoData?.TwoWheelerDetails?.[0]?.twlegGuard || '', , '', row.rearMudguard, autoData?.TwoWheelerDetails?.[0]?.twrearMudguard || '', , ''],
            [row.chassis, autoData?.TwoWheelerDetails?.[0]?.twchassis || '', , '', row.remarks, autoData?.TwoWheelerDetails?.[0]?.twremarks || '', , ''],
        ];
        // Add the mapped row to the mapTwoWheelerData array
        mapTwoWheelerData.push(...mappedRow);
    });

    fourWheelerData.forEach((row: any) => {
        const mappedRow = [
            [row.frontBumper, autoData?.FourWheelerDetails?.[0]?.fwfrontBumper || '', '', row.lhRearDoor, autoData?.FourWheelerDetails?.[0]?.fwlhRearDoor || '', ''],
            [row.rhheadLight, autoData?.FourWheelerDetails?.[0]?.fwheadLight || '', '', row.lhQuarterPanel, autoData?.FourWheelerDetails?.[0]?.fwlhQuarterPanel || '', ''],
            [row.lhheadLight, autoData?.FourWheelerDetails?.[0]?.fwheadLight || '', '', row.dickey, autoData?.FourWheelerDetails?.[0]?.fwdickyDoor || '', ''],
            [row.bonnet, autoData?.FourWheelerDetails?.[0]?.fwbonet || '', '', row.rearBumper, autoData?.FourWheelerDetails?.[0]?.fwrearBumper || '', ''],
            [row.showGrill, autoData?.FourWheelerDetails?.[0]?.fwfrontGrill || '', '', row.rearGlass, autoData?.FourWheelerDetails?.[0]?.fwrearGlass || '', ''],
            [row.windSheildGlass, autoData?.FourWheelerDetails?.[0]?.fwrearGlass || '', '', row.lhTailLight, autoData?.FourWheelerDetails?.[0]?.fwlhTailLight || '', ''],
            [row.rhFender, autoData?.FourWheelerDetails?.[0]?.fwfender || '', '', row.rhTailLight, autoData?.FourWheelerDetails?.[0]?.fwrhTailLight || '', ''],
            [row.lhFender, autoData?.FourWheelerDetails?.[0]?.fwfender || '', '', row.engine, autoData?.FourWheelerDetails?.[0]?.fwengine || '', ''],
            [row.rhFrontDoor, autoData?.FourWheelerDetails?.[0]?.fwrhFrontDoor || '', '', row.dashboard, autoData?.FourWheelerDetails?.[0]?.fwdashboard || '', ''],
            [row.rhRearDoor, autoData?.FourWheelerDetails?.[0]?.fwrhRearDoor || '', '', row.underCarraige, autoData?.FourWheelerDetails?.[0]?.fwunderCarraige || '', ''],
            [row.rhQuarterPanel, autoData?.FourWheelerDetails?.[0]?.fwrhQuarterPanel || '', '', row.tyre4, autoData?.FourWheelerDetails?.[0]?.fwtyre4 || '', ''],
            [row.lhFrontDoor, autoData?.FourWheelerDetails?.[0]?.fwlhFrontDoor || '', '', row.remarks, autoData?.FourWheelerDetails?.[0]?.fwremarks || '', ''],
        ];

        // Add the mapped row to the mapTwoWheelerData array
        mapFourWheelerData.push(...mappedRow);

    });

    CommercialData.forEach((row: any) => {
        const mappedRow = [
            [row.frontBumper, autoData?.CommercialDetails?.[0]?.cmfrontBumper || '', '', row.rhTailLight, autoData?.CommercialDetails?.[0]?.cmrhTailLight || '', ''],
            [row.rhHeadLight, autoData?.CommercialDetails?.[0]?.cmrhHeadLight || '', '', row.lhTailLight, autoData?.CommercialDetails?.[0]?.cmlhTailLight || '', ''],
            [row.lhHeadLight, autoData?.CommercialDetails?.[0]?.cmlhHeadLight || '', '', row.chassis, autoData?.CommercialDetails?.[0]?.cmchassis || '', ''],
            [row.frontShow, autoData?.CommercialDetails?.[0]?.cmfrontShow || '', '', row.underCarriage, autoData?.CommercialDetails?.[0]?.cmunderCarriage || '', ''],
            [row.cabin, autoData?.CommercialDetails?.[0]?.cmcabin || '', '', row.engine, autoData?.CommercialDetails?.[0]?.cmengine || '', ''],
            [row.loadBody, autoData?.CommercialDetails?.[0]?.cmloadBody || '', '', row.dashboard, autoData?.CommercialDetails?.[0]?.cmdashboard || '', ''],
            [row.tyres, autoData?.CommercialDetails?.[0]?.cmtyres || '', '', row.remarks, autoData?.CommercialDetails?.[0]?.cmremarks || '', ''],
        ];

        // Add the mapped row to the mapTwoWheelerData array
        mapCommercialData.push(...mappedRow);
    });

    otherData.forEach((row: any) => {
        const mappedRow = [
            [row.frontBumper, autoData?.OtherDetails?.[0]?.otherfrontBumper || '', '', row.cabin, autoData?.OtherDetails?.[0]?.othercabin || '', ''],
            [row.cabinGlasses, autoData?.OtherDetails?.[0]?.othercabinGlasses || '', '', row.boom, autoData?.OtherDetails?.[0]?.otherboom || '', ''],
            [row.tyres, autoData?.OtherDetails?.[0]?.othertyres || '', '', row.engine, autoData?.OtherDetails?.[0]?.otherengine || '', ''],
            [row.hydraulicSystems, autoData?.OtherDetails?.[0]?.otherhydraulicSystems || '', '', row.remarks, autoData?.OtherDetails?.[0]?.otherremarks || '', ''],

        ];

        // Add the mapped row to the mapTwoWheelerData array
        mapOtherData.push(...mappedRow);
    });

    // format date
    const formatDate = (inputDate: any) => {
        if (!inputDate) {
            return "";
        }
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const year = date.getFullYear();

        // Pad single-digit day and month with a leading zero
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    const getHeaderFromUrl = (url: any) => {
        // Assuming the URL format is consistent, you can extract the header from the URL
        const parts = url.split('/');
        const header = parts[parts.length - 1].split('.')[0]; // Extract the part before the dot
        return header;
    }

    useEffect(() => {
        getTwoWheelerData();
        getFourWheelerData();
        getCommercialData();
        getOtherData();
    }, []);

    useEffect(() => {
        getOwner();
    }, []);

    useEffect(() => {
        if (selectedOwner) {
            setLoading(true);
            getDetailsByOwnerName();
        }
    }, [selectedOwner])



    useEffect(() => {
        if (FormId) {
            fetchImage(FormId)
        }
    }, [FormId])

    useEffect(() => {
        if (imageDataURI.length > 0) {
            setLoading(false)
        }
    }, [imageDataURI])

    return (
        <div style={{ width: "100%", height: "100vh" }}>

            <Autocomplete
                fullWidth
                sx={{ my: 3 }}
                disablePortal
                id="combo-box-demo"
                options={Owner}
                onChange={onOwnerChange}
                getOptionLabel={(option) => `${option.ownerName}- MobileNumber(${option.mobileNumber})`}
                renderInput={(params) => <TextField {...params} label="Please Select Owner Name" />}
            />
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} className="progress_circle">
                    <CircularProgress />
                </Box>
            )}
            {selectedOwner && autoData && (
                <PDFViewer style={{ width: "100%", height: "100%", color: "red" }}>
                    <>
                        <Document title={`${autoData?.automobileDetails?.[0]?.ownerName}_${new Date(Date.now()).toDateString()}`}>
                            <>
                                <Page size="A4" style={styles.page}>
                                    <View style={{ ...styles.para1 }}>
                                        <View style={{ ...styles.col1, ...styles.left, }}>
                                            <Text
                                                style={{ ...styles.text2, ...styles.boldText }}
                                            >
                                                ASSOCIATED RISK
                                            </Text>
                                            <Text
                                                style={{ ...styles.text2, ...styles.boldText1 }}
                                            >
                                                MANAGEMENT SERVICES
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.col2, ...styles.left }}>
                                            {/* contact icon */}
                                            <Text style={{ ...styles.text2, margin: 1 }}>
                                                <Text style={{ ...styles.icon }}>&#xf095;</Text>
                                                {"    "}+91 9175012125
                                            </Text>
                                            {/*  mail icon*/}
                                            <Text style={{ ...styles.text2, margin: 1 }}>
                                                <Text style={{ ...styles.icon }}>&#xf0e0;</Text>
                                                {"    "}Email: example@example.com
                                            </Text>
                                            {/* Location Icon */}
                                            <Text style={{ ...styles.text2, margin: 1 }}>
                                                <Text style={{ ...styles.icon }}>&#xf041;</Text>
                                                {"    "}Office Number 4, Buena Vista, Plot Number 58, Viman Nagar, Pune,     Maharashtra 411014
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={{ ...styles.divider3, marginHorizontal: 10, }} />

                                    <View style={{ ...styles.para1, ...styles.center, padding: 3 }}>
                                        <Text style={{ ...styles.boldText1 }}>Inspection Report</Text>
                                    </View>

                                    <View style={styles.para1}>
                                        <View style={{ width: "16%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Insurance Co. & Branch:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "44%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.insuranceCompany ? 0 : 5 }} ><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.insuranceCompany}</Text></View>
                                        </View>
                                        <View style={{ width: "8%", }}>
                                            <Text style={styles.text2}>Alloted by: </Text>
                                        </View>
                                        <View
                                            style={{ width: "32%", }}
                                        >
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.inspectionAgency ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.inspectionAgency}</Text></View>
                                        </View>
                                    </View>
                                    <View style={styles.para1}>
                                        <View style={{ width: "15%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Name Of Reg. Owner:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "85%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.ownerName ? 0 : 5 }} ><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.ownerName}</Text></View>
                                        </View>
                                    </View>
                                    <View style={styles.para1}>
                                        <View style={{ width: "7%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Address:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "93%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.permanentAddress ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.permanentAddress}</Text></View>
                                        </View>
                                    </View>
                                    <View style={styles.para1}>
                                        <View style={{ width: "9%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Vehicle  No.:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "21%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.vehicleNumber ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.vehicleNumber}</Text></View>
                                        </View>
                                        <View style={{ width: "4%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Make:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "21%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.vehicleCompany ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.vehicleCompany}</Text></View>
                                        </View>
                                        <View style={{ width: "6%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Variant:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "19%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.vehicleType ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.vehicleType}</Text></View>
                                        </View>
                                        <View style={{ width: "6%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Model:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "14%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.vehicleModel ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.vehicleModel}</Text></View>
                                        </View>
                                    </View>

                                    <View style={styles.para1}>
                                        <View style={{ width: "9%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Chassis No.:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "41%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.chassisNumber ? 0 : 5 }} ><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.chassisNumber}</Text></View>
                                        </View>
                                        <View style={{ width: "9%", }}>
                                            <Text style={styles.text2}>Engine No.: </Text>
                                        </View>
                                        <View
                                            style={{ width: "41%", }}
                                        >
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.engineNumber ? 0 : 5 }} ><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.engineNumber}</Text></View>
                                        </View>
                                    </View>

                                    <View style={styles.para1}>
                                        <View style={{ width: "15%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Date of Registration:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "35%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.registrationDate ? 0 : 5 }}><Text style={styles.text2}>{formatDate(autoData?.automobileDetails?.[0]?.registrationDate)}</Text></View>
                                        </View>
                                        <View style={{ width: "14%", }}>
                                            <Text style={styles.text2}>Odometer Reading: </Text>
                                        </View>
                                        <View
                                            style={{ width: "16%", }}
                                        >
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.odometerReading ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.odometerReading}</Text></View>
                                        </View>
                                        <View style={{ width: "5%", }}>
                                            <Text style={styles.text2}>Colour: </Text>
                                        </View>
                                        <View
                                            style={{ width: "15%", }}
                                        >
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.color ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.color}</Text></View>
                                        </View>
                                    </View>

                                    <View style={styles.para1}>
                                        <View style={{ width: "10%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Inspected by:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "20%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.inspectionAgency ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.inspectionAgency}</Text></View>
                                        </View>
                                        <View style={{ width: "4%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Date:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "21%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.date ? 0 : 5 }}><Text style={styles.text2}>
                                                {/* {autoData?.automobileDetails?.[0]?.date} */}
                                                {formatDate(autoData?.automobileDetails?.[0]?.date)}
                                            </Text></View>
                                        </View>
                                        <View style={{ width: "6%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Time:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "19%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.time ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.time}</Text></View>
                                        </View>
                                        <View style={{ width: "5%", }}>
                                            <Text style={styles.text2}>
                                                <Text style={styles.text2}>Place:</Text>
                                            </Text>
                                        </View>
                                        <View style={{ width: "15%", }}>
                                            <View style={{ ...styles.divider3, marginTop: autoData?.automobileDetails?.[0]?.permanentAddress ? 0 : 5 }}><Text style={styles.text2}>{autoData?.automobileDetails?.[0]?.permanentAddress}</Text></View>
                                        </View>
                                    </View>


                                    <View style={{ ...styles.para1, marginTop: 3 }}>
                                        <Text style={{ ...styles.text1, marginRight: 10 }}>Type:</Text>
                                        <Text style={{ ...styles.squareBox, backgroundColor: type === 'twowheeler' ? 'black' : 'transparent' }}>
                                        </Text>
                                        <Text style={{ ...styles.text2, marginRight: 20 }}>2-Wheeler</Text>

                                        <Text style={{ ...styles.squareBox, backgroundColor: type === 'fourwheeler' ? 'black' : 'transparent' }}>
                                        </Text>
                                        <Text style={{ ...styles.text2, marginRight: 20 }}>4-Wheeler</Text>

                                        <Text style={{ ...styles.squareBox, backgroundColor: type === 'commercial' ? 'black' : 'transparent' }}>
                                        </Text>
                                        <Text style={{ ...styles.text2, marginRight: 20 }}>Commercial</Text>

                                        <Text style={{ ...styles.squareBox, backgroundColor: type === 'others' ? 'black' : 'transparent' }}>
                                        </Text>
                                        <Text style={{ ...styles.text2 }}>Other</Text>
                                    </View>

                                    {type === "twowheeler" && (
                                        <>
                                            <View style={{ ...styles.table, borderTopWidth: 1, borderRightWidth: 1, marginTop: 3 }}>
                                                {tableHeaders1.map((header: any, index: any) => (
                                                    <View key={index} style={styles.tableCell}>
                                                        <Text style={{ ...styles.text2, ...styles.boldText3 }}>{header}</Text>
                                                    </View>
                                                ))}
                                            </View>
                                            {mapTwoWheelerData.map((row: any, rowIndex: any) => (
                                                <View key={rowIndex} style={{ ...styles.table, borderRightWidth: 1 }}>
                                                    {row.map((cell: any, cellIndex: any) => (
                                                        <View style={styles.tableCell}>
                                                            <Text style={styles.text2}>{cell}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            ))}
                                        </>
                                    )}


                                    {type === "fourwheeler" && (
                                        <>
                                            <View style={{ ...styles.table, borderRightWidth: 1, borderTopWidth: 1, marginTop: 3 }}>
                                                {tableHeaders1.map((header, index) => (
                                                    <View key={index} style={styles.tableCell}>
                                                        <Text style={{ ...styles.text2, ...styles.boldText3 }}>{header}</Text>
                                                    </View>
                                                ))}
                                            </View>
                                            {mapFourWheelerData.map((row: any, rowIndex: any) => (
                                                <View key={rowIndex} style={{ ...styles.table, borderRightWidth: 1 }}>
                                                    {row.map((cell: any, cellIndex: any) => (
                                                        <View key={cellIndex} style={styles.tableCell}>
                                                            <Text style={styles.text2}>{cell}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            ))}
                                        </>
                                    )}

                                    {type === "commercial" && (
                                        <>
                                            <View style={{ ...styles.table, borderRightWidth: 1, borderTopWidth: 1, marginTop: 3 }}>
                                                {tableHeaders1.map((header, index) => (
                                                    <View key={index} style={styles.tableCell}>
                                                        <Text style={{ ...styles.text2, ...styles.boldText3 }}>{header}</Text>
                                                    </View>
                                                ))}
                                            </View>
                                            {mapCommercialData.map((row: any, rowIndex: any) => (
                                                <View key={rowIndex} style={{ ...styles.table, borderRightWidth: 1 }}>
                                                    {row.map((cell: any, cellIndex: any) => (
                                                        <View key={cellIndex} style={styles.tableCell}>
                                                            <Text style={styles.text2}>{cell}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            ))}
                                        </>
                                    )}

                                    {type === "others" && (
                                        <>
                                            <View style={{ ...styles.table, borderRightWidth: 1, borderTopWidth: 1, marginTop: 3 }}>
                                                {tableHeaders1.map((header, index) => (
                                                    <View key={index} style={styles.tableCell}>
                                                        <Text style={{ ...styles.text2, ...styles.boldText3 }}>{header}</Text>
                                                    </View>
                                                ))}
                                            </View>
                                            {mapOtherData.map((row: any, rowIndex: any) => (
                                                <View key={rowIndex} style={{ ...styles.table, borderRightWidth: 1 }}>
                                                    {row.map((cell: any, cellIndex: any) => (
                                                        <View key={cellIndex} style={styles.tableCell}>
                                                            <Text style={styles.text2}>{cell}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            ))}
                                        </>
                                    )}


                                    <View style={{ ...styles.para1, marginTop: 7 }}>

                                        <View style={{ width: "50%", ...styles.para1, }}>
                                            <View style={{ width: "15%", }}>
                                                <Text style={styles.text2}>
                                                    <Text style={styles.text2}>Remarks:</Text>
                                                </Text>
                                            </View>
                                            <View style={{ width: "85%", }}>
                                                <View style={{ ...styles.divider3, marginTop: 7, marginHorizontal: 5 }} />
                                            </View>

                                            <View style={{ width: "100%" }}>
                                                <View style={{ ...styles.divider3, marginTop: 7, marginRight: 5 }} />
                                                <View style={{ ...styles.divider3, marginTop: 7, marginRight: 5 }} />
                                                <View style={{ ...styles.divider3, marginTop: 7, marginRight: 5 }} />
                                            </View>
                                        </View>

                                        <View style={{ width: "50%" }}>
                                            <View style={{ ...styles.squareBox1 }}>
                                                <Text style={{ ...styles.boldText2, padding: 3 }}>RECOMMENDED FOR INSURANCE</Text>
                                                <View style={{ ...styles.para5 }}>
                                                    {autoData?.automobileDetails?.[0]?.recommandationForInsurance === "accepted" ? (
                                                        <>
                                                            <View style={{ ...styles.squareBox2 }}>
                                                                <Text style={{ ...styles.boldText2, textAlign: "center", marginTop: 3 }}>YES</Text>
                                                            </View>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <View style={{ ...styles.squareBox2, }}>
                                                                <Text style={{ ...styles.boldText2, textAlign: "center", marginTop: 3 }}>NO</Text>
                                                            </View>
                                                        </>
                                                    )}

                                                </View>
                                            </View>


                                        </View>

                                    </View>

                                    <View style={{ ...styles.para1, marginTop: 2 }}>
                                        <View style={{ width: "50%" }}> </View>
                                        <View style={{ width: "50%" }}> <Text style={{ ...styles.boldText2, marginLeft: 30 }}>For Associated Risk Management Service</Text></View>
                                    </View>

                                    <View style={{ ...styles.para1 }}>
                                        <View style={{ width: "100%" }}> <Text style={{ ...styles.boldText2 }}>Declaration of Owner({autoData?.automobileDetails?.[0]?.ownerName})</Text></View>
                                    </View>
                                    <View style={{ ...styles.para1, marginTop: 2 }}>
                                        <Text style={{ ...styles.text3 }}>I hereby confirm and declare that above mentioned identification details of my vehicle No. {autoData?.automobileDetails?.[0]?.vehicleNumber}    as well as that of damage to the vehicle as noted by inspecting officials is correct. Nothing has been hidden/undisclosed. I further confirm & declare that the Motor vehicle proposed for insurance after a break in cver has not met with any accident giving rise to any person or damages to any property vehicle during the period followingthe expiry of a previous insurance,till the moment it is proposed for insurance. I also agree that the damages mentioned above shall be excluded in the event of any claim being lodge.</Text>
                                    </View>

                                    <View style={{ ...styles.para1, marginTop: 30 }}>
                                        <Text style={{ ...styles.boldText2 }}>Name and sign of Vehicle Inspector  </Text>
                                        <Text style={{ ...styles.boldText2 }}>--------------------------------------------------------------------------------</Text>
                                    </View>

                                </Page>
                                {imageDataURI && imageDataURI.length > 0 && (
                                    <>
                                        <Page size="A4" style={styles.page}>
                                            <>
                                                <View style={styles.para1}>
                                                    {imageDataURI.map((imgData, index) => {
                                                        console.log("ContentType:", imgData.contentType); // Log the contentType
                                                        if (imgData.contentType === "image") {
                                                            // Start a new row
                                                            const header = getHeaderFromUrl(imgData.url);
                                                            // const nextHeader = index + 1 < imageDataURI.length && imageDataURI[index + 1].contentType === "image" ? getHeaderFromUrl(imageDataURI[index + 1].url) : '';
                                                            // return (
                                                            //     <View style={styles.tableRow1} key={index}>
                                                            //         <View style={{ ...styles.column4 }}>
                                                            //             <Text style={styles.boldText1}>{header}</Text>
                                                            //             <Image src={imgData.imageDataURI} style={{ ...styles.image, margin: 10, }} />
                                                            //         </View>
                                                            //         {index + 1 < imageDataURI.length && (
                                                            //             <View style={{ ...styles.column4 }}>
                                                            //                 <Text style={styles.boldText1}>{nextHeader}</Text>
                                                            //                 <Image src={imageDataURI[index + 1].imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                                            //             </View>
                                                            //         )}
                                                            //     </View>
                                                            // );
                                                            return (
                                                                <View style={styles.tableRow1} key={index}>
                                                                    <View style={{ ...styles.column4 }}>
                                                                        <Text style={styles.boldText1}>{header}</Text>
                                                                        <Image src={imgData.imageDataURI} style={{ ...styles.image, margin: 10 }} />
                                                                    </View>
                                                                </View>
                                                            );
                                                        }
                                                        return null; // Continue to the next image
                                                    })}
                                                </View>
                                            </>
                                        </Page>
                                    </>
                                )}

                            </>
                        </Document>

                    </>
                </PDFViewer>
            )
            }
        </div >

    );
}



