import { updateOwnerData } from "../Actions/CommonAction";

export const OtherInitialState = {
  otherfrontBumper: '',
  othercabin: '',
  othercabinGlasses: '',
  otherboom: '',
  othertyres: '',
  otherengine: '',
  otherhydraulicSystems: '',
  otherremarks: '',
  othertaillight: '',
  otherrearshockabsorber: '',
  otherrearmudguard: '',
  otherrearwheelrim: '',
};

const OthersReducer = (state = OtherInitialState, action: any) => {
  switch (action.type) {
    case "UPDATE_OTHERS_DATA":
      return {
        ...state,
        otherfrontBumper: action.payload.otherfrontBumper,
        othercabin: action.payload.othercabin,
        othercabinGlasses: action.payload.othercabinGlasses,
        otherboom: action.payload.otherboom,
        othertyres: action.payload.othertyres,
        otherengine: action.payload.otherengine,
        otherhydraulicSystems: action.payload.otherhydraulicSystems,
        otherremarks: action.payload.otherremarks,
        othertaillight: action.payload.othertaillight,
        otherrearshockabsorber: action.payload.otherrearshockabsorber,
        otherrearmudguard: action.payload.otherrearmudguard,
        otherrearwheelrim: action.payload.otherrearwheelrim,

      };
    case "CLEAR_OTHER_DATA": return { ...OtherInitialState };
    default:
      return state;
  }
};

export default OthersReducer;
