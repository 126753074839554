import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Service from "./Service";
import About from "./AboutUs";
import Login from "./Login";
import Home from "./Home";
import "../CommonComponents/styles.css";
import Contact from "./Contact";
import Career from "./Career";
import Logo from "../../Assets/images/Logo.png";
import FooterComponent from "../CommonComponents/FooterComponent";
import HeaderComponent from "../CommonComponents/HeaderComponent";

import { Stack, Typography } from "@mui/material";
import {QRCodeCanvas} from 'qrcode.react';
import { useLocation } from "react-router-dom";

import Qrcodeview1 from "./QRCodeView1";


interface Page {
  name: string;
  active?: boolean;
}

const pages: Page[] = [
  { name: "Home", active: true },
  { name: "Service" },
  { name: "About" },
  { name: "Contact" },
  { name: "Career" },
];

function Layout() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Location = useLocation();

  return (
    <>
          {/* header navbar part*/}
            <HeaderComponent/>
            
      
      
      <Box id="Home" py={8}>
        <Home />
      </Box>
      <Box id="Service" py={8}>
        <Service />
      </Box>
      <Box id="About" py={8}>
        <About />
      </Box>
      <Box id="Login" py={8}>
        <Login />
      </Box>
      <Box id="Contact" py={8}>
        <Contact />
      </Box>
      <Box id="Career" py={8}>
        <Career />
      </Box>
       
      <Box id="Qrview" py={10}>
        
<Stack
  direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2}
>
<Qrcodeview1/>
      {/* <Typography sx={{m:"40px"}}>
        {Location?.state && <QRCodeCanvas value={Location?.state} size={340} />}
        
        </Typography> */}
        </Stack>
      </Box>
      
      
      
        {/* Footer  part*/}
        <FooterComponent/>
    </>
  );
}

export default Layout;
