import { updateOwnerData } from '../Actions/CommonAction'

export const OwnerInitialState = {
  ownerName: "",
  locationOfVehicle: "",
  permanentAddress: "",
  mobileNumber: "",
  emailID: "",
  jobType: "",
  inspectionAgency: "",
  salesChannel: "",
  color: "",
  bodyType: "",
  chassisNumber: "",
  vehicleCompany: "",
  fuelType: "",
  vehicleModel: "",
  engineNumber: "",
  odometerReading: "",
  vehicleCategory: "",
  vehicleClassDescription: "",
  vehicleType: "",
  permitType: "",
  company: "",
  vehicleNumber: "",
  ownerSerialNumber: "",
  insuranceCompany: "",
  fieldExecutive: "",
  fieldExecutiveEmail: ""
}

const OwnerAutoReducer = (state = OwnerInitialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_AUTO_OWNER_DATA':
      return {
        ...state,
        ownerName: action.payload.ownerName,
        locationOfVehicle: action.payload.locationOfVehicle,
        permanentAddress: action.payload.permanentAddress,
        mobileNumber: action.payload.mobileNumber,
        emailID: action.payload.emailID,
        jobType: action.payload.jobType,
        inspectionAgency: action.payload.inspectionAgency,
        salesChannel: action.payload.salesChannel,
        color: action.payload.color,
        // businessSector: action.payload.businessSector,
        bodyType: action.payload.bodyType,
        chassisNumber: action.payload.chassisNumber,
        vehicleCompany: action.payload.vehicleCompany,
        fuelType: action.payload.fuelType,
        vehicleModel: action.payload.vehicleModel,
        engineNumber: action.payload.engineNumber,
        odometerReading: action.payload.odometerReading,
        // Update the owner data with the payload
        vehicleCategory: action.payload.vehicleCategory,
        vehicleClassDescription: action.payload.vehicleClassDescription,
        vehicleType: action.payload.vehicleType,
        // permitType: action.payload.permitType,
        company: action.payload.company,
        vehicleNumber: action.payload. vehicleNumber,
        ownerSerialNumber: action.payload.ownerSerialNumber,
        insuranceCompany: action.payload.insuranceCompany,
        fieldExecutive: action.payload.fieldExecutive,
        fieldExecutiveEmail: action.payload.fieldExecutiveEmail
      }
    case 'RESET_OWNER_AUTO_DATA':
      return { ...OwnerInitialState }
    default:
      return state
  }
}

export default OwnerAutoReducer
