import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  Container,
  ImageList,
  ImageListItem,
} from "@mui/material";
import Home_Image from "../../Assets/Images/slider-img.png";

export default function Landing() {
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{ paddingY: 4 }}
        className="home_container1"
        component="div"
      >
        <Container maxWidth="lg" className="home_container2">
          <Box>
            <Grid container columnSpacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <ImageList className="home_imagelist" cols={1}>
                  <ImageListItem>
                    <img
                      src={Home_Image}
                      loading="lazy"
                      alt="About university"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={12} sm={12} md={6} marginTop={5}>
                <Stack
                  direction="row"
                  spacing={2}
                  className="home_stack"
                ></Stack>

                <Grid item xs={12} md={6} className="home_grid"></Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  );
}
