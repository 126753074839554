export const initialState = {
  nameOfThePerson: "",
  designation: "",
  signature: "",
  status: "Assigned",
  additionalInformation: ""
};

const AuthorisedReducer = (state = initialState, action?: any) => {
  switch (action.type) {
    case "Authorised_Update":
      return {
        ...state,
        nameOfThePerson: action.payload.nameOfThePerson,
        designation: action.payload.designation,
        signature: action.payload.signature,
        status: action.payload.status,
        additionalInformation: action.payload.additionalInformation
      };
    case "AUTHORISED_RESET":
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export default AuthorisedReducer;
