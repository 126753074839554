
export const CattleInitialState = {
  animalCategory: "",
  typeOfBreed: "",
  gender: "",
  age: "",
  AnimalDescription: "",
  purpose: "",
  marketValue: "",
  earTagNo: "",
  stabledAnimalAddress: "",
  veterinaryCertificate: "Yes",
  VeterinaryDoctorServices: "",
  Qualification: "",
  registrationNumber: "",
  doctorAddress: "",
  diseaseType: "",
  dateOfIntimation: "",
  previousHistory: "",
  previousClaimDate: "",
  dateOfInceptionofDisease: "",
  treatmentDetails: "",
  DateofDeath: "",
  timeOfDeath: "",
  dateOfPostmortem: "",
  timeOfPostmortem: "",
  causeOfDeath: "",
  VDID: "",
};

const cattleDataReducer = (state = [CattleInitialState], action?: any) => {
  switch (action.type) {
    case 'UPDATE_CATTLE_DATA':
      const updatedArray = [...state];
      updatedArray[action.index] = {
        ...state[action.index],
        animalCategory: action.payload.animalCategory,
        typeOfBreed: action.payload.typeOfBreed,
        gender: action.payload.gender,
        age: action.payload.age,
        AnimalDescription: action.payload.AnimalDescription,
        purpose: action.payload.purpose,
        marketValue: action.payload.marketValue,
        earTagNo: action.payload.earTagNo,
        stabledAnimalAddress: action.payload.stabledAnimalAddress,
        veterinaryCertificate: "Yes",
        VeterinaryDoctorServices: action.payload.VeterinaryDoctorServices,
        Qualification: action.payload.Qualification,
        registrationNumber: action.payload.registrationNumber,
        doctorAddress: action.payload.doctorAddress,
        diseaseType: action.payload.diseaseType,
        dateOfIntimation: action.payload.dateOfIntimation,
        previousHistory: action.payload.previousHistory,
        previousClaimDate: action.payload.previousClaimDate,
        dateOfInceptionofDisease: action.payload.dateOfInceptionofDisease,
        treatmentDetails: action.payload.treatmentDetails,
        DateofDeath: action.payload.DateofDeath,
        timeOfDeath: action.payload.timeOfDeath,
        dateOfPostmortem: action.payload.dateOfPostmortem,
        timeOfPostmortem: action.payload.timeOfPostmortem,
        causeOfDeath: action.payload.causeOfDeath,
        VDID: action.payload.VDID,
      };
      return updatedArray;

    case 'RESET_CATTLE_DATA':
      return [CattleInitialState];

    default:
      return state;
  }
};

export default cattleDataReducer;
