import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import {
  Badge,
  Menu,
  MenuItem,
  Popover,
  Paper,
  ImageListItem,
  Collapse,
  Grid,
  Container,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { AccountCircle } from "@mui/icons-material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import GroupsIcon from "@mui/icons-material/Groups";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/Logo.png";
import CategoryIcon from "@mui/icons-material/Category";
import { useDispatch, useSelector } from "react-redux";
import LoginReducer from "../../Redux/Reducer/LoginReducer";
import PetsIcon from "@mui/icons-material/Pets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import bmw from "../../Assets/Images/bmw.png";
import cow from "../../Assets/Images/cow.png";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Mainlayout(props: any) {
  // const headerTitle = useSelector(state: any);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatchLogout = useDispatch();

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("usertype");
    dispatchLogout({ type: "LOGOUT" })
    navigate("/");
    window.scrollTo(0, 0);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState(null); // Add state for the dropdown anchor

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isDropdownOpen = Boolean(dropdownAnchorEl); // Check if dropdown is open
  //const [isCollapseOpen, setCollapseOpen] = React.useState(false);

  const initialCollapseState = Array(3).fill(false); // numOfTables is the number of collapse tables
  const [isCollapseOpen, setCollapseOpen] =
    React.useState(initialCollapseState);

  const openCollapseTable = (index: any) => {
    const updatedCollapseState = [...isCollapseOpen];
    updatedCollapseState[index] = !updatedCollapseState[index];
    setCollapseOpen(updatedCollapseState);
  };

  const [header, setHeader] = React.useState("");
  function ListItemHandle(e: any) {
    setHeader(e);
  }
  // const handleCollapseToggle = () => {
  //   // Toggle the collapse state when the "Service Category" item is clicked
  //   setCollapseOpen(!isCollapseOpen);
  //   navigat("/dashboard")
  // };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDropdownOpen = (event: any) => {
    setDropdownAnchorEl(event.currentTarget); // Set the anchor for the dropdown when "Dropdown" is clicked
  };

  const handleDropdownClose = () => {
    setDropdownAnchorEl(null); // Close the dropdown when needed
  };

  const navigat = useNavigate();

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <Typography>Messages</Typography>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Typography>Profile</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Container>
      <Grid sm={12} lg={12}>
        <Box sx={{ display: "flex", overflowX: "hidden" }}>
          <CssBaseline />
          <AppBar position="fixed" sx={{ backgroundColor: "#ffffff" }}>
            <Toolbar>
              <IconButton
                color="primary"
                //className="drawerbutton"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 2,
                  // ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>

              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: "white" }}
              >
                {/* {props.appswitch === "automobiles" ? "Automobiles" : "Cattles"} VIS */}

                <ImageListItem>
                  {/* sx={{height:"150px",width:"200px"}} */}
                  <img
                    src={Logo}
                    loading="lazy"
                    className="webLogo"
                    alt="image loading"
                  />
                </ImageListItem>
              </Typography>

              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: "black" }}
              >
                {header}
              </Typography>

              <Box
                sx={{ display: { xs: "none", md: "flex", color: "#1f6ab4" } }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ backgroundColor: "#2d83cc" }}>
              <IconButton
                size="large"
                edge="start"
                onClick={handleDrawerClose}
                aria-label="close drawer"
                sx={{
                  mr: 2,
                  color: "white",
                  ...(open && { display: "block" }),
                }}
              >
                {theme.direction === "rtl" ? <MenuIcon /> : <MenuIcon />}
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: "white" }}
              >
                <ImageListItem className="logo">
                  <img
                    src={Logo}
                    loading="lazy"
                    alt=""
                    style={{ height: "2px", width: "4%" }}
                  />
                </ImageListItem>
              </Typography>
            </DrawerHeader>

            {props.appswitch === "automobiles" ? (
              <List sx={{ backgroundColor: "#2d83cc", p: "1px" }}>
                {[
                  {
                    id: 1,
                    name: "Service Category",
                    icon: <CategoryIcon />,
                    //path: "/dashboard",
                    submenu: ["Cattle", "Automobiles"],
                    // icons:[<DirectionsCarIcon/> ,<PetsIcon/>],
                    icons: [
                      // <img src={bmw} alt="bmw" className="bmw" />,
                      <img src={cow} alt="cow" className="cow" />,
                      <DirectionsCarIcon sx={{ color: "#b71c1c" }} />,
                      <GroupAddIcon />,
                    ],
                    path: ["/dashboard/Cattle", "/dashboard/Automobiles"],
                  },
                  {
                    id: 2,
                    name: "Report",
                    icon: <LibraryBooksIcon />,
                    //path: "/dashboard",
                    submenu: [
                      <span className="smallText">
                        Cattle Fitness Certificate
                      </span>,
                      <span className="smallText">
                        Farmer's Package Certificate
                      </span>,
                      <span className="smallText">Agency Certificate</span>,
                    ],
                    icons: [
                      <PictureAsPdfIcon />,
                      <PictureAsPdfIcon />,
                      <PictureAsPdfIcon />,
                    ],
                    path: [
                      "/dashboard/CattleFitness",
                      "/dashboard/FarmerPackage",
                      "/dashboard/Agency",
                    ],
                  },
                  {
                    id: 3,
                    name: "Administration",
                    icon: <CategoryIcon />,
                    //path: "/dashboard",
                    submenu: ["Add Executive", "Veterinary Doctor"],
                    //  icons:[<DirectionsCarIcon/> ,<PetsIcon/>],
                    icons: [
                      <GroupAddIcon />, <LocalHospitalOutlinedIcon />
                    ],
                    path: ["/dashboard/Executive/Create","/dashboard/Veterinary/AddDrRegistrationList"],
                  }
                  // {
                  //   id: 3,
                  //   name: "Report",
                  //   icon: <LibraryBooksIcon />,
                  //   path: "/dashboard",
                  // }

                ].map((text, index) => (
                  <>
                    <div key={text.id}>
                      <ListItem
                        disablePadding
                        sx={{
                          display: "block",
                          backgroundColor: "#2d83cc",
                          color: "white",
                        }}
                        onClick={() => openCollapseTable(index)}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={(e) => {
                            handleDropdownOpen(e);
                            ListItemHandle("");
                          }} // Open the dropdown on click
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            {text.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={text.name}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                          {/* {text.name === "Service Category" ? (
                            <KeyboardArrowDownIcon />
                          ) : null} */}
                          {/* {text.name === "Report" ? ( */}
                          <KeyboardArrowDownIcon />
                          {/* ) :<KeyboardArrowDownIcon />} */}
                        </ListItemButton>
                      </ListItem>

                      {/* <ListItemButton className="button" > */}
                      <Collapse
                        className="CollapseMenu"
                        in={isCollapseOpen[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {text.submenu?.map((subMenu: any, index: any) => (
                          <div key={index}>
                            <ListItemButton
                              className="button"
                              onClick={() => {
                                navigat(text.path[index]);
                                if (text.name !== "Service Category")
                                  ListItemHandle(subMenu);
                                else
                                  ListItemHandle(
                                    subMenu + " " + "Inspection System"
                                  );
                              }}
                            >
                              {/* <ListItem disablePadding sx={{ paddingLeft: "60px" }}> */}

                              <ListItem
                                disablePadding
                                sx={{ paddingLeft: "11px" }}
                              >
                                {text.icons[index]}

                                <ListItemText
                                  sx={{ paddingLeft: "10px" }}
                                  primary={subMenu}
                                />
                              </ListItem>
                            </ListItemButton>
                          </div>
                        ))}
                      </Collapse>
                      {/* </ListItemButton> */}
                    </div>
                  </>
                ))}
              </List>
            ) : null}

            {/* newwwwwwwwwwwwwwwwwwwwww */}

            {props.appswitch === "cattles" ? (
              <List>
                {[
                  {
                    id: 1,
                    name: "Overview",
                    icon: <DirectionsCarIcon />,
                    path: "/dashboard",
                  },
                  {
                    id: 2,
                    name: "Cattles",
                    icon: <PetsIcon />,
                    path: "/dashboard/customerscattle",
                  },
                  {
                    id: 3,
                    name: "Services",
                    icon: <LibraryBooksIcon />,
                    path: "/dashboard/servicescattle",
                  },
                  {
                    id: 4,
                    name: "Reports",
                    icon: <LibraryBooksIcon />,
                    path: "/dashboard/reportscattle",
                  },
                  {
                    id: 5,
                    name: "Account",
                    icon: <LibraryBooksIcon />,
                    path: "/dashboard/accountcattle",
                  },
                ].map((text, index) => (
                  <ListItem
                    key={text.id}
                    disablePadding
                    sx={{
                      display: "block",
                      backgroundColor: "#7335b7",
                      color: "white",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => navigate(text.path)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={text.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : null}
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Outlet />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
