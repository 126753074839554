// REST API integration
// export const BASE_SERVER_URL = "http://www.finguardtech.com:5100";
// export const BASE_SERVER_URL = "http://localhost:5100";
export const BASE_SERVER_URL = "http://18.136.122.146:5100";
// export const BASE_SERVER_URL = "https://test.finguardtech.com:5100";

export const LOGIN = BASE_SERVER_URL + "/login/finguard";

// LEAD Module
export const GET_EXECUTIVE_LIST = BASE_SERVER_URL + "/executivelist";
export const DELETE_EXECUTIVE = BASE_SERVER_URL + "/executivelist/delete";
export const POST_EXECUTIVE = BASE_SERVER_URL + "/executivelist/post";
export const PUT_EXECUTIVE = BASE_SERVER_URL + "/executivelist/update";

// AUTOMOBILE Module
export const POST_AUTOMOBILE = BASE_SERVER_URL + "/automobile/post";

// Report module
export const GET_TWO_WHEELER_DATA =
  BASE_SERVER_URL + "/report/getTwoWheelerData";
export const GET_FOUR_WHEELER_DATA =
  BASE_SERVER_URL + "/report/getFourWheelerData";
export const GET_COMMERCIAL_DATA =
  BASE_SERVER_URL + "/report/getCommercialData";
export const GET_OTHER_DATA = BASE_SERVER_URL + "/report/getOtherData";
export const GET_OWNER_DATA_FOR_CATTLE = BASE_SERVER_URL + "/Cattles";
export const GET_CATTLE_BY_OWNER_NAME =
  BASE_SERVER_URL + "/report/getCattleDetails";
export const GET_CATTLE_LIST = BASE_SERVER_URL + "/Cattles";
export const GET_VETERNARY_LIST = BASE_SERVER_URL + "/Cattles/veternaryList";
export const FARMER_PACKAGE_REPORT =
  BASE_SERVER_URL + "/report/getSecPdfReport";
export const CATTLE_POST_CATTLE = BASE_SERVER_URL + "/Cattles/postCattle";
export const CATTLE_EDIT_CATTLE = BASE_SERVER_URL + "/Cattles/editCattle";
export const UPLOAD_SINGLE_IMAGE_ON_S3 =
  BASE_SERVER_URL + "/Cattles/upload-image";
export const UPLOAD_MULTIPLE_IMAGE_ON_S3 =
  BASE_SERVER_URL + "/Cattles/uploadMultipleImageWithFormId";
export const GET_IMAGE_FROM_S3_BUCKET =
  BASE_SERVER_URL + "/Cattles/getMultipleImageByS3Bucket";
export const GET_IMAGE_FOR_AUTOMOBILE_FROM_S3_BUCKET =
  BASE_SERVER_URL + "/Cattles/getMultipleImageofAutomobileByS3Bucket";
export const GET_IMAGE_BY_VDID = BASE_SERVER_URL + "/Cattles/getImageByVDID";
export const GET_VETERNARY_DOCTOR_BY_VDID =
  BASE_SERVER_URL + "/Cattles/getVeternaryDrByVDID";

// dr Module
export const GET_DRREGISTRATION_LIST =
  BASE_SERVER_URL + "/veterinaryDr/getDrData";
export const DELETE_DRREGISTRATION_LIST =
  BASE_SERVER_URL + "/veterinaryDr/delete";
export const PUT_DRREGISTRATION = BASE_SERVER_URL + "/veterinaryDr/update";
export const POST_DRREGISTRATION = BASE_SERVER_URL + "/veterinaryDr/postDrData";

export const POST_MULTIPLE_CATTLE =
  BASE_SERVER_URL + "/Cattles/postMultipleCattle";
export const GET_MULTIPLE_CATTLE_LIST =
  BASE_SERVER_URL + "/Cattles/getMultipleCattles";
export const EDIT_MULTIPLE_CATTLE =
  BASE_SERVER_URL + "/Cattles/editMultipleCattle";
export const GET_MULTIPLE_CATTLE_BY_OWNER_NAME =
  BASE_SERVER_URL + "/report/getmultipleCattleByOwnerName";
export const UPLOAD_IMAGES_FOR_MULTIPLE_CATTLE =
  BASE_SERVER_URL + "/Cattles/uploadImagesForMultipleCattle";
export const GET_AUTOMOBILE_LIST = BASE_SERVER_URL + "/automobile";
export const EDIT_AUTOMOBILE_LEAD =
  BASE_SERVER_URL + "/automobile/editAutomobile";
export const GET_AUTOMOBILE_DETAILS_BY_OWNERNAME =
  BASE_SERVER_URL + "/report/getAutomobileDetailsByOwnerName";
export const GET_IMAGE_BY_FORMID =
  BASE_SERVER_URL + "/automobile/getImageByFormId";
