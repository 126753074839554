import React, { useState, ChangeEvent, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Input from '@mui/material/Input';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRequestByParams } from '../../../Api/ApiEndpointconfiguration';
import { GET_IMAGE_FOR_AUTOMOBILE_FROM_S3_BUCKET, GET_IMAGE_FROM_S3_BUCKET } from '../../../Api/ServerConfiguration';



const Image: React.FC = () => {
  const VehicleDetail = useSelector((state: any) => state);

  const bodyPart: string[] = [
    "Front Side",
    "Back Side",
    "Left Side",
    "Right Side",
    "Chasis Number",
    "Odometer",
    "Engine Room",
    "Front Side under carriage",
    "RC Book",
    "Driving License",
    "Dashboard",
    "Damage1",
    "Damage2",
    "Damage3",
    "Damage4",
    "Video Upload "
  ];

  const allApplicationData = useSelector((state: any) => state);
  const previousAuthoriseData = useSelector((state: any) => state.AuthorisedReducer);
  let cattleDetails = allApplicationData.CattleReducer;
  const dispatchAllImages = useDispatch();
  // let conversionDetails = JSON.parse(allApplicationData?.AuthorisedReducer?.additionalInformation)
  const [imageURLs, setImageURLs] = useState<string[]>(Array(6).fill(""));
  const [formInformation, setformInformation] = useState<any>({});
  const [imageDataURI, setImageDataURI] = useState<any[]>([]);
  const location: any = useLocation();
  const [loader, setLoader] = useState(true)
  const [manualUpload, setManualUpload] = useState(false);
  const [pdfUpload, setPdfUpload] = useState(false);
  const [allImage, setAllImage] = useState<any[]>([]);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [cattle, setCattle] = useState<any[]>([]);
  // const [accordionImages, setAccordionImages] = useState<{ [key: string]: any[] }>({});
  const [accordionImages, setAccordionImages] = useState<{ [key: string]: any[] }>({});
  const [dataURIs, setDataURIs] = useState<string[]>([]);
  const fetchImage = async (formId: any) => {
    try {
      // Use the correct endpoint and parameters in the request
      // const response: any = await getRequestByParams(GET_IMAGE_FROM_S3_BUCKET, formId, "", "");
      const response: any = await getRequestByParams(GET_IMAGE_FOR_AUTOMOBILE_FROM_S3_BUCKET, formId, "", "");
      // Assuming the response is an array of images
      setImageDataURI(response.data);
      setLoader(false)
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally { 
      setLoader(false);
    }

  };

  const handleImageUpload = (index: number, imageURL: string) => {
    const newImageURLs = [...imageURLs];
    newImageURLs[index] = imageURL;
    setImageURLs(newImageURLs);
  };

  useEffect(() => {
    if (location?.state?.status) {
      setLoader(true);
      fetchImage(location?.state?.status);
    }
    return () => { };
  }, [location]);

  useEffect(() => {
    if (imageDataURI.length > 0) {
      dispatchAllImages({
        type: "Authorised_Update",
        payload: {
          status: "Completed"
        }
      })
    }
  }, [imageDataURI])

  const getAutoImageName = (url: any) => {
    // Define a regular expression to extract the cattle name from the URL path
    const regex = /\/automobile(\d+)\//;
    const match = url.split("/");
    const splitDot = match[match.length - 1].split(".")

    // Check if a match is found and return the cattle name (or a default value)
    return match ? `${splitDot[0]}` : 'Unknown Images';
  };
  return (
    <>
      <Grid container sx={{ justifyContent: "center" }}>
        {location.state === null ? (
          null
        ) : (
          <>
            {loader && imageDataURI && imageDataURI.length === 0 && (
              <CircularProgress
                style={{ display: "flex", alignItems: "center" }}
              />
            )}
          </>
        )}

      </Grid>
      <Grid container spacing={2}>
        {imageDataURI.map((imageURL, index) => (
          <Grid item xs={12} sm={8} md={8} lg={4} key={index}>
            <Card>
              {imageURL?.contentType === 'pdf' ? ( // Only render CardHeader if content type is not 'pdf'
                <CardHeader
                  title={
                    <Typography variant="h6" style={{ fontFamily: "YourFontFamily" }}>

                    </Typography>
                  }
                />
              ) : (
                <CardHeader
                  title={<Typography variant="h6">{getAutoImageName(imageURL.url)}</Typography>}
                />
              )}
              {imageURL?.contentType === 'image' ? ( // If it's an image
                <CardMedia component="img" height={"200px"} width={"200px"} image={imageURL.imageDataURI || ""}
                  onClick={() => {
                    window.open(imageURL.url);
                  }}
                />
              ) : imageURL?.contentType === 'video' ? ( // If it's a video
                <CardMedia component="video" height={"200px"} width={"200px"} controls
                  onClick={() => {
                    window.open(imageURL.url);
                  }}>
                  <source src={imageURL.imageDataURI} type="video/mp4" />
                </CardMedia>
              ) : imageURL?.contentType === "pdf" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mx: 12, px: 2, my: 8 }}
                    onClick={() => {
                      window.open(imageURL.url);
                    }}

                  >
                    Download PDF
                  </Button>
                </>
              ) : (
                <div>Error: Unsupported media type</div>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Image;
