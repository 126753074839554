import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Stack,
  Container,
  ImageList,
  ImageListItem,
  Alert,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Aboutus_Image from "../../Assets/Images/about-img.png";
import { useNavigate } from "react-router-dom";
import { WindowSharp } from "@mui/icons-material";
import useValidation from "../Validation/useValidation";
import axios, { AxiosError } from "axios";
import CommonSnackBar from "../../Components/CommonComponents/CommonSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { postrequestMethod } from "../../Api/ApiMethods";
import { LOGIN } from "../../Api/ServerConfiguration";
export default function Login() {
  const { eventHandler } = useValidation();
  const [authorization, setAuthorization] = useState<any | null>(null);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  // const getFormStateData = useSelector((state: any) => state.TokenReducer);
  const dispatchToken = useDispatch();
  const dispatchLogin = useDispatch();
  const [loginData, setLogin] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onChangelogin = (e: any) => {
    setLogin({ ...loginData, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const matchPassword = async (e: any) => {
    e.preventDefault();
    try {
      await postrequestMethod(LOGIN, "", loginData).then((response: any) =>{
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("usertype", response.data.user.usertype);
        // Handle a successful login
        const loginPayload = {
          login: true,
          isAuthenticated: true,
          userToken: response.data.token
        }
        if(response.data.user.usertype !== "Executive"){
          handleOpenSnackBar("Loging Successfully");
          dispatchLogin({type: "LOGIN", payload: loginPayload})
          navigate("/dashboard", {replace: true});
        }else{
          alert("Your are not authorised person to access web content");
        }
      } else if (response.status === 401) {
        // Invalid credentials
        navigate("/");
        alert("Wrong email or password");
      } else {
        console.error("Server error");
        // Handle other status codes if needed
      }
      }).catch((error: any)=>{
        setErrorState(true);
      })
    } catch(error){
      console.log(error)
    }
  };

  
  const validationHandler = async (e: any, alterName: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => setError({ ...error, [e.target.name]: res }));
    }
    setLogin({ ...loginData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (authorization) {
      dispatchToken({ type: "Token_Update", payload: authorization });
    }
    return () => {};
  }, [authorization]);
  return (
    <>
      <Container maxWidth="xl" className="login_container_outer">
        <Container maxWidth="lg" className="login_container_inner">
          <Box>
            <Grid container columnSpacing={10}>
              <Grid item xs={12} sm={12} md={6}>
                <ImageList className="login_imagelist" cols={1}>
                  <ImageListItem>
                    <img
                      src={Aboutus_Image}
                      loading="lazy"
                      alt="About university"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={12} sm={12} md={6} marginTop={5}>
                <Box className="login_box1">
                  <CommonSnackBar
                    open={openSnackBar}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackBar}
                    message={snackMessage}
                  />
                  <Avatar sx={{ m: 1, bgcolor: "#2d83cc" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Login
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={matchPassword}
                    // onSubmit={handleSubmit}
                    className="login_box2"
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={Boolean(error.email)}
                      helperText={error.email}
                      onChange={(e: any) => {
                        validationHandler(e, "email");
                        onChangelogin(e);
                      }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      //onChange={onChangelogin}
                      error={Boolean(error.password)}
                      helperText={error.password}
                      onChange={(e: any) => {
                        validationHandler(e, "password");
                        onChangelogin(e);
                      }}
                    />
                     {errorState ? <><Typography component="h1" variant="h5">{errorMsg}</Typography></> : null }
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="login_box2_button"
                    >
                      Login
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  );
}
