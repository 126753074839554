import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TablePagination,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GET_CATTLE_LIST, GET_EXECUTIVE_LIST, GET_IMAGE_BY_VDID, GET_MULTIPLE_CATTLE_LIST } from "../../Api/ServerConfiguration";
import { getRequest, getRequestByParams } from "../../Api/ApiEndpointconfiguration";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import { worksheetcolumns } from "../../Api/HeaderApi";
function List() {

  const [page, setPage] = useState(0);
  const [filterVal, setFilterVal] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredCount, setFilteredCount] = useState(0);
  const [multipleUser, setMultipleUsers] = useState<any[]>([]);
  const [filterMultipleUsers, setFilterMultipleUsers] = useState<any[]>([]);
  const [allInsuranceCompany, setAllInsuranceCompany] = useState<any>([]);
  const [insuranceCompany, setInsuranceCompany] = React.useState("");
  const [image, setImage] = useState<any>("")
  const navigate = useNavigate();
  const dispatchOwner = useDispatch();
  const getFormStateDatainlist = useSelector((state: any) => state);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    try {
      return <Slide direction="up" ref={ref} {...props} />;
    } catch (error) {
      console.error("Error in Transition component:", error);
      return null;
    }
  });

  const navgationHandler = () => {
    dispatchOwner({ type: "RESET_OWNER_DATA" });
    dispatchOwner({ type: "RESET_CATTLE_DATA" });
    dispatchOwner({ type: "AUTHORISED_RESET" });
    navigate("/dashboard/Cattle/Create");
  };

  const navigationEditHandler = async (item: any) => {
    const cattleDetailsString = item.cattleDetails;
    // const cattleDetailsArray = JSON.parse(cattleDetailsString);
    const alldata = JSON.parse(cattleDetailsString);

    if (item) {
      dispatchOwner({
        type: "UPDATE_OWNER_DATA",
        payload: item,
      });

      alldata.forEach((cattleDetail: any, index: any) => {
        // Dispatch each cattle detail using your action type and payload
        dispatchOwner({
          type: 'UPDATE_CATTLE_DATA',
          index: index, // Set the correct index here based on your use case
          payload: cattleDetail,
        });
      });

      try {
        const VDID = alldata[0].VDID;
        if (VDID === null || VDID === undefined || VDID === "") {
          console.log("NO VDID FOUND");
        } else {
          const response: any = await getRequestByParams(GET_IMAGE_BY_VDID, VDID, "", "");
          if (response !== undefined) {
            dispatchOwner({
              type: "Authorised_Update",
              payload: {
                ...item,
                signature: response.data, // Assuming `image` is the base64-encoded string of the image
              },

            });
          } else {
            dispatchOwner({
              type: "Authorised_Update",
              payload: {
                ...item,
                signature: "",
              },
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    navigate("/dashboard/Cattle/Create", {
      state: { status: item.FormID },
    });
  };


  const loadMultipleUser = async () => {
    getRequest(GET_MULTIPLE_CATTLE_LIST, "")
      .then((res: any) => {
        if (res.data.length > 0) {

          const today = new Date();
          const lastSevenDays = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
          const filteredRecords = res.data.filter((record: any) => {
            const recordDate = new Date(record.created_date);
            return recordDate >= lastSevenDays && recordDate <= today;
          });
          const sortedFilteredRecords = filteredRecords.sort((a: any, b: any) => {
            // Sort records based on the created_date in descending order
            return new Date(b.created_date).getTime() - new Date(a.created_date).getTime();
          });
          setFilterMultipleUsers(sortedFilteredRecords); // Set sorted filtered records to filterMultipleUsers state
          console.log("cattle", res.data);
          setMultipleUsers(res.data);
          // setFilterMultipleUsers(res.data);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleMultipleFilter = (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue === "") {
      // If the search input is empty, display the whole list
      if (insuranceCompany === "") {
        // If no insurance company is selected, display all users
        const today = new Date();
        const lastSevenDays = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const filteredRecords = multipleUser.filter((record: any) => {
          const recordDate = new Date(record.created_date);
          return recordDate >= lastSevenDays && recordDate <= today;
        });
        const sortedFilteredRecords = filteredRecords.sort((a: any, b: any) => {
          // Sort records based on the created_date in descending order
          return new Date(b.created_date).getTime() - new Date(a.created_date).getTime();
        });
        setFilterMultipleUsers(sortedFilteredRecords);
        setFilteredCount(multipleUser.length);
      } else {
        // If an insurance company is selected, filter the data based on the insurance company
        const filterInsuranceCompany = multipleUser.filter(
          (item: any) => item.insuranceRepository === insuranceCompany
        );
        setFilterMultipleUsers(filterInsuranceCompany);
        setFilteredCount(filterInsuranceCompany.length);
      }
    } else {
      // If the search input is not empty, filter the data based on both search input and insurance company
      const filterResult = multipleUser.filter((item: any) => {
        return (
          (insuranceCompany === "" ||
            item.insuranceRepository === insuranceCompany) &&
          Object.values(item).some((value) => {
            if (typeof value === "string" || typeof value === "number") {
              const itemValue = String(value).toLowerCase();
              return itemValue.includes(searchValue);
            }
            return false;
          })
        );
      });

      setFilterMultipleUsers(filterResult);
      setFilteredCount(filterResult.length);
    }

    setFilterVal(e.target.value);
  };
  const filterMultipleUserData = () => {
    const filterInsuranceCompany = multipleUser.filter(
      (item: any) => item.insuranceRepository === insuranceCompany
    );
    setFilterMultipleUsers(filterInsuranceCompany);
    setFilteredCount(filterInsuranceCompany.length);
    setPage(0);
  };

  useEffect(() => {
    // Reset page to 0 if filtered results count is less than current page * rows per page
    if (filteredCount < (page + 1) * rowsPerPage) {
      setPage(0);
    }
  }, [filteredCount]);

  const handleChange = (event: any) => {
    setInsuranceCompany(event.target.value);
    setFilterVal("");
  };

  useEffect(() => {
    filterMultipleUserData();
  }, [insuranceCompany]);

  const getExecutiveList = () => {
    getRequest(GET_EXECUTIVE_LIST, "")
      .then((res: any) => {
        setAllInsuranceCompany(res.data.companyList)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadMultipleUser();
    getExecutiveList();
  }, []);

  const exportToCSV = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Define columns to exclude from export
    const excludedColumns = ["signature", "cattleDetails", "VDID", "Images", "additionalInformation", "created_date"];

    // Create a set to store unique headings
    const headings = new Set<string>();

    // Extract unique headings from all users, excluding the specified columns
    multipleUser.forEach((user) => {
      let cattleDetails = JSON.parse(user.cattleDetails);
      // Check if cattleDetails is a string, if not, convert it to a string
      const allKeys = [...Object.keys(user)];
      allKeys.forEach((key) => {
        if (!excludedColumns.includes(key)) {
          headings.add(key);
        }
      });
      // Add a new heading for the total number of cattle details
      headings.add("TotalCattleDetails");
    });

    // Create the header row with bold style from unique headings
    const headerRow = worksheet.addRow(Array.from(headings));
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add the data rows
    multipleUser.forEach((user: Record<string, any>) => {
      let cattleDetails = JSON.parse(user.cattleDetails);
      console.log("cattleDetails", cattleDetails)
      const combinedData = { ...user, TotalCattleDetails: cattleDetails.length };

      // Extract values in the order of headings, excluding excluded columns
      const rowData = Array.from(headings).map((heading) => combinedData[heading as keyof typeof combinedData]);

      // Add a new row with the combined data
      const dataRow = worksheet.addRow(rowData);
    });

    // Generate a Blob containing the Excel data
    const blob = await workbook.xlsx.writeBuffer();

    // Save the Blob as a file
    FileSaver.saveAs(
      new Blob([blob], { type: "application/octet-stream" }),
      "Cattle_List.xlsx"
    );
  };



  return (
    <>
      <Container style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" size="large" onClick={navgationHandler}>
              Create
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="contained" onClick={exportToCSV}>
              Export
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Insurance Company"
              size="small"
              name="insuranceRepository"
              select // Make it a select input
              value={insuranceCompany}
              onChange={handleChange}
              fullWidth
            >
              {allInsuranceCompany && allInsuranceCompany.map((item: any) => {
                return (
                  <MenuItem value={item.companyName} key={item.uniqueCId}>
                    {item.companyName}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl>
              <TextField
                label="Search"
                size="small"
                value={filterVal}
                // onChange={(e: any) => handleFilter(e)}
                // onChange={(e: any) => handleMultipleFilter(e)}
                onChange={(e: any) => {
                  setFilterVal(e.target.value);
                  handleMultipleFilter(e); // Call your filtering function here
                }}
                sx={{ mx: 3, background: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
              marginTop: "15px",
            }}
          >
            <Grid>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Cattle List
              </Typography>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Form No.
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Field Executive
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Inspection Agency
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Name of Owner
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Animal Category
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Select Purpose
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Ear Tag No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Veterinary Doctor Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Name Of the Person
                  </TableCell> */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filterMultipleUsers.length > 0 &&
                  filterMultipleUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <React.Fragment key={item._id}>
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", border: "1px solid #ddd" }}>
                            {item.FormID}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", border: "1px solid #ddd" }}>
                            {item.fieldExecutive}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", border: "1px solid #ddd" }}>
                            {item.inspectionAgency}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", border: "1px solid #ddd" }}>
                            {item.ownerName}
                          </TableCell>
                          {/* Add more TableCell components for other columns */}
                          <TableCell sx={{ textAlign: "center", border: "1px solid #ddd" }}>
                            {item?.status}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", border: "1px solid #ddd" }}>
                            <Button
                              variant="contained"
                              size="large"
                              onClick={() => navigationEditHandler(item)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>

                      </React.Fragment>
                    ))}
              </TableBody>
            </Table>
            {multipleUser.length === 0 ? <Box sx={{ display: 'flex' }} className="progress_circle"><CircularProgress /></Box> : null}
          </TableContainer>
          {filterMultipleUsers.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filterMultipleUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Paper>
      </Container>
    </>
  );
}
export default List;
