export const initialState = {
  nameOfThePerson: '',
  designation: '',
  signature: '',
  status: "Assigned"
  
}

const AuthorisedAutoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'Authorised_Update':
      return {
        ...state,
        nameOfThePerson: action.payload.nameOfThePerson,
        designation: action.payload.designation,
        signature: action.payload.signature,
        status: action.payload.status ? action.payload.status : 'Assigned'
      }
    case 'AUTO_AUTHORISED_RESET':
      return {
        ...initialState
      }

    default:
      return { ...state }
  }
}

export default AuthorisedAutoReducer
