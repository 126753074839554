import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Stack,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import car_insurance from "../../Assets/Images/car_insurance.jpg";
import car_insurance1 from "../../Assets/Images/car_insurance1.jpg";
import car_insurance2 from "../../Assets/Images/car_insurance2.jpg";
import Cattel from "../../Assets/Images/Cattle.jpg";
import liberty from "../../Assets/Images/liberty.jpg";
import cow from "../../Assets/Images/cow 1.jpg";
import car_insurance4 from "../../Assets/Images/car_insurance4.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../CommonComponents/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent";

import Image1 from "../../Assets/Images/Image1.jpg";

import Image2 from "../../Assets/Images/Image2.jpg";

import Image3 from "../../Assets/Images/Image3.jpg";
import Image4 from "../../Assets/Images/Image4.jpg";
import Image5 from "../../Assets/Images/Image5.jpg";

const Service_Content = () => {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const [currentImage, setCurrentImage] = useState(1);
  useEffect(() => {
    const imageTimeout = setTimeout(() => {
      if (currentImage === 6) {
        setCurrentImage(1);
      } else {
        setCurrentImage(currentImage + 1);
      }
    }, 2000); // Change images every 2 seconds

    return () => clearTimeout(imageTimeout);
  }, [currentImage]);

  return (
    <>
      {" "}
      <HeaderComponent />
      <Container
        className="Information"
        maxWidth="lg"
        // style={{ paddingTop: "20px" }}
        sx={{ py: "100px" }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <ImageList
              className="image_1"
              cols={1}
              style={{ overflow: "hidden" }}
            >
              <ImageListItem
                className="zoom-image"
                style={{ overflow: "hidden" }}
              >
                <img src={car_insurance} alt="Service" />
              </ImageListItem>
            </ImageList>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                textAlign: "start",
                fontWeight: "500",
                mt: 5,
              }}
              variant="h4"
              component="h2"
            >
              Car Valuation
            </Typography>

            <Typography sx={{ textAlign: "justify", letterSpacing: "0px" }}>
              At Finguard Technologies, we offer comprehensive car valuation
              services that help you determine the true market value of your
              vehicle. Our expert team utilizes advanced algorithms and
              extensive market data to provide you with accurate and up-to-date
              valuations.
            </Typography>

            <Typography
              sx={{
                // textAlign: "start",
                fontWeight: "400",
                mt: 5,
              }}
              variant="h6"
              // component="h2"
            >
              Our Car Valuation Service Features:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ textAlign: "justify", letterSpacing: "0px" }}
            >
              <CircleIcon sx={{ fontSize: "small", marginRight: "8px" }} />
              Accurate and Real-time Valuation: Get the most precise estimate of
              your car's value based on current market conditions.
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ textAlign: "justify", letterSpacing: "0px" }}
            >
              <CircleIcon sx={{ fontSize: "small", marginRight: "8px" }} /> It
              Vehicle History Analysis: We consider various factors, including
              make, model, year, mileage, and condition to assess your car's
              worth.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ textAlign: "justify", letterSpacing: "0px" }}
            >
              <CircleIcon sx={{ fontSize: "small", marginRight: "8px" }} /> It
              Transparency: We provide a detailed breakdown of how we calculate
              the valuation, giving you confidence in the accuracy of our
              results.
            </Typography>

            <Typography>
              Whether you're buying, selling, or simply curious about your car's
              worth, Finguard Technologies Pvt Ltd is your trusted partner for
              car valuation.
            </Typography>
          </Grid>

          <Grid item sm={12} md={6}>
            <Typography
              sx={{
                textAlign: "start",
                fontWeight: "500",

                mt: 7
              }}
              variant="h4"
            >
              Vehicle Preinspection
            </Typography>

            <Typography variant="body1" paragraph>
              Make Smarter Insurance Decisions
            </Typography>

            <Typography
              paragraph
              sx={{ textAlign: "justify", letterSpacing: "0px" }}
            >
              We offer a specialized Pre-Insurance Inspection Service designed
              for insurance companies. Our comprehensive reports provide crucial
              insights to help insurance companies assess motor insurability
              accurately.We thoroughly inspect vehicle conditions, safety
              features, maintainence records for best risk assessments and
              objective reporting
            </Typography>

            <Typography
              sx={{
                // textAlign: "start",
                fontWeight: "400",
                mt: 5,
              }}
              variant="h6"
              // component="h2"
            >
              Benefits for Insurance Companies:
            </Typography>

            <Typography paragraph>
              <CircleIcon
                sx={{
                  fontSize: "small",
                  marginRight: "8px",
                  textAlign: "justify",
                  letterSpacing: "0px",
                }}
              />{" "}
              Risk Mitigation: Accurate risk assessment leads to better
              underwriting decisions.
            </Typography>
            <Typography paragraph>
              <CircleIcon
                sx={{
                  fontSize: "small",
                  marginRight: "8px",
                  textAlign: "justify",
                  letterSpacing: "0px",
                }}
              />{" "}
              Cost Savings: Avoid costly claims with early issue identification.
            </Typography>

            <Typography paragraph>
              <CircleIcon
                sx={{
                  fontSize: "small",
                  marginRight: "8px",
                  textAlign: "justify",
                  letterSpacing: "0px",
                }}
              />{" "}
              Enhanced Customer Relations: Demonstrate transparency and
              commitment to customer satisfaction.
            </Typography>
          </Grid>

          <Grid item sm={12} md={6}>
            <ImageList
              className="image_2"
              cols={1}
              style={{ overflow: "hidden" }}
            >
              <ImageListItem
                className="zoom-image"
                style={{ overflow: "hidden" }}
              >
                <img src={car_insurance1} alt="Service" />
              </ImageListItem>
            </ImageList>
          </Grid>

          {/* <Grid container spacing={1}>
          <Grid item md={12} lg={2}></Grid>
          <Grid item md={12} lg={4}>
            <ImageList
              className="image_3"
              cols={1}
              style={{ overflow: "hidden" }}
            >
              <ImageListItem style={{ overflow: "hidden" }}>
                <img src={car_insurance2} alt="Service" />
              </ImageListItem>
            </ImageList>
          </Grid>
          <Grid item md={12} lg={6}>
            <ImageList
              cols={1}
              className="image_7"
              style={{ overflow: "hidden" }}
            >
              <ImageListItem style={{ overflow: "hidden" }}>
                <img src={car_insurance4} alt="Service" />
              </ImageListItem>
            </ImageList>
          </Grid>
        </Grid> */}

          <Grid container>
            <Grid item sm={12} md={4}>
              <ImageList
                className="image_6"
                cols={1}
                style={{ overflow: "hidden" }}
              >
                <ImageListItem
                  className="zoom-image"
                  style={{ overflow: "hidden" }}
                >
                  <img src={car_insurance2} alt="Service" />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid item sm={12} md={8}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontWeight: "500",

                  mt: 7,
                }}
                variant="h4"
              >
                Vehicle Claims
              </Typography>

              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                When unexpected accidents or damages occur to your vehicle, our
                Vehicle Claims service is here to provide swift and hassle-free
                support. We assist you in processing insurance claims, ensuring
                you receive the compensation you deserve for your losses.
              </Typography>

              <Typography
                sx={{
                  // textAlign: "start",
                  fontWeight: "400",
                  mt: 5,
                }}
                variant="h6"
                // component="h2"
              >
                Our Vehicle Claims Service Features:
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                <CircleIcon sx={{ fontSize: "small", marginRight: "8px" }} />
                Claims Assistance: We guide you through the entire claims
                process, from initial documentation to final settlement.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                <CircleIcon sx={{ fontSize: "small", marginRight: "8px" }} />
                Timely Support: Get quick and efficient resolution of your
                vehicle insurance claims, reducing downtime and inconvenience.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                <CircleIcon sx={{ fontSize: "small", marginRight: "8px" }} />
                Expertise: Our team understands insurance policies and
                procedures, helping you navigate the complexities of the claims
                process.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                Count on Finguard Technologies to advocate for your rights and
                help you recover from unexpected vehicle mishaps.
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={12} lg={4}>
              <ImageList
                className="image_5"
                cols={1}
                style={{ overflow: "hidden" }}
              >
                <ImageListItem
                  className="zoom-image"
                  style={{ overflow: "hidden" }}
                >
                  <img src={Cattel} alt="Service" />
                </ImageListItem>
              </ImageList>
            </Grid>

            <Grid item md={12} lg={8}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontWeight: "500",

                  mt: 7,
                }}
                variant="h4"
              >
                Cattle Insurance Claims
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                Protect your livestock and investments with our specialized
                Cattle Insurance Claims service. We understand the unique
                challenges that come with cattle farming, and we're here to
                assist you in filing and managing insurance claims effectively.
              </Typography>

              <Typography
                sx={{
                  // textAlign: "start",
                  fontWeight: "400",
                  mt: 5,
                }}
                variant="h6"
                // component="h2"
              >
                Benefits of Our Cattle Insurance Claims Service
              </Typography>

              <Typography paragraph>
                <CircleIcon
                  sx={{
                    fontSize: "small",
                    marginRight: "8px",
                    textAlign: "justify",
                    letterSpacing: "0px",
                  }}
                />
                Industry Expertise: Our team is well-versed in the intricacies
                of cattle insurance, ensuring your claims are handled
                professionally.
              </Typography>

              <Typography paragraph>
                <CircleIcon
                  sx={{
                    fontSize: "small",
                    marginRight: "8px",
                    textAlign: "justify",
                    letterSpacing: "0px",
                  }}
                />
                Streamlined Process: We simplify the claims submission and
                processing, saving you time and effort.
              </Typography>
              <Typography paragraph>
                <CircleIcon
                  sx={{
                    fontSize: "small",
                    marginRight: "8px",
                    textAlign: "justify",
                    letterSpacing: "0px",
                  }}
                />
                Maximizing Coverage: We work diligently to ensure you receive
                the full compensation you are entitled to under your cattle
                insurance policy.
              </Typography>
              <Typography paragraph>
                With Finguard Technologies Pvt Ltd, you can safeguard your
                cattle and livelihood with confidence.
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={12} lg={4}>
              <ImageList
                className="image_8"
                cols={1}
                style={{ overflow: "hidden" }}
              >
                <ImageListItem
                  className="zoom-image"
                  style={{ overflow: "hidden" }}
                >
                  <img src={cow} alt="Service" />
                </ImageListItem>
              </ImageList>
            </Grid>

            <Grid item md={12} lg={8}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontWeight: "500",

                  mt: 7,
                }}
                variant="h4"
              >
                Cattle Tagging
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "justify", letterSpacing: "0px" }}
              >
                Efficiently manage your livestock with our Cattle Tagging
                service. We provide state-of-the-art tagging solutions that help
                you keep track of your cattle, monitor their health, and improve
                overall farm management.
              </Typography>

              <Typography
                sx={{
                  // textAlign: "start",
                  fontWeight: "400",
                  mt: 5,
                }}
                variant="h6"
                // component="h2"
              >
                Key Features of Our Cattle Tagging Service:
              </Typography>

              <Typography paragraph>
                <CircleIcon
                  sx={{
                    fontSize: "small",
                    marginRight: "8px",
                    textAlign: "justify",
                    letterSpacing: "0px",
                  }}
                />
                RFID Technology: We offer RFID tags for easy and accurate
                identification of each animal in your herd.
              </Typography>

              <Typography paragraph>
                <CircleIcon
                  sx={{
                    fontSize: "small",
                    marginRight: "8px",
                    textAlign: "justify",
                    letterSpacing: "0px",
                  }}
                />
                Health Monitoring: Track the health and behavior of your cattle,
                helping you identify issues early.
              </Typography>
              <Typography paragraph>
                <CircleIcon
                  sx={{
                    fontSize: "small",
                    marginRight: "8px",
                    textAlign: "justify",
                    letterSpacing: "0px",
                  }}
                />
                Inventory Management: Streamline record-keeping and improve farm
                productivity through efficient cattle tracking.
              </Typography>
              <Typography paragraph>
                Finguard Technologies Pvt Ltd empowers cattle farmers with
                innovative solutions for better livestock management.
              </Typography>
              <Typography paragraph>
                Feel free to use or modify the provided content for your website
                to showcase the services offered by Finguard Technologies Pvt
                Ltd effectively.
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: 6 }}>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              {currentImage === 1 && (
                <img src={Image1} alt="Image 1" className={"Animation"} />
              )}

              {currentImage === 2 && (
                <img src={Image2} alt="Image 2" className={"Animation"} />
              )}

              {currentImage === 3 && (
                <img src={Image3} alt="Image 3" className={"Animation"} />
              )}

              {currentImage === 4 && (
                <img src={Image4} alt="Image 4" className={"Animation"} />
              )}

              {currentImage === 5 && (
                <img src={Image5} alt="Image 5" className={"Animation"} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
      <FooterComponent />
    </>
  );
};

export default Service_Content;
